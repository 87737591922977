import type { ComponentType } from "react";
import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { useDragging } from "hooks/useDragging";
import type { ViewMenuProps } from "components/utils/ViewMenu";
import { ViewMenu } from "components/utils/ViewMenu";
import { ViewHeader } from "components/utils/ViewHeader";
import type { EramView } from "types/eramView";
import { useZIndex } from "hooks/useZIndex";
import type { EramFontSize, ViewHeaderProps, ViewOptionMapValue } from "@poscon/shared-frontend";
import { useViewOptionSelected, eramTextDimensionMap, useOnMount } from "@poscon/shared-frontend";
import { viewTitleMap } from "~/eramConstants";
import { closeView, pushZStack, viewPositionSelector } from "~redux/slices/eramStateSlice";
import type { RootState } from "~redux/store";
import type { ColorSource, Graphics as PixiGraphics } from "pixi.js";
import { Rectangle } from "pixi.js";
import { CrrViewMenu } from "components/utils/CrrViewMenu";
import { useViewOptions } from "contexts/viewOptionContext";
import { useBrightContext } from "contexts/brightnessContext";
import { InteractiveContainer } from "components/utils/InteractiveContainer";
import { layerZIndexMap } from "~/layerZIndexMap";
import { ASViewMenu } from "components/utils/ASViewMenu";
import { useWindowSize } from "usehooks-ts";

type ViewProps<V extends EramView> = {
  view: V;
  title?: string;
  viewMenuTitle?: string;
  optionMap?: Record<string, ViewOptionMapValue<RootState>>;
  children?: React.ReactNode;
  headerButtons?: ViewHeaderProps["buttons"];
  bgColor?: number;
  width: number;
  extraWidthPx?: number;
  height: number;
  borderColor?: ColorSource;
};

const viewMenuComponentMap: {
  [View in EramView]?: ComponentType<ViewMenuProps<View>>;
} = {
  CRR: CrrViewMenu,
  ALTIM_SET: ASViewMenu,
};

export const View = <V extends EramView>({
  view,
  children,
  width: _width,
  extraWidthPx = 0,
  height,
  bgColor = 0,
  title = viewTitleMap[view]?.title ?? "TITLE MISSING",
  ...props
}: ViewProps<V>) => {
  const ref = useRef<PixiGraphics>(null);
  const dispatch = useRootDispatch();
  const { selected: showOptions, openViewOption } = useViewOptionSelected(view);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const viewOptions = useViewOptions(view);
  const { borderTint } = useBrightContext();
  const pos = useRootSelector((state) => viewPositionSelector(state, view));
  const viewHeight = eramTextDimensionMap[2].height + 3 + height;

  const width =
    _width * eramTextDimensionMap[(viewOptions as { font?: EramFontSize })?.font ?? 2].width + extraWidthPx;
  const { x, y } = {
    x: Math.min(pos.x, windowWidth - width),
    y: Math.min(pos.y, windowHeight - viewHeight - 1),
  };
  const rectRef = useRef(new Rectangle(x, y, width, viewHeight));
  rectRef.current.x = x;
  rectRef.current.y = y;
  rectRef.current.width = width;
  rectRef.current.height = viewHeight;

  const { startDrag } = useDragging(rectRef, view);
  const zIndex = useZIndex(view, ref);

  useOnMount(() => {
    dispatch(pushZStack(view));
  });

  const handleOptionsMouseDown = () => {
    openViewOption();
  };

  const showBorder = (viewOptions as any)?.border ?? true;
  const opaqueValue = (viewOptions as any)?.background ?? true;
  const ViewMenuComponent = viewMenuComponentMap[view] ?? ViewMenu;

  return (
    <>
      <InteractiveContainer
        ref={ref}
        x={x}
        y={y}
        zIndex={zIndex + layerZIndexMap[opaqueValue ? "viewsOverTracksBase" : "viewsUnderTracksBase"]}
        sortableChildren
      >
        <ViewHeader
          width={width}
          title={title}
          handleOptionsMouseDown={viewOptions ? handleOptionsMouseDown : undefined}
          onClose={() => dispatch(closeView(view))}
          startDrag={startDrag}
          opaque={opaqueValue}
          buttons={props.headerButtons}
        />
        {height > 0 && (
          <container y={eramTextDimensionMap[2].height + 3} sortableChildren>
            <graphics
              zIndex={0}
              draw={(graphics) => {
                graphics.clear();
                graphics
                  .rect(0, 0, width, height)
                  .fill(bgColor)
                  .stroke({ width: 1, color: showBorder ? borderTint : 0 });
              }}
            />
            {children}
          </container>
        )}
      </InteractiveContainer>
      {showOptions && (
        <container x={x} y={y} zIndex={layerZIndexMap.viewMenu} sortableChildren>
          <ViewMenuComponent
            view={view}
            parentWidth={width}
            options={props.optionMap}
            viewMenuTitle={props.viewMenuTitle}
          />
        </container>
      )}
    </>
  );
};
