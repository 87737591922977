import React from "react";
import type { LsConfig } from "@poscon/shared-types/eram";
import { mod } from "@poscon/shared-types";
import { useProjection } from "contexts/sdContext";
import { situationDisplayStore } from "~/situationDisplayStore";

export const LongitudinalScale = ({ config: { position, distance, bearing } }: { config: LsConfig }) => {
  const projection = useProjection();

  const [x, y] = projection(position)!;
  const pixelLength = situationDisplayStore.nmToPx(distance);

  const lineAngle = mod(bearing, 360);
  return (
    <graphics
      zIndex={10}
      x={x}
      y={y}
      angle={lineAngle + 180}
      draw={(graphics) => {
        graphics.clear();
        graphics.moveTo(0, 0).lineTo(0, pixelLength).stroke({ width: 1, color: 0xffffff });

        Array.from({ length: distance + 1 }).forEach((_, j) => {
          const alongDist = (j * pixelLength) / distance;
          const l = j % 5 === 0 ? 16 : 6;
          graphics.moveTo(0, alongDist).lineTo(l, alongDist).stroke({ width: 1, color: 0xffffff });
        });
      }}
    />
  );
};
