import type React from "react";
import { useRef, useState } from "react";
import { setViewPosition } from "~redux/slices/eramStateSlice";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import type { EramView } from "types/eramView";
import { LogicalPosition } from "@tauri-apps/api/window";
import { TBE, TBP } from "~/eramConstants";
import type { Container, FederatedPointerEvent } from "pixi.js";
import { Rectangle } from "pixi.js";
import {
  usePixiMouseEventListener,
  clipCursorToWindow,
  setSelectedViewOption,
  useUiIsLocked,
  useStableCallback,
  useOnUnmount,
} from "@poscon/shared-frontend";
import { useDragContext } from "contexts/dragContext";
import { clipCursorToSituationDisplay } from "~/utils/cursor";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";

type StopDragOn = "mousedown" | "mouseup";

/**
 * hook to provide startDrag/endDrag functions with a previewStyle to render the previewWindow
 * @param ref ref to a Pixi element or a Rectangle object representing the absolute bounds of the object
 * @param eramView
 * @param stopDragOn whether to listen for stopDrag onmousedown or onMouseUp
 * @param repositionCursor
 * @returns
 */
export const useDragging = (
  ref: React.RefObject<Container | Rectangle | null>,
  eramView: EramView,
  stopDragOn: StopDragOn = "mousedown",
  repositionCursor = true,
) => {
  const dispatch = useRootDispatch();
  const {
    setDraggingOutlineVisible,
    setDraggingOutlinePositionAndDimension,
    getDraggingOutlinePositionAndDimension,
    draggingHandler,
    anyDragging,
    setAnyDragging,
  } = useDragContext();
  // on middleClick I always want to stop drag onmouseup
  const [currentStopDragOn, setCurrentStopDragOn] = useState(stopDragOn);
  const isDraggingRef = useRef(false);
  const uiIsLocked = useUiIsLocked();

  const abIsOpen = useRootSelector((state) => state.eram.toggleButtonState.AB_OPEN);

  const startDrag = useStableCallback(async (event: FederatedPointerEvent) => {
    if (
      !uiIsLocked &&
      !isDraggingRef.current &&
      !anyDragging &&
      ref.current &&
      (event.button === 0 || event.button === 1)
    ) {
      event.stopPropagation();
      dispatch(setSelectedViewOption(null));
      if (event.button === TBE) {
        setCurrentStopDragOn("mousedown");
      }
      const pos = ref.current instanceof Rectangle ? ref.current : ref.current.getGlobalPosition();
      const previewPos = { x: pos.x, y: pos.y };
      const width = ref.current.width - 1;
      const height = ref.current.height - 1;
      if (window.__TAURI__) {
        if (abIsOpen) {
          await clipCursorToWindow(width - 1, height);
        } else {
          await clipCursorToSituationDisplay(width, height);
        }
        if (repositionCursor) {
          await WebviewWindow.getCurrent().setCursorPosition(new LogicalPosition(previewPos.x, previewPos.y));
        }
      }
      setDraggingOutlinePositionAndDimension(previewPos.x, previewPos.y, width, height);
      setDraggingOutlineVisible(true);
      isDraggingRef.current = true;
      setAnyDragging(true);
      window.addEventListener("mousemove", draggingHandler, {
        passive: true,
        capture: true,
      });
    }
  });

  const stopDrag = useStableCallback(async () => {
    window.removeEventListener("mousemove", draggingHandler, { capture: true });
    if (isDraggingRef.current) {
      const { x, y } = getDraggingOutlinePositionAndDimension();
      const newPos = { x, y };
      if (window.__TAURI__) {
        if (abIsOpen) {
          void WebviewWindow.getCurrent().setCursorGrab(false);
        } else {
          void clipCursorToSituationDisplay();
        }
      }
      dispatch(
        setViewPosition({
          view: eramView,
          pos: newPos,
        }),
      );
      setAnyDragging(false);
      isDraggingRef.current = false;
      setDraggingOutlineVisible(false);
    }
  });

  usePixiMouseEventListener(
    (event) => {
      if (event.button === TBP && isDraggingRef.current) {
        event.stopImmediatePropagation();
        setCurrentStopDragOn(stopDragOn);
        void stopDrag();
      }
    },
    undefined,
    true,
    currentStopDragOn,
  );

  useOnUnmount(stopDrag);

  return { startDrag };
};
