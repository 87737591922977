import { FlightplanId } from "@poscon/shared-types"
import { ComponentType } from "react";
import { useRootSelector } from "~/redux/hooks";
import { flightplanSelector } from "~/redux/slices/aircraftSlice";
import { Asel } from "~/redux/slices/eramTempStateSlice";
import { EramAselMenu } from "~/types/eramView";
import { AltitudeMenu, HeadingMenu, FreeTextMenu, HoldMenu, RouteMenu, SpeedMenu, XResMenu, EramAircraftMenuProps } from "./menus/AircraftMenu";
import { PoMenu } from "./prompts/PoMenu";
import { cpdlcMiniMOView, cpdlcPidView } from "@poscon/shared-frontend";
import { CpdlcMiniMOPrompt } from "./views/CpdlcMiniMO";
import { CpdlcPidMenuPrompt } from "./views/CpdlcPidMenu";

const flightplanMenuMap: Record<EramAselMenu, ComponentType<EramAircraftMenuProps> | null> = {
  ALTITUDE_MENU: AltitudeMenu,
  CANCEL_HOLD_MENU: null,
  CHANGE_DEST_MENU: null,
  HEADING_MENU: HeadingMenu,
  FREETEXT_MENU: FreeTextMenu,
  HOLD_MENU: HoldMenu,
  PO_MENU: PoMenu,
  PREV_ROUTE_MENU: null,
  ROUTE_MENU: RouteMenu,
  SPEED_MENU: SpeedMenu,
  X_RES_MENU: XResMenu,
  [cpdlcMiniMOView]: CpdlcMiniMOPrompt,
  [cpdlcPidView]: CpdlcPidMenuPrompt,
};

type AselComponentProps = {
  fpId: FlightplanId;
  asel: Asel;
}

export const AselComponent = ({ fpId, asel }: AselComponentProps) => {
  const fp = useRootSelector((state) => flightplanSelector(state, fpId));

  const Component = flightplanMenuMap[asel.menu];

  return fp && Component ? <Component key={fpId} fp={fp} /> : null;
}
