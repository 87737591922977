import React, { useState } from "react";
import { View } from "components/utils/View";
import {
  backgroundOption,
  borderOption,
  brightOption,
  fontOption,
  viewOptionSelector,
} from "~redux/slices/viewOptionSlice";
import { useRootSelector } from "~redux/hooks";
import { checkListSelector } from "~redux/slices/eramTempStateSlice";
import type { EramFontSize } from "@poscon/shared-frontend";
import { colorNameMap, computeColor, eramFontDimensionMap, eramFontNameMap, getBitmapTextStyles } from "@poscon/shared-frontend";
import { ViewOptionContextProvider } from "~/contexts/viewOptionContext";
import { CheckListButtonId } from "~/types/eramButton";

const optionMapArr = Array.from({ length: 17 * 6 }, (_, i) => {
  const view = `CHKLST_${i}` as CheckListButtonId;
  return {
    background: backgroundOption(view),
    border: borderOption(view),
    font: fontOption(view),
    bright: brightOption(view),
  };
});

type ChecklistProps = {
  view: CheckListButtonId;
};
export const Checklist = ({ view }: ChecklistProps) => {
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));
  const chkListIndex = parseInt(view.split("_").at(-1)!, 10);
  const checklist = useRootSelector((state) => checkListSelector(state, chkListIndex));
  const [hover, setHover] = useState<string | null>(null);

  const items = checklist?.chkListViewListItem ?? [];

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const fontSize = viewOptions.font as EramFontSize;
  const fontFamily = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontFamily];

  const width = Math.max(29, ...items.map((s) => s ? s.length + 2 : 0));

  const toggleItem = (item: string) => {
    setSelectedItems((prev) => {
      if (prev.includes(item)) {
        return prev.filter((e) => e !== item);
      }
      return [...prev, item];
    });
  };

  const height = items.length * (fontDimension.height + 4) + 8;

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View
        width={width}
        height={height}
        view={view}
        optionMap={optionMapArr[chkListIndex]!}
        title={checklist?.chkListViewTitle ?? ""}
        viewMenuTitle={checklist?.chkListViewMenuTitle ?? ""}
      >
        <container x={6} y={4}>
          {items.map((item, index) => {
            const key = `${index}-${item}`;
            const selected = item && selectedItems.includes(key);
            const bgColor = computeColor(
              selected ? colorNameMap.grey : colorNameMap.black,
              viewOptions.bright / 100,
            );
            const tint = computeColor(selected ? colorNameMap.black : colorNameMap.grey, viewOptions.bright / 100);
            return (
              <container key={key} eventMode={item && /[\w\s]+/.test(item) ? "static" : "none"} sortableChildren y={(fontDimension.height + 4) * index}>
                <graphics
                  zIndex={0}
                  draw={(graphics) => {
                    graphics.clear();

                    graphics
                      .rect(0, 0, (item?.length ?? 0) * fontDimension.width + 8, fontDimension.height + 2).fill(bgColor)
                      .stroke({
                        width: 1,
                        color: item && hover === key ? colorNameMap.white : selected ? colorNameMap.grey : colorNameMap.black,
                      });
                  }}
                  eventMode="static"
                  onMouseDown={() => item && toggleItem(key)}
                  onMouseEnter={() => item && setHover(key)}
                  onMouseLeave={() => item && setHover((prev) => (prev === key ? null : prev))}
                />
                <bitmapText
                  zIndex={1}
                  text={(item ?? "").toUpperCase()}
                  eventMode="none"
                  x={4}
                  y={1}
                  style={{ ...getBitmapTextStyles(fontFamily), fill: tint }}
                />
              </container>
            );
          })}
        </container>
      </View>
    </ViewOptionContextProvider>
  );
};
