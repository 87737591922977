import type { ComponentType } from "react";
import React, { useRef } from "react";
import type {
  BrightButtonId,
  CheckListButtonId,
  EramButtonId,
  FontButtonId,
  ToggleButtonId,
  ToolbarMenuButtonId,
} from "types/eramButton";
import {
  brightButtons,
  buttonMenuMap,
  fontButtonList,
  toggleButtonList,
  toolbarMenuButtonList,
} from "types/eramButton";
import type { EramButtonProps } from "components/buttons/EramButton";
import { EramBaseButton, EramButton } from "components/buttons/EramButton";
import { EramCounterButton } from "components/buttons/EramCounterButton";
import { EramAltLimButton } from "components/buttons/EramAltLimButton";
import {
  EramDrawAnchorButton,
  EramDrawButton,
  EramDrawCircleSizeButton,
  EramDrawCounterColor,
} from "components/buttons/EramDrawButton";
import {
  EramCreateMacroButton,
  EramEditMacroButton,
  EramMacroButton,
} from "components/buttons/EramMacroButton";
import { EramOutageButton } from "components/buttons/EramOutageButton";
import { EramPrefSetButton } from "components/buttons/EramPrefSetButton";
import { EramToolbarCatButton } from "components/buttons/EramToolbarCatButton";
import { EramCommandButton } from "components/buttons/EramCommandButton";
import { EramToggleButton } from "components/buttons/EramToggleButton";
import { useDrawContext } from "contexts/drawContext";
import type { Container as PixiContainer } from "pixi.js";
import { ButtonMenuContainer } from "components/buttons/ButtonMenuContainer";
import { EramRangeMenuButton } from "components/buttons/EramRangeMenuButton";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import {
  menuButtonPathsSelector,
  removeMenuButtonPath,
  toggleMenuButtonPathValue,
} from "~redux/slices/eramStateSlice";
import { sectorIdSelector, useOnUnmount } from "@poscon/shared-frontend";
import { rPosConfigSelector } from "~/redux/slices/eramTempStateSlice";

const baseToolbarButtonColor = new Uint8Array([0, 0, 0xad]);
const baseToolbarButtonSelectedColor = new Uint8Array([0xad, 0x3b, 0x25]);

export const EramMenuBarButton = (props: EramButtonProps) => {
  const dispatch = useRootDispatch();
  const sectorId = useRootSelector(sectorIdSelector);
  const rposConfig = useRootSelector(rPosConfigSelector);
  const { setDrawMode } = useDrawContext();
  const { buttonId, path } = props;
  const selectedPaths = useRootSelector(menuButtonPathsSelector);
  const ref = useRef<PixiContainer>(null);
  const showMenu = selectedPaths.includes(path);

  useOnUnmount(() => {
    if (showMenu) {
      dispatch(removeMenuButtonPath(path));
    }
  });

  let rows = buttonMenuMap[buttonId];

  if (buttonId === "CHECK_LISTS") {
    const sectorNumber = sectorId?.split(".").at(-1);
    const sectorConfig = rposConfig?.sectorConfigs.find((c) => c.sectorId === sectorNumber);
    const buttonLocations =
      sectorConfig?.checklists?.map((c) => c.buttonLocation) ??
      rposConfig?.checklists.map((l) => l.chkLstViewButtonLocation);
    const numRows = Math.max(2, ...(buttonLocations?.map((l) => Math.ceil((l + 1) / 6)) ?? []));
    rows = rows?.slice(0, numRows) ?? [];
  }

  return (
    <EramBaseButton
      {...props}
      containerRef={ref}
      baseBgColor={showMenu ? baseToolbarButtonSelectedColor : baseToolbarButtonColor}
      zIndex={showMenu ? 20 : undefined}
      onmousedown={(event) => {
        dispatch(toggleMenuButtonPathValue(props.path));
        if (buttonId === "DRAW") {
          setDrawMode(!showMenu ? "OPEN" : "CLOSED");
        }
        props.onmousedown?.(event);
      }}
    >
      {showMenu && rows?.length && (
        <ButtonMenuContainer
          buttonId={buttonId}
          parentX={props.position?.x}
          parentY={props.position?.y}
          rows={rows}
          direction={props.toolbarDirection}
          anchor={
            !props.toolbarPosition ||
            props.toolbarPosition.row === 0 ||
            rows.length > 2 ||
            props.toolbarDirection === "vertical"
              ? "top"
              : "bottom"
          }
        >
          {rows.flatMap((buttonList, i) =>
            buttonList.map((id, j) => {
              const Component = ButtonTypeMap[id];
              const componentPath = `${path}/${i}/${j}/${id}`;
              const componentHidden =
                selectedPaths.some((p) => p !== path && p.startsWith(path)) &&
                !selectedPaths.includes(componentPath);
              return (
                <Component
                  key={`${id}-${i}-${j}`}
                  path={componentPath}
                  buttonId={id}
                  hidden={componentHidden}
                  zIndex={0}
                  isSubMenu
                  toolbarDirection={props.toolbarDirection}
                  toolbarPosition={{
                    row: props.toolbarDirection === "vertical" ? j : i,
                    col: props.toolbarDirection === "vertical" ? i : j,
                  }}
                />
              );
            }),
          )}
        </ButtonMenuContainer>
      )}
    </EramBaseButton>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EramButtonComponentType = ComponentType<EramButtonProps<any>>;
export const ButtonTypeMap: Record<EramButtonId | "MACRO_BUTTON", EramButtonComponentType> = {
  "": EramButton,
  AB_SETTING: EramMenuBarButton,
  AB_WIDTH: EramCounterButton,
  ALT_LIM: EramAltLimButton,
  ATC_TOOLS: EramMenuBarButton,
  ATC_TOOLS_WX: EramMenuBarButton,
  BRIGHT: EramMenuBarButton,
  CHECK_LISTS: EramMenuBarButton,
  CLR_ALL_MACROS: EramCommandButton,
  COMMAND_MENUS: EramMenuBarButton,
  CPDLC_BRIGHT: EramMenuBarButton,
  CURSOR: EramMenuBarButton,
  CURSOR_SIZE: EramCounterButton,
  CURSOR_SPEED: EramCounterButton,
  CURSOR_VOLUME: EramCounterButton,
  DB_FIELDS: EramMenuBarButton,
  DELETE_TEAROFF: EramCommandButton,
  DRAW: EramMenuBarButton,
  DRAW_ANCHOR_MAP: EramDrawAnchorButton,
  DRAW_CIRCLE: EramDrawButton,
  DRAW_CIRCLE_SIZE: EramDrawCircleSizeButton,
  DRAW_COLOR: EramDrawCounterColor,
  DRAW_COLOR_PALETTE: EramDrawButton,
  DRAW_DEL: EramDrawButton,
  DRAW_DEL_ALL: EramDrawButton,
  DRAW_FONT: EramDrawButton,
  DRAW_FONT_SIZE: EramCounterButton,
  DRAW_LINE: EramDrawButton,
  DRAW_RECT: EramDrawButton,
  DRAW_TEXT: EramDrawButton,
  FDB_LDR: EramCounterButton,
  FONT: EramMenuBarButton,
  HISTORY: EramCounterButton,
  MACRO_BUTTON: EramMacroButton,
  MACRO_CREATE: EramCreateMacroButton,
  MACRO_DELETE: EramToggleButton,
  MACRO_EDIT: EramEditMacroButton,
  MAP: EramMenuBarButton,
  MAP_BRIGHT: EramMenuBarButton,
  NX_ALT: EramCounterButton,
  NX_LVL: EramCounterButton,
  OUTAGE: EramOutageButton,
  PREFSET: EramPrefSetButton,
  RADAR_FILTER: EramMenuBarButton,
  RANGE_MENU: EramRangeMenuButton,
  RECORD: EramMenuBarButton,
  TOOLBAR: EramMenuBarButton,
  VECTOR: EramCounterButton,
  VIEWS: EramMenuBarButton,
  ...([...brightButtons, ...fontButtonList]
    .flat()
    .reduce((prev, id) => ({ ...prev, [id]: EramCounterButton }), {}) as Record<
    BrightButtonId | FontButtonId,
    EramButtonComponentType
  >),
  ...([
    ...toggleButtonList.flat().filter((id) => !["RANGE_MENU", "ALT_LIM", "PREFSET"].includes(id)),
    ...toolbarMenuButtonList.flat(),
  ].reduce(
    (prev, id) => ({
      ...prev,
      [id]: id.startsWith("CMD_MENU_") ? EramToolbarCatButton : EramToggleButton,
    }),
    {},
  ) as Record<
    Exclude<ToggleButtonId | ToolbarMenuButtonId, "RANGE_MENU" | "ALT_LIM" | "PREFSET">,
    EramButtonComponentType
  >),
};
