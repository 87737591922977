import type { EramView } from "types/eramView";
import React, { useRef, useState } from "react";
import type { ViewMenuProps } from "components/utils/ViewMenu";
import { ViewMenu } from "components/utils/ViewMenu";
import { colorNameMap, eramFontNameMap, eramTextDimensionMap, getBitmapTextStyles, useFocused } from "@poscon/shared-frontend";
import { ColorPalette } from "components/utils/ColorPalette";
import type { ColorName } from "@poscon/shared-types";
import { useRootSelector } from "~redux/hooks";
import type { Graphics as PixiGraphics } from "pixi.js";
import { crrGroupsSelector } from "~redux/slices/eramStateSlice";
import { eramHubConnection } from "~/eramHubConnection";
import { drawColorMap } from "~/utils/drawColorMap";
import { useBrightContext } from "contexts/brightnessContext";

const { width: fontWidth, height: fontHeight } = eramTextDimensionMap[2];
const titleXPadding = Math.floor((fontHeight - fontWidth) / 2);
const fontFamily = eramFontNameMap[2];

type GroupRowProps = {
  groupName: string;
  width: number;
  y: number;
  color?: ColorName;
  selectedPaletteColor: ColorName;
};
const GroupRow = ({ groupName, width, y, color, selectedPaletteColor }: GroupRowProps) => {
  const ref = useRef<PixiGraphics>(null);
  const focused = useFocused(ref);
  const { borderTint } = useBrightContext();

  const tint = drawColorMap[color ?? "grey"];

  return (
    <>
      <graphics
        ref={ref}
        y={y}
        eventMode="static"
        zIndex={focused ? 1 : 0}
        draw={(graphics) => {
          graphics.clear();
          graphics.rect(0, 0, width, fontHeight + 1).fill(0)
            .stroke({ width: 1, color: focused ? colorNameMap.white : borderTint });
        }}
        onMouseDown={() => {
          eramHubConnection.emit("setCrrColor", groupName, selectedPaletteColor);
        }}
      />
      <bitmapText
        x={4}
        y={y + 2}
        text={groupName}
        zIndex={2}
        eventMode="none"
        style={{ ...getBitmapTextStyles(fontFamily), fill: tint }}
      />
    </>
  );
};

export const CrrViewMenu = <V extends EramView>(props: ViewMenuProps<V>) => {
  const { borderTint } = useBrightContext();
  const crrGroups = useRootSelector(crrGroupsSelector);
  const [selectedPaletteColor, setSelectedPaletteColor] = useState<ColorName>("yellow");
  const width = Math.max(
    3 * fontWidth + 2 * titleXPadding,
    ...Object.values(props.options ?? {}).map(
      (option) => (option as { minWidth: number }).minWidth * fontWidth + 4,
    ),
  );
  const paletteItemWidth = Math.floor(width / 2) - 8;
  const paletteItemHeight = 10;
  return (
    <ViewMenu {...props} minWidth={width}>
      <graphics
        draw={(graphics) => {
          graphics.clear();
          graphics.zIndex = 0;
          graphics.rect(0, 0, width, paletteItemHeight * 2 + 13).fill(0).stroke({ width: 1, color: borderTint });
        }}
      />
      <ColorPalette
        width={paletteItemWidth}
        height={paletteItemHeight}
        spacing={4}
        selectedColor={selectedPaletteColor}
        setSelectedColor={setSelectedPaletteColor}
      />
      <container y={paletteItemHeight * 2 + 13}>
        {crrGroups.map((group, index) => (
          <GroupRow
            key={group.name}
            groupName={group.name}
            width={width}
            y={(fontHeight + 2) * index}
            color={group.color}
            selectedPaletteColor={selectedPaletteColor}
          />
        ))}
      </container>
    </ViewMenu>
  );
};
