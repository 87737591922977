import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { View } from "components/utils/View";
import type { EramFontSize } from "@poscon/shared-frontend";
import {
  useViewOptionSelected,
  MenuElement,
  colorNameMap,
  computeColor,
  eramFontDimensionMap,
  eramFontNameMap,
  ScrollBar,
  useFocused,
  useMetar,
  useScrollProps,
  createItemOption,
  ViewItemOptionContainer,
  metarAirportsSelector,
  getBitmapTextStyles,
  useHitArea,
} from "@poscon/shared-frontend";
import {
  backgroundOption,
  borderOption,
  brightOption,
  fontOption,
  linesOption,
  toggleOption,
  viewOptionSelector,
} from "~redux/slices/viewOptionSlice";
import { metarTearoffSelector } from "~redux/slices/eramStateSlice";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import { stringToParsedTokenArray } from "@poscon/shared-types/eram";
import { useWeatherTearOffContext } from "contexts/weatherTearOffContext";
import type { FederatedPointerEvent, Graphics as PixiGraphics } from "pixi.js";
import { Rectangle } from "pixi.js";
import { TBE, TBP } from "~/eramConstants";
import { Cross } from "components/utils/Cross";
import { useViewOptions, ViewOptionContextProvider } from "contexts/viewOptionContext";
import { useTearOffContext } from "contexts/tearOffContext";

const view = "WX_REPORT";

export const optionMap = {
  background: backgroundOption(view),
  border: borderOption(view),
  tearoff: toggleOption(view, "tearoff", "TEAROFF", 7),
  lines: linesOption(view, 2, 20),
  font: fontOption(view),
  bright: brightOption(view),
};

type MetarRowProps = {
  stationCode: string;
  x: number;
  y: number;
  asTearoff?: boolean;
};
export const MetarRow = ({ stationCode, x, y, asTearoff = false }: MetarRowProps) => {
  const dispatch = useRootDispatch();
  const ref = useRef<PixiGraphics>(null);
  const focused = useFocused(ref);
  const { submitDelTearOffEvent } = useTearOffContext();
  const tearoffPosition = useRootSelector((state) => metarTearoffSelector(state, stationCode));
  const viewOptions = useViewOptions(view);
  const { selected: _selected, toggleViewOption } = useViewOptionSelected(`${view}/${stationCode}`);
  const itemOptions = [
    createItemOption(`DELETE ${stationCode}`, () =>
      dispatch(processEramMessage(stringToParsedTokenArray(`WR ${stationCode}`))),
    ),
  ];

  const selected = _selected && !asTearoff;
  const fontSize = viewOptions.font as EramFontSize;
  const fontFamily = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontFamily];

  const { startTearOff, delTearOffCommandActive, toggleDeletionItem, pendingDeletionItems } =
    useWeatherTearOffContext();
  const { metar } = useMetar(stationCode);

  const isPendingDeletion = pendingDeletionItems.some(
    (item) => item.airport === stationCode && item.itemType === "METAR",
  );

  let text = metar ?? "-M-";
  const lastSpaceIndex = text.lastIndexOf(" ", 35);
  if (lastSpaceIndex !== -1 && text.length > 35) {
    text = text.slice(0, lastSpaceIndex) + "\n" + text.slice(lastSpaceIndex + 1);
  }
  const lines = 1 + Math.floor(text.length / 35);

  const offset = !asTearoff ? fontDimension.width * 2 - 4 : 0;
  const width = 37 * fontDimension.width + 4 - offset;
  const height = fontDimension.height * lines + 2;

  const hitAreaRef = useHitArea(offset, 0, width, height);

  const alpha = (viewOptions.bright * 0.8) / 100 + 0.2;

  const tint = selected ? 0 : computeColor(colorNameMap.white, viewOptions.bright / 100);

  const bgColor = computeColor(
    selected ? colorNameMap.grey : colorNameMap.black,
    !asTearoff && selected ? alpha : 1,
  );

  return (
    <container x={x} y={y} eventMode="static">
      <container eventMode="static" sortableChildren>
        {(viewOptions.tearoff || asTearoff) && (
          <MenuElement
            x={3}
            y={3}
            width={fontDimension.width + 2}
            height={fontDimension.height - 3}
            zIndex={4}
            disabled={!(asTearoff || !tearoffPosition) || delTearOffCommandActive}
            fillColor={computeColor(
              asTearoff || !tearoffPosition ? colorNameMap.yellow : colorNameMap.grey,
              alpha,
            )}
            onmousedown={(event) => ref.current && startTearOff(event, stationCode, "METAR", ref.current)}
          />
        )}
        <graphics
          ref={ref}
          hitArea={hitAreaRef.current}
          eventMode={asTearoff && !delTearOffCommandActive ? "none" : "static"}
          zIndex={1}
          draw={(graphics) => {
            graphics.clear();
            graphics
              .rect(offset, 0, width, height)
              .fill({ color: bgColor, alpha: asTearoff || selected ? 1 : 0 })
              .stroke({
                width: 1,
                color:
                  !asTearoff && focused
                    ? 0xffffff
                    : asTearoff
                      ? colorNameMap.darkGrey
                      : selected
                        ? bgColor
                        : 0,
              });
          }}
          onMouseDown={(event: FederatedPointerEvent) => {
            if (asTearoff && delTearOffCommandActive) {
              if (event.button === TBP) {
                toggleDeletionItem(stationCode, "METAR");
              }
              if (event.button === TBE) {
                submitDelTearOffEvent({ stationCode, itemType: "METAR" });
              }
            } else if (!asTearoff) {
              toggleViewOption();
            }
          }}
        />
        <bitmapText
          x={fontDimension.width * 2}
          y={2}
          zIndex={3}
          text={text}
          eventMode="none"
          style={{ ...getBitmapTextStyles(fontFamily), fill: tint }}
        />
        {asTearoff && isPendingDeletion && <Cross width={width} height={height} />}
      </container>
      {selected && <ViewItemOptionContainer baseX={offset} xOffset={width} options={itemOptions} />}
    </container>
  );
};

export const WxReport = () => {
  const airports = useRootSelector(metarAirportsSelector);
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));
  const { scroll, ...scrollProps } = useScrollProps("wxReport", airports.length, viewOptions.lines);
  const fontDimension = eramFontDimensionMap[eramFontNameMap[viewOptions.font as EramFontSize]];

  const airportToRender = airports.slice(scroll, viewOptions.lines + scroll);

  const height =
    airportToRender.length > 0 ? (fontDimension.height + 2.5) * 2 * airportToRender.length + 6 : 0;

  // TODO: account for row only be 1 line high
  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View width={40} height={height} view="WX_REPORT" optionMap={optionMap}>
        {airportToRender.length > 0 && (
          <container x={4} y={4} eventMode="static" zIndex={2}>
            {airportToRender.map((station, index) => (
              <MetarRow
                key={`${station}-${index}`}
                stationCode={station}
                x={8}
                y={(fontDimension.height * 2 + 4) * index + 2}
              />
            ))}
          </container>
        )}
        {viewOptions.lines < airports.length && (
          <ScrollBar
            height={height - 3}
            x={39 * fontDimension.width - 3}
            y={2}
            tint={computeColor(colorNameMap.white, viewOptions.bright / 100)}
            disabledTint={computeColor(colorNameMap.grey, viewOptions.bright / 100)}
            fontSize={viewOptions.font as EramFontSize}
            {...scrollProps}
          />
        )}
      </View>
    </ViewOptionContextProvider>
  );
};
