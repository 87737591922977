import React from "react";
import { useRootSelector } from "~redux/hooks";
import { crrGroupsSelector } from "~redux/slices/eramStateSlice";
import type { CrrGroup } from "@poscon/shared-types";
import { stringToTokenArray } from "@poscon/shared-types/eram";
import { useGetWindowCoordFromLonLat } from "contexts/sdContext";
import {
  dispatchInsertCommandEvent,
  eramFontNameMap,
  eramTextDimensionMap,
  getBitmapTextStyles,
  TBP,
} from "@poscon/shared-frontend";
import { drawColorMap } from "~/utils/drawColorMap";
import { InteractiveContainer } from "../utils/InteractiveContainer";
import { FederatedPointerEvent } from "pixi.js";

const fontFamily = eramFontNameMap[2];
const fontDimensions = eramTextDimensionMap[2];

type CrrGroupLabelProps = {
  group: CrrGroup;
};
export const CrrGroupLabel = ({ group }: CrrGroupLabelProps) => {
  const getPixelCoordFromLonLat = useGetWindowCoordFromLonLat();

  const [x, y] = getPixelCoordFromLonLat(group.coordinate)!;
  const tint = drawColorMap[group.color];

  return (
    <container x={x} y={y} label={`CRR_GROUP_${group.name}`}>
      <bitmapText
        eventMode="static"
        onMouseDown={(e: FederatedPointerEvent) => {
          if (e.button === TBP) {
            dispatchInsertCommandEvent(stringToTokenArray(`LF ${group.name}`));
          }
        }}
        x={-Math.round(fontDimensions.width / 2)}
        y={-Math.round(fontDimensions.height / 2)}
        text={`*${group.name}`}
        style={{ ...getBitmapTextStyles(fontFamily), fill: tint }}
        scale={1}
      />
    </container>
  );
};

export const CrrGroupLabels = () => {
  const crrGroups = useRootSelector(crrGroupsSelector);

  return (
    <InteractiveContainer zIndex={5}>
      {Object.values(crrGroups).map((group) => (
        <CrrGroupLabel key={group.name} group={group} />
      ))}
    </InteractiveContainer>
  );
};
