import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState, RootThunkAction } from "~redux/store";
import type { EramAselMenu } from "types/eramView";
import { emptyBCG, emptyMapFilterMenu, sectorIdSelector } from "@poscon/shared-frontend";
import { FlightplanId, Nullable, TrackId, RouteLine, Coordinate } from "@poscon/shared-types";
import {
  RangeCenter,
  EramConfig,
  BCG,
  MapFilterMenu,
  RPosConfig,
  KSDConfig,
  GeomapConfig,
  LsConfig,
  SharedOutageMap,
  EramOutageMap,
  SDKeypadKey,
} from "@poscon/shared-types/eram";

const defaultRangeCenter: RangeCenter = {
  dbOffset000to044: "NE",
  dbOffset045to089: "NE",
  dbOffset090to134: "NE",
  dbOffset135to179: "NE",
  dbOffset180to224: "NE",
  dbOffset225to269: "NE",
  dbOffset270to314: "NE",
  dbOffset315to359: "NE",
  alertSectorFloorAltitude: 0,
  centerLatitude: 40.6399278,
  centerLongitude: -73.7786925,
  mciSectorFloorAltitude: 0,
  sectorId: "",
  tbCursorHomeX: 0,
  tbCursorHomeY: 0,
};

export type Asel = {
  fpId: FlightplanId;
  menu: EramAselMenu;
};

export type Line4DisplayOverride = "HSF" | "DEST" | "TYPE";

export type EramTempState = {
  eramConfig: EramConfig | null;
  bcg: BCG;
  mapFilterMenu: MapFilterMenu;
  rPosConfig: RPosConfig | null;
  ksdConfig: KSDConfig | null;
  delTearOffCommandActive: boolean;
  geomapConfig: GeomapConfig | null;
  asel: Nullable<Asel>;
  cfrList: TrackId[];
  beaconCodeList: number[];
  confirmClearAllMacros: boolean;
  routeLineMap: Record<string, RouteLine>;
  lsConfig: LsConfig | null;
  prefsets: string[];
  status: SharedOutageMap & EramOutageMap;
  line4DisplayOverride: Line4DisplayOverride | null;
  mapScale: number;
  rangeCenter: RangeCenter;
  rangeCenterOverride: Coordinate;
};

const initialState: EramTempState = {
  asel: null,
  eramConfig: null,
  rPosConfig: null,
  ksdConfig: null,
  bcg: emptyBCG,
  mapFilterMenu: emptyMapFilterMenu,
  geomapConfig: null,
  beaconCodeList: [0o1200],
  cfrList: [],
  confirmClearAllMacros: false,
  delTearOffCommandActive: false,
  lsConfig: null,
  status: {
    ADSB_SV: "UP",
    AIRSPACE_SAA: "DOWN",
    ALTIMETER_SETTINGS: "UP",
    CPDLC: "DOWN",
    FLIGHT_DATA: "DOWN",
    FLIGHT_MODELING: "DOWN",
    UPPER_WINDS: "DOWN",
    WX_STATION_REPORTS: "UP",
    DISPLAY_SETTINGS: "IN_SYNC",
    FACILITY_ID: "UP",
    GROUP_SUPPRESSION: "DOWN",
    METERING_INTERFACE: "DOWN",
    NEXRAD: "UP",
    QUICK_LOOK: "DOWN",
  },
  prefsets: [],
  routeLineMap: {},
  line4DisplayOverride: null,
  mapScale: 1,
  rangeCenter: defaultRangeCenter,
  rangeCenterOverride: [0, 0],
};

export const eramTempStateSlice = createSlice({
  name: "eramTemp",
  initialState,
  reducers: {
    setAsel(state, action: PayloadAction<Nullable<Asel>>) {
      state.asel = action.payload;
    },
    setDelTearOffState(state, action: PayloadAction<boolean>) {
      state.delTearOffCommandActive = action.payload;
    },
    toggleDelTearOffState(state) {
      state.delTearOffCommandActive = !state.delTearOffCommandActive;
    },
    setGeomapConfig(state, action: PayloadAction<GeomapConfig | null>) {
      state.geomapConfig = action.payload;
    },
    setMapFilterMenu(state, action: PayloadAction<MapFilterMenu>) {
      state.mapFilterMenu = action.payload;
    },
    setBCG(state, action: PayloadAction<BCG>) {
      state.bcg = action.payload;
    },
    setCfrList(state, action: PayloadAction<TrackId[]>) {
      state.cfrList = action.payload;
    },
    setBeaconCodeList(state, action: PayloadAction<number[]>) {
      state.beaconCodeList = action.payload;
    },
    setConfirmClearAllMacros(state, action: PayloadAction<boolean>) {
      state.confirmClearAllMacros = action.payload;
    },
    setRouteLine(state, action: PayloadAction<{ id: string; line: RouteLine }>) {
      state.routeLineMap[action.payload.id] = action.payload.line;
    },
    setRouteLines(state, action: PayloadAction<EramTempState["routeLineMap"]>) {
      state.routeLineMap = action.payload;
    },
    removeRouteLine(state, action: PayloadAction<string>) {
      delete state.routeLineMap[action.payload];
    },
    setKSDConfig(state, action: PayloadAction<KSDConfig | null>) {
      state.ksdConfig = action.payload;
    },
    setRPosConfig(state, action: PayloadAction<RPosConfig | null>) {
      state.rPosConfig = action.payload;
    },
    setEramConfig(state, action: PayloadAction<EramConfig | null>) {
      state.eramConfig = action.payload;
    },
    setLsConfig(state, action: PayloadAction<LsConfig | null>) {
      state.lsConfig = action.payload;
    },
    setPrefsets(state, action: PayloadAction<string[]>) {
      state.prefsets = action.payload;
    },
    setStatus(state, action: PayloadAction<SharedOutageMap & EramOutageMap>) {
      state.status = action.payload;
    },
    setLine4DisplayOverride(state, action: PayloadAction<Line4DisplayOverride | null>) {
      state.line4DisplayOverride = action.payload;
    },
    setMapScale(state, action: PayloadAction<number>) {
      state.mapScale = action.payload;
    },
    setRangeCenter(state, action: PayloadAction<RangeCenter>) {
      state.rangeCenter = action.payload;
    },
    setRangeCenterOverride(state, action: PayloadAction<Coordinate>) {
      state.rangeCenterOverride = action.payload;
    },
  },
});

export const {
  setStatus,
  toggleDelTearOffState,
  setDelTearOffState,
  setGeomapConfig,
  setBCG,
  setMapFilterMenu,
  setCfrList,
  setBeaconCodeList,
  setConfirmClearAllMacros,
  setRouteLine,
  setRouteLines,
  removeRouteLine,
  setKSDConfig,
  setRPosConfig,
  setEramConfig,
  setLsConfig,
  setPrefsets,
  setLine4DisplayOverride,
  setMapScale,
  setRangeCenter,
  setRangeCenterOverride,
} = eramTempStateSlice.actions;

export const reducer = eramTempStateSlice.reducer;

export function setAsel(asel: Nullable<Asel>): RootThunkAction {
  return (dispatch, getState) => {
    if (!asel || Object.keys(getState().tracks.flightplans).includes(asel.fpId)) {
      dispatch(eramTempStateSlice.actions.setAsel(asel));
    }
  };
}

export const delTearOffCommandActiveSelector = (state: RootState) => state.eramTemp.delTearOffCommandActive;
export const prefsetsSelector = (state: RootState) => state.eramTemp.prefsets;
export const geomapConfigSelector = (state: RootState) => state.eramTemp.geomapConfig;
export const cfrListSelector = (state: RootState) => state.eramTemp.cfrList;
export const beaconCodeListSelector = (state: RootState) => state.eramTemp.beaconCodeList;
export const aselSelector = (state: RootState) => state.eramTemp.asel;
export const routeLineMapSelector = (state: RootState) => state.eramTemp.routeLineMap;

export const rPosConfigSelector = (state: RootState) => state.eramTemp.rPosConfig;
const emptyKsdKeysArr: SDKeypadKey[] = [];
export const ksdKeysSelector = (state: RootState) => state.eramTemp.ksdConfig?.sdKeys ?? emptyKsdKeysArr;
export const eramConfigSelector = (state: RootState) => state.eramTemp.eramConfig;
export const checkListSelector = (state: RootState, position: number) => {
  const rPosConfig = rPosConfigSelector(state);
  const sectorId = sectorIdSelector(state);
  const sectorNumber = sectorId?.split(".").at(-1);
  const sectorConfig = rPosConfig?.sectorConfigs.find((c) => c.sectorId === sectorNumber);
  if (sectorConfig?.checklists) {
    const checklistTitle = sectorConfig.checklists.find(
      (c) => c.buttonLocation === position,
    )?.chkListViewTitle;
    return checklistTitle ? rPosConfig?.checklists.find((c) => c.chkListViewTitle === checklistTitle) : null;
  }
  return rPosConfig?.checklists?.find((c) => c.chkLstViewButtonLocation === position) ?? null;
};
export const toolbarCategoryMenusSelector = (state: RootState) =>
  state.eramTemp.rPosConfig?.toolbarCategoryMenus;
export const cmdMenuItemSelector = (state: RootState, position: number) =>
  state.eramTemp.rPosConfig?.toolbarCategoryMenus.find((m) => m.catButtonLocation === position) ?? null;
export const lsConfigSelector = (state: RootState) => state.eramTemp.lsConfig;
export const line4DisplayOverrideSelector = (state: RootState) => state.eramTemp.line4DisplayOverride;
export const mapScaleSelector = (state: RootState) => state.eramTemp.mapScale;
export const rangeCenterSelector = (state: RootState) => state.eramTemp.rangeCenter;
export const rangeCenterOverrideSelector = (state: RootState) => state.eramTemp.rangeCenterOverride;
