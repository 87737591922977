import type { RefObject } from "react";
import React, { useEffect, useImperativeHandle, useRef } from "react";
import type { ViewItemOptionContainerProps, WindowPosition } from "@poscon/shared-frontend";
import {
  setSelectedViewOption,
  computeColor,
  eramFontNameMap,
  eramTextDimensionMap,
  useFocused,
  useOnMount,
  useOptionContainerPos,
  usePixiMouseEventListener,
  usePixiOnClickOutside,
  ViewItem,
  colorNameMap,
  ViewItemOptionContainer as BaseViewItemOptionContainer,
  setCursorPosition,
  getBitmapTextStyles,
} from "@poscon/shared-frontend";
import { LogicalPosition } from "@tauri-apps/api/window";
import type {
  ViewButtonOption,
  ViewCounterOption,
  ViewOptionMapValue,
  ViewOptionState,
  ViewToggleOption,
} from "~redux/slices/viewOptionSlice";
import { deltaViewOption, setViewOption } from "~redux/slices/viewOptionSlice";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import type { EramView } from "types/eramView";
import { unsafeEntries } from "@poscon/shared-types";
import { TBE, TBP, viewTitleMap } from "~/eramConstants";
import { useCursorContext } from "contexts/cursorContext";
import type { Container as PixiContainer, FederatedPointerEvent, Graphics as PixiGraphics, Container } from "pixi.js";
import { useBrightContext } from "contexts/brightnessContext";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import { useWindowSize } from "usehooks-ts";

const { width: fontWidth, height: fontHeight } = eramTextDimensionMap[2];
const fontFamily = eramFontNameMap[2];

type ViewOptionRowProps<V extends EramView, O extends Omit<ViewOptionMapValue, "type">> = {
  width: number;
  y: number;
  option: O;
  view: V;
  optionKey: keyof ViewOptionState[V];
  focusRef?: RefObject<PixiGraphics | null>;
};
const ViewOptionCounterRow = <V extends EramView>({
  option,
  view,
  optionKey,
  width,
  y,
  focusRef,
}: ViewOptionRowProps<V, ViewCounterOption>) => {
  const dispatch = useRootDispatch();
  const { onCounterError, onTBHError } = useCursorContext();
  const text = useRootSelector(option.textSelector);
  const ref = useRef<PixiGraphics | null>(null);
  const prevOptionValueRef = useRef(text);
  const prevPosRef = useRef<WindowPosition>({ x: -1, y: -1 });
  const focused = useFocused(ref);
  const { borderTint, tint, buttonBright } = useBrightContext();

  useEffect(() => {
    if (window.__TAURI__ && ref.current) {
      const rect = ref.current.getBounds();
      if (!prevPosRef.current) {
        prevPosRef.current = { x: rect.x, y: rect.y };
      } else if (prevPosRef.current.x !== rect.x || prevPosRef.current.y !== rect.y) {
        if (text !== prevOptionValueRef.current) {
          const newCursorPos = {
            x: rect.x + rect.width / 2,
            y: rect.y + rect.height / 2,
          };
          setTimeout(
            () =>
              WebviewWindow.getCurrent().setCursorPosition(
                new LogicalPosition(newCursorPos.x - 1, newCursorPos.y - 1),
              ),
            20,
          );
        }
      }
      setTimeout(() => {
        prevOptionValueRef.current = text;
      }, 20);
      prevPosRef.current = { x: rect.x, y: rect.y };
    }
  });

  const onmousedown = (event: FederatedPointerEvent) => {
    if (event.button === TBP || event.button === TBE) {
      if (option.type === "delta") {
        let delta = event.button === TBP ? -option.amountPerTick : option.amountPerTick;
        if (optionKey === "bright") {
          delta *= 2;
        }
        dispatch(deltaViewOption(view, optionKey, delta, option.minValue, option.maxValue, onCounterError));
        const intervalId = setInterval(
          () =>
            dispatch(deltaViewOption(view, optionKey, delta, option.minValue, option.maxValue, onCounterError)),
          100,
        );
        window.addEventListener("mouseup", () => clearInterval(intervalId), { once: true });
      } else {
        const delta = event.button === TBP ? -option.amountPerTick : option.amountPerTick;
        dispatch(deltaViewOption(view, optionKey, delta, option.minValue, option.maxValue, onCounterError));
      }
    } else {
      onTBHError();
    }
  };

  usePixiMouseEventListener(onmousedown, ref, true);

  useImperativeHandle<Container | null, Container | null>(focusRef, () => {
    return ref.current;
  }, []);

  return (
    <>
      <graphics
        ref={ref}
        y={y}
        eventMode="static"
        draw={(graphics) => {
          graphics.clear();
          graphics.zIndex = focused ? 1 : 0;
          graphics.rect(0, 0, width, fontHeight + 2).fill(computeColor(colorNameMap.counterGreen, buttonBright))
            .stroke({ width: 1, color: focused ? colorNameMap.white : borderTint });
        }}
      />
      <bitmapText
        x={Math.floor(width / 2 - (text.length * fontWidth) / 2)}
        y={y + 2}
        text={text}
        zIndex={2}
        eventMode="none"
        style={{ ...getBitmapTextStyles(fontFamily), fill: tint }}
      />
    </>
  );
};

const ViewOptionToggleRow = <V extends EramView>({
  option,
  view,
  optionKey,
  width,
  y,
  focusRef,
}: ViewOptionRowProps<V, ViewToggleOption>) => {
  const dispatch = useRootDispatch();
  const text = useRootSelector(option.textSelector);
  const value = useRootSelector(option.valueSelector);
  const ref = useRef<PixiGraphics | null>(null);
  const focused = useFocused(ref);
  const { borderTint, tint, fillColor } = useBrightContext();

  const onmousedown = () => {
    dispatch(setViewOption(view, optionKey, !value));
  };

  usePixiMouseEventListener(onmousedown, ref);

  const draw = (graphics: PixiGraphics) => {
    graphics.clear();
    graphics.zIndex = focused ? 1 : 0;
    graphics.rect(0, 0, width, fontHeight + 2)
      .fill(value ? fillColor : 0)
      .stroke({ width: 1, color: focused ? 0xffffff : borderTint });
  };

  useImperativeHandle<Container | null, Container | null>(focusRef, () => {
    return ref.current;
  }, []);

  return (
    <>
      <graphics ref={ref} y={y} eventMode="static" draw={draw} />
      <bitmapText
        x={Math.floor(width / 2 - (text.length * fontWidth) / 2)}
        y={y + 3}
        text={text}
        zIndex={2}
        eventMode="none"
        style={{ ...getBitmapTextStyles(fontFamily), fill: tint }}
      />
    </>
  );
};

const ViewOptionButtonRow = <T extends EramView>({
  option,
  view,
  optionKey,
  width,
  y,
  focusRef,
}: ViewOptionRowProps<T, ViewButtonOption>) => {
  const dispatch = useRootDispatch();
  const text = useRootSelector(option.textSelector);
  const ref = useRef<PixiGraphics | null>(null);
  const focused = useFocused(ref);
  const { borderTint, tint } = useBrightContext();

  usePixiMouseEventListener(() => {
    option.onClick?.();
    if (option.action) {
      dispatch(option.action());
    }
  }, ref);

  const draw = (graphics: PixiGraphics) => {
    graphics.clear();
    graphics.zIndex = focused ? 1 : 0;
    graphics.rect(0, 0, width, fontHeight + 2).fill(0).stroke({ width: 1, color: focused ? colorNameMap.white : borderTint });
  };

  useImperativeHandle<Container | null, Container | null>(focusRef, () => {
    return ref.current;
  }, []);

  return (
    <>
      <graphics ref={ref} y={y} eventMode="static" draw={draw} />
      <bitmapText
        x={Math.floor(width / 2 - (text.length * fontWidth) / 2)}
        y={y + 2}
        text={text}
        zIndex={2}
        eventMode="none"
        style={{ ...getBitmapTextStyles(fontFamily), fill: tint }}
      />
    </>
  );
};

export type ViewMenuProps<V extends EramView> = {
  view: V;
  parentWidth: number;
  viewMenuTitle?: string;
  options?: Record<string, ViewOptionMapValue>;
  children?: React.ReactNode;
  minWidth?: number;
  initialFocusOverrideIndex?: number;
};

export const ViewMenu = <V extends EramView>({
  view,
  parentWidth,
  options,
  viewMenuTitle = viewTitleMap[view]?.optionTitle ?? "TITLE MISSING",
  children,
  minWidth = 0,
  initialFocusOverrideIndex,
}: ViewMenuProps<V>) => {
  const windowSize = useWindowSize();
  const dispatch = useRootDispatch();
  const ref = useRef<PixiContainer>(null);
  const xRef = useRef<PixiGraphics>(null);
  const focusRef = useRef<PixiGraphics | null>(null);
  const width = Math.max(
    minWidth,
    (viewMenuTitle.length + 2) * fontWidth,
    ...Object.values(options ?? {}).map((option) => (option as { minWidth: number }).minWidth * fontWidth + 4),
  );
  const height = (fontHeight + 2) * ((Object.values(options ?? {})).length + 1)
  const { x, y } = useOptionContainerPos(ref, width, height, parentWidth + 1);
  const { borderTint, tint, fillColor } = useBrightContext();

  usePixiOnClickOutside(ref, () => dispatch(setSelectedViewOption(null)));

  useOnMount(() => {
    if (window.__TAURI__) {
      let rect = null;
      if (focusRef.current) {
        rect = focusRef.current.getBounds();
      } else if (xRef.current) {
        rect = xRef.current.getBounds();
      } else if (ref.current) {
        rect = ref.current.getBounds();
      }
      let yOverflow = 0;
      if (ref.current) {
        yOverflow = Math.max(0, ref.current.getBounds().y + height + 1 - windowSize.height);
      }
      if (rect) {
        const newCursorPos = {
          x: rect.left + rect.width / 2,
          y: rect.y + rect.height / 2 - yOverflow,
        };
        if (ref.current && rect.right > window.innerWidth) {
          newCursorPos.x -= parentWidth + ref.current.getBounds().width;
        }
        void setCursorPosition(newCursorPos.x, newCursorPos.y);
      }
    }
  });

  const xX = width - fontHeight - 2;

  return (
    <container label="VIEW_MENU_CONTAINER" eventMode="static" x={x} y={y} ref={ref} sortableChildren>
      <ViewItem
        eventMode="none"
        text={viewMenuTitle}
        fontSize={2}
        width={width - (fontHeight + 2)}
        fill
        fillColor={fillColor}
        borderColor={borderTint}
        tint={tint}
      />
      <ViewItem
        ref={xRef}
        text="X"
        x={xX}
        width={fontHeight + 2}
        fontSize={2}
        fill
        fillColor={fillColor}
        borderColor={borderTint}
        tint={tint}
        onmousedown={() => dispatch(setSelectedViewOption(null))}
      />
      {options &&
        unsafeEntries(options).map(([key, option], i) => {
          switch (option.type) {
            case "counter":
            case "delta":
              return (
                <ViewOptionCounterRow
                  key={key}
                  view={view}
                  optionKey={key as keyof ViewOptionState[V]}
                  option={option}
                  y={(fontHeight + 2) * (i + 1)}
                  width={width}
                  focusRef={i === initialFocusOverrideIndex ? focusRef : undefined}
                />
              );
            case "toggle":
              return (
                <ViewOptionToggleRow
                  key={key}
                  view={view}
                  optionKey={key as keyof ViewOptionState[V]}
                  option={option}
                  y={(fontHeight + 2) * (i + 1)}
                  width={width}
                  focusRef={i === initialFocusOverrideIndex ? focusRef : undefined}
                />
              );
            case "button":
              return (
                <ViewOptionButtonRow
                  key={key}
                  view={view}
                  optionKey={key as keyof ViewOptionState[V]}
                  option={option}
                  y={(fontHeight + 2) * (i + 1)}
                  width={width}
                  focusRef={i === initialFocusOverrideIndex ? focusRef : undefined}
                />
              );
            default:
              return null;
          }
        })}
      {children && (
        <container y={(fontHeight + 2) * (Object.keys(options ?? {}).length + 1)}>{children}</container>
      )}
    </container>
  );
};

export const ViewItemOptionContainer = (props: Omit<ViewItemOptionContainerProps, "borderTint">) => {
  const { borderTint } = useBrightContext();

  return <BaseViewItemOptionContainer {...props} borderTint={borderTint} />;
};
