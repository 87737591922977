import { useDrawContext } from "contexts/drawContext";
import type { Container } from "pixi.js";
import React from "react";
import { useUiIsLocked } from "@poscon/shared-frontend";
import { useAnyDragging } from "contexts/dragContext";
import { PixiElements } from "@pixi/react";

export const useEventMode = () => {
  const { drawMode } = useDrawContext();
  const uiIsLocked = useUiIsLocked();
  const { anyDragging } = useAnyDragging();

  return !["CLOSED", "OPEN", "DELETE_ALL", "DELETE"].includes(drawMode) || anyDragging || uiIsLocked
    ? "none"
    : "static";
};

type InteractionWrapperProps = {
  children?: React.ReactNode;
  ref?: React.RefObject<Container | null>;
} & Omit<PixiElements["container"], "eventMode" | "children">;
export const InteractiveContainer = ({ children, label, ref, ...props }: InteractionWrapperProps) => {
  const eventMode = useEventMode();

  return (
    <container {...props} label={label} sortableChildren ref={ref} eventMode={eventMode}>
      {children}
    </container>
  );
};
