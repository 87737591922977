import React from "react";
import { GeoMap } from "components/map/GeoMap";
import type { GeomapConfig, GeomapFilterButtonPosition } from "@poscon/shared-types/eram";

type GeomapsProps = {
  geomapConfig: GeomapConfig;
};
export const Geomaps = ({ geomapConfig }: GeomapsProps) => {
  return (
    <container label="GEOMAP_CONTAINER" sortableChildren zIndex={2} eventMode="none">
      {Array.from({ length: 20 }, (_, i) => (
        <GeoMap key={i} geomapConfig={geomapConfig} filterGroup={(i + 1) as GeomapFilterButtonPosition} />
      ))}
    </container>
  );
};
