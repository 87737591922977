import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { useDragging } from "hooks/useDragging";
import { setViewPosition, viewPositionSelector } from "~redux/slices/eramStateSlice";
import type { Container as PixiContainer } from "pixi.js";
import { layerZIndexMap } from "~/layerZIndexMap";
import type { AircraftMenuView } from "@poscon/shared-frontend";
import { AircraftMenuComponentMap, resetViewMenuFields, useOnUnmount, usePixiOnClickOutside } from "@poscon/shared-frontend";
import { useEventMode } from "components/utils/InteractiveContainer";
import { setAsel } from "~/redux/slices/eramTempStateSlice";
import { EramFlightplan } from "@poscon/shared-types/eram";

const AircraftMenu = ({ fp, view }: { fp: EramFlightplan; view: AircraftMenuView }) => {
  const dispatch = useRootDispatch();
  const ref = useRef<PixiContainer | null>(null);
  const pos = useRootSelector((state) => viewPositionSelector(state, view));
  const { startDrag } = useDragging(ref, view);
  const eventMode = useEventMode();

  usePixiOnClickOutside(ref, () => {
    dispatch(setAsel(null));
  });

  useOnUnmount(() => {
    dispatch(resetViewMenuFields(view));
  });

  const Component = AircraftMenuComponentMap[view];

  return (
    <Component
      ref={ref}
      key={fp.id}
      eventMode={eventMode}
      fp={fp}
      pos={pos}
      startDrag={startDrag}
      closeMenu={() => dispatch(setAsel(null))}
      openMenu={(menu) => {
        dispatch(setViewPosition({ view: menu, pos }));
        dispatch(setAsel({ menu, fpId: fp.id }));
      }}
      zIndex={layerZIndexMap.dbMenus}
    />
  );
};

type AircraftMenuProps = { fp: EramFlightplan };
export type EramAircraftMenuProps = AircraftMenuProps;

export const AltitudeMenu = (props: AircraftMenuProps) => <AircraftMenu {...props} view="ALTITUDE_MENU" />;

export const SpeedMenu = (props: AircraftMenuProps) => <AircraftMenu {...props} view="SPEED_MENU" />;

export const HeadingMenu = (props: AircraftMenuProps) => <AircraftMenu {...props} view="HEADING_MENU" />;

export const XResMenu = (props: AircraftMenuProps) => <AircraftMenu {...props} view="X_RES_MENU" />;

export const RouteMenu = (props: AircraftMenuProps) => <AircraftMenu {...props} view="ROUTE_MENU" />;

export const HoldMenu = (props: AircraftMenuProps) => <AircraftMenu {...props} view="HOLD_MENU" />;

export const FreeTextMenu = (props: AircraftMenuProps) => <AircraftMenu {...props} view="FREETEXT_MENU" />;

