import React, { useRef } from "react";
import type { Container as PixiContainer, Graphics as PixiGraphics } from "pixi.js";
import { useFocused, usePixiMouseEventListener } from "@poscon/shared-frontend";
import { drawColorMap } from "~/utils/drawColorMap";
import type { ColorName } from "@poscon/shared-types";

type ColorPaletteProps = {
  width: number;
  height: number;
  spacing: number;
  selectedColor: ColorName;
  setSelectedColor: (color: ColorName) => void;
};
export const ColorPalette = ({ width, height, spacing, selectedColor, setSelectedColor }: ColorPaletteProps) => {
  const containerRef = useRef<PixiContainer>(null);
  const greenRef = useRef<PixiGraphics>(null);
  const redRef = useRef<PixiGraphics>(null);
  const greyRef = useRef<PixiGraphics>(null);
  const yellowRef = useRef<PixiGraphics>(null);
  const greenFocused = useFocused(greenRef);
  const redFocused = useFocused(redRef);
  const greyFocused = useFocused(greyRef);
  const yellowFocused = useFocused(yellowRef);

  const draw = (graphics: PixiGraphics, _color: ColorName, focused: boolean) => {
    const color = drawColorMap[_color];
    graphics.clear();
    graphics.rect(1, 1, width, height).fill(color).stroke({ width: 1, color: focused || selectedColor === _color ? 0xffffff : color });
  };

  usePixiMouseEventListener((event) => {
    const color = (event.target as unknown as { label?: string })?.label
      ?.split("_")
      ?.at(-1)
      ?.toLowerCase() as ColorName;
    setSelectedColor(color);
  }, containerRef);

  return (
    <container ref={containerRef} eventMode="static" zIndex={1}>
      <graphics
        label="COLOR_PALETTE_GREEN"
        eventMode="static"
        draw={(graphics) => draw(graphics, "green", greenFocused)}
        x={spacing}
        y={spacing}
        ref={greenRef}
      />
      <graphics
        label="COLOR_PALETTE_RED"
        eventMode="static"
        draw={(graphics) => draw(graphics, "red", redFocused)}
        x={spacing + width + spacing}
        y={spacing}
        ref={redRef}
      />
      <graphics
        label="COLOR_PALETTE_GREY"
        eventMode="static"
        draw={(graphics) => draw(graphics, "grey", greyFocused)}
        x={spacing}
        y={spacing + height + spacing}
        ref={greyRef}
      />
      <graphics
        label="COLOR_PALETTE_YELLOW"
        eventMode="static"
        draw={(graphics) => draw(graphics, "yellow", yellowFocused)}
        x={spacing + width + spacing}
        y={spacing + height + spacing}
        ref={yellowRef}
      />
    </container>
  );
};
