import React, { useLayoutEffect, useRef, useState } from "react";

import { useButtonContext } from "contexts/buttonContext";
import type { EramButtonId } from "types/eramButton";
import { colorNameMap, computeColor } from "@poscon/shared-frontend";
import type { ColorSource, Container as PixiContainer } from "pixi.js";
import { Color } from "pixi.js";
import { useSituationDisplay } from "contexts/sdContext";

const containerFillColor = colorNameMap.grey;
const containerOutline = new Color("#8e5048");

type ButtonMenuContainerProps = {
  name?: string;
  buttonId: EramButtonId;
  rows?: readonly (readonly EramButtonId[])[];
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  parentX?: number;
  parentY?: number;
  direction?: "horizontal" | "vertical";
  anchor?: "top" | "bottom";
  fillColor?: ColorSource;
  children?: React.ReactNode;
};
export const ButtonMenuContainer = ({
  buttonId,
  name = `${buttonId}_MENU_CONTAINER`,
  rows = [],
  anchor,
  direction = "horizontal",
  fillColor: _fillColor,
  width: _width,
  height: _height,
  x: _x,
  y: _y,
  parentX,
  parentY,
  children,
}: ButtonMenuContainerProps) => {
  const { buttonWidth, buttonHeight, buttonBright } = useButtonContext();
  const [rightOverflow, setRightOverflow] = useState(false);
  const [leftOverflow, setLeftOverflow] = useState(false);
  const [heightOverflow, setHeightOverflow] = useState(false);

  const { rect: sdRect } = useSituationDisplay();
  const ref = useRef<PixiContainer>(null);

  const width =
    _width ??
    (buttonWidth + 1) * (direction === "horizontal" ? Math.max(...rows.map((row) => row.length)) : rows.length) +
    1;
  const height =
    _height ??
    (buttonHeight + 1) * (direction === "horizontal" ? rows.length : Math.max(...rows.map((row) => row.length))) +
    1;

  useLayoutEffect(() => {
    if (ref.current) {
      const pos = ref.current.getGlobalPosition();
      setLeftOverflow(pos.x - (buttonWidth + 2) - width < sdRect.x);
      setRightOverflow(pos.x + width > sdRect.x + sdRect.width);
      setHeightOverflow(pos.y + height > sdRect.y + sdRect.height + 1)
    }
  }, [width, height, sdRect, parentX, parentY]);

  const lineColor = containerOutline;
  const fillColor = _fillColor ?? computeColor(containerFillColor, buttonBright);
  let x = _x ?? (direction === "horizontal" ? buttonWidth + 2 : 0);
  let y = _y ?? (direction === "vertical" ? buttonHeight + 2 : 0);

  if (direction === "horizontal") {
    if ((_y === undefined && anchor === "bottom") || heightOverflow) {
      y = - (height - buttonHeight - 2);
    }
    if (rightOverflow && !leftOverflow) {
      x -= width + buttonWidth + 2;
    }
  } else {
    if (rightOverflow && !leftOverflow) {
      x -= (buttonWidth + 1) * (rows.length - 1);
    }
    if (heightOverflow) {
      y -= height - buttonHeight - 2;
    }
  }

  return (
    <container x={x} y={y} ref={ref} eventMode="static" sortableChildren>
      <graphics
        label={name}
        draw={(graphics) => {
          graphics.clear();
          graphics.rect(-1, -1, width, height).fill(fillColor ?? computeColor(containerFillColor, buttonBright))
            .stroke({ width: 1, color: lineColor });
        }}
      />
      {children}
    </container>
  );
};
