import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { View } from "components/utils/View";
import {
  backgroundOption,
  borderOption,
  brightOption,
  buttonOption,
  counterOption,
  fontOption,
  linesOption,
  toggleOption,
  viewOptionSelector,
} from "~redux/slices/viewOptionSlice";
import { altimTearoffSelector } from "~redux/slices/eramStateSlice";
import {
  createItemOption,
  ViewItemOptionContainer,
  colorNameMap,
  computeColor,
  eramFontDimensionMap,
  eramFontNameMap,
  ScrollBar,
  useAltimeter,
  useCurrentTime,
  useFocused,
  useScrollProps,
  MenuElement,
  useViewOptionSelected,
  altimeterAirportsSelector,
  getBitmapTextStyles,
  useHitArea,
} from "@poscon/shared-frontend";
import type { EramFontSize } from "@poscon/shared-frontend";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import { useWeatherTearOffContext } from "contexts/weatherTearOffContext";
import { TBE, TBP } from "~/eramConstants";
import { Cross } from "components/utils/Cross";
import type { FederatedPointerEvent, Graphics as PixiGraphics } from "pixi.js";
import { Rectangle } from "pixi.js";
import { useViewOptions, ViewOptionContextProvider } from "contexts/viewOptionContext";
import { useTearOffContext } from "contexts/tearOffContext";
import { formatUtcTime } from "@poscon/shared-types";
import { stringToParsedTokenArray } from "@poscon/shared-types/eram";

const view = "ALTIM_SET";

const optionMap = {
  background: backgroundOption(view),
  border: borderOption(view),
  tearoff: toggleOption(view, "tearoff", "TEAROFF", 7),
  lines: linesOption(view, 2, 21),
  column: counterOption(view, "column", "COLUMN", 1, 4, 9),
  font: fontOption(view),
  bright: brightOption(view),
  template: buttonOption(view, "TEMPLATE", 8),
};

type AltimeterRowProps = {
  stationCode: string;
  x: number;
  y: number;
  asTearoff?: boolean;
};
export const AltimeterRow = ({ stationCode, x, y, asTearoff = false }: AltimeterRowProps) => {
  const dispatch = useRootDispatch();
  const { selected: _selected, toggleViewOption } = useViewOptionSelected(`${view}/${stationCode}`);

  const ref = useRef<PixiGraphics>(null);
  const focused = useFocused(ref);
  const { submitDelTearOffEvent } = useTearOffContext();
  const tearoffPosition = useRootSelector((state) => altimTearoffSelector(state, stationCode));
  const viewOptions = useViewOptions(view);
  const itemOptions = [
    createItemOption(`DELETE ${stationCode}`, () => {
      dispatch(processEramMessage(stringToParsedTokenArray(`QD ${stationCode}`)));
    }),
  ];

  const selected = !asTearoff && _selected;
  const fontSize = viewOptions.font as EramFontSize;
  const fontFamily = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontFamily];

  const { startTearOff, delTearOffCommandActive, toggleDeletionItem, pendingDeletionItems } =
    useWeatherTearOffContext();
  const { altimeter, timeRecorded } = useAltimeter(stationCode);

  const now = useCurrentTime();
  const observationThreshold1 = new Date(timeRecorded + 3900e3);
  const observationThreshold2 = new Date(timeRecorded + 7200e3);

  const isPendingDeletion = pendingDeletionItems.some(
    (item) => item.airport === stationCode && item.itemType === "ALTIM",
  );

  const offset = asTearoff ? 0 : fontDimension.width * 2 - 4;
  const width = 17 * fontDimension.width + 4 - offset;
  const height = fontDimension.height + 2;

  const hitAreaRef = useHitArea(offset, 0, width, height);

  const alpha = (viewOptions.bright * 0.8) / 100 + 0.2;

  const tint = selected ? 0 : computeColor(colorNameMap.white, viewOptions.bright / 100);
  const style = { ...getBitmapTextStyles(fontFamily), fill: tint };

  const bgColor = computeColor(
    selected ? colorNameMap.grey : colorNameMap.black,
    !asTearoff && selected ? alpha : 1,
  );

  return (
    <container x={x} y={y} zIndex={selected ? 2 : 1}>
      <container sortableChildren>
        {(viewOptions.tearoff || asTearoff) && (
          <MenuElement
            x={2}
            y={2}
            width={fontDimension.width + 2}
            height={fontDimension.height - 3}
            disabled={!(asTearoff || !tearoffPosition) || delTearOffCommandActive}
            zIndex={4}
            fillColor={computeColor(
              asTearoff || !tearoffPosition ? colorNameMap.yellow : colorNameMap.grey,
              alpha,
            )}
            onmousedown={(event) => ref.current && startTearOff(event, stationCode, "ALTIM", ref.current)}
          />
        )}
        <graphics
          ref={ref}
          hitArea={hitAreaRef.current}
          eventMode={asTearoff && !delTearOffCommandActive ? "none" : "static"}
          zIndex={1}
          draw={(graphics) => {
            graphics.clear();
            graphics
              .rect(offset, 0, width, height)
              .fill({ color: bgColor, alpha: asTearoff || selected ? 1 : 0 })
              .stroke({
                width: 1,
                color:
                  !asTearoff && focused
                    ? 0xffffff
                    : asTearoff
                      ? colorNameMap.darkGrey
                      : selected
                        ? bgColor
                        : 0x000000,
              });
            if (observationThreshold1 < now) {
              graphics
                .moveTo(fontDimension.width * 7, fontDimension.height)
                .lineTo(fontDimension.width * 11, fontDimension.height)
                .stroke({ width: 1, color: tint });
            }
            if (altimeter && parseInt(altimeter, 10) < 2992) {
              graphics
                .moveTo(fontDimension.width * 13, fontDimension.height)
                .lineTo(fontDimension.width * 16, fontDimension.height)
                .stroke({ width: 1, color: tint });
            }
          }}
          onMouseDown={(event: FederatedPointerEvent) => {
            if (asTearoff && delTearOffCommandActive) {
              if (event.button === TBP) {
                toggleDeletionItem(stationCode, "ALTIM");
              }
              if (event.button === TBE) {
                submitDelTearOffEvent({ stationCode, itemType: "ALTIM" });
              }
            } else if (!asTearoff) {
              toggleViewOption();
            }
          }}
        />
        <bitmapText
          x={fontDimension.width * 2}
          y={2}
          zIndex={3}
          text={stationCode}
          eventMode="none"
          style={style}
        />
        {altimeter && (
          <bitmapText
            x={fontDimension.width * 7}
            y={2}
            zIndex={3}
            text={formatUtcTime(timeRecorded)}
            eventMode="none"
            style={style}
          />
        )}
        <bitmapText
          x={fontDimension.width * 13}
          y={2}
          zIndex={3}
          text={!altimeter || observationThreshold2 < now ? "-M-" : altimeter.slice(1)}
          eventMode="none"
          style={style}
        />
        {asTearoff && isPendingDeletion && <Cross width={width} height={height} />}
      </container>
      {selected && <ViewItemOptionContainer baseX={offset} xOffset={width} options={itemOptions} />}
    </container>
  );
};

export const AltimSet = () => {
  const airports = useRootSelector(altimeterAirportsSelector);
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));
  const columnsInUse = [...new Set(airports.map(({ index }) => index % viewOptions.column))].sort();
  const rowsInUse = [...new Set(airports.map(({ index }) => Math.floor(index / viewOptions.column)))].sort();
  const newAirports = airports.map((a) => {
    const row = Math.floor(a.index / viewOptions.column);
    const col = a.index % viewOptions.column;
    const actualRow = rowsInUse.indexOf(row);
    const actualCol = columnsInUse.indexOf(col);
    return { station: a.station, actualRow, actualCol };
  });
  const maxRow = Math.max(0, ...newAirports.map(({ actualRow }) => actualRow + 1));
  const maxCol = Math.max(0, ...newAirports.map(({ actualCol }) => actualCol + 1));
  const { scroll, ...scrollProps } = useScrollProps("altimSet", maxRow, viewOptions.lines);
  const fontDimension = eramFontDimensionMap[eramFontNameMap[viewOptions.font as EramFontSize]];

  const height = maxRow > 0 ? (fontDimension.height + 6) * Math.min(viewOptions.lines, maxRow) + 4 : 0;
  const columnsToDisplay = maxCol > 0 ? Math.min(viewOptions.column, maxCol) : 1;

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View width={20 * columnsToDisplay} view="ALTIM_SET" optionMap={optionMap} height={height}>
        {newAirports.length > 0 && (
          <container x={0} y={4} zIndex={2} sortableChildren>
            {newAirports.map(({ station, actualRow, actualCol }) => {
              const x = fontDimension.width * 20 * actualCol + 10;
              const renderRow = actualRow - scroll;
              const shouldRender = actualRow >= scroll && actualRow < scroll + viewOptions.lines;
              return (
                shouldRender && (
                  <AltimeterRow
                    key={`${station}-${actualRow}-${actualCol}`}
                    stationCode={station}
                    x={x}
                    y={(fontDimension.height + 6) * renderRow}
                  />
                )
              );
            })}
          </container>
        )}
        {viewOptions.lines < maxRow && (
          <ScrollBar
            height={height - 4}
            x={(20 * columnsToDisplay - 1) * fontDimension.width - 3}
            y={2}
            tint={computeColor(colorNameMap.white, viewOptions.bright / 100)}
            disabledTint={computeColor(colorNameMap.grey, viewOptions.bright / 100)}
            fontSize={viewOptions.font as EramFontSize}
            {...scrollProps}
          />
        )}
      </View>
    </ViewOptionContextProvider>
  );
};
