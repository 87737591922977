export const layerZIndexMap = {
  buttons: 90,
  priorityButtons: 1000,
  dbMenus: 200,
  prompts: 201,
  map: 1,
  situationDisplay: 0,
  timeOverTracks: 199,
  timeUnderTracks: 99,
  toolbar: 101,
  tracks: 100,
  viewMenu: 190,
  viewsOverTracksBase: 102,
  viewsUnderTracksBase: 20,
};
