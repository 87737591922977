import type { Coordinate } from "@poscon/shared-types";
import * as turf from "@turf/turf";
import { geoMercator } from "d3-geo";
import { Matrix, Point, Rectangle } from "pixi.js";
import type { ListenerEntry } from "types/listenerEntry";
import { listenerMiddleware } from "~redux/listenerMiddleware";
import {
  rangeCenterOverrideSelector,
  rangeCenterSelector,
  setRangeCenter,
  mapScaleSelector,
} from "~redux/slices/eramTempStateSlice";
import { BaseStore } from "@poscon/shared-frontend";
import type { RootStore } from "~redux/store";
import {
  setMapScaleToRange,
  setRangeAction,
  setRangeCenterOverrideToLonLat,
  setRangeCenterOverrideToLonLatAction,
} from "~redux/thunks/mapThunks";
import { RangeCenter } from "@poscon/shared-types/eram";

let store: RootStore;
export const injectStore = (s: RootStore) => {
  store = s;
  situationDisplayStore.updateRange();
};

const listeners: ListenerEntry[] = [
  {
    predicate: (action, state, prevState) =>
      rangeCenterOverrideSelector(state) !== rangeCenterOverrideSelector(prevState) ||
      mapScaleSelector(state) !== mapScaleSelector(prevState),
    effect: (action, { getState, dispatch }) => {
      const prevRange = situationDisplayStore.range;
      const state = getState();
      const rangeCenterOverride = rangeCenterOverrideSelector(state);
      const mapScale = mapScaleSelector(state);
      situationDisplayStore.matrix = new Matrix(
        mapScale,
        0,
        0,
        mapScale,
        rangeCenterOverride[0],
        rangeCenterOverride[1],
      );
      situationDisplayStore.updateLonLatCenter();
      if ((action.meta as any)?.keepRange) {
        dispatch(setMapScaleToRange(prevRange));
      } else {
        situationDisplayStore.updateRange();
      }
      situationDisplayStore.sync();
    },
  },
  {
    predicate: (action) => setRangeCenter.match(action),
    effect: (action, { getState }) => {
      const state = getState();
      const rangeCenter = rangeCenterSelector(state);
      situationDisplayStore.rangeCenterUpdate(rangeCenter);
    },
  },
];

// to draw datablocks even if the position symbol is outside the SD
const sdPadding = 100;

for (const listener of listeners) {
  // @ts-ignore
  listenerMiddleware.startListening(listener);
}

export type SituationDisplayState = {
  rect: Rectangle;
  range: number;
  matrix: Matrix;
  projection: ReturnType<typeof geoMercator>;
  getLonLatFromWindowCoord: (coord: Coordinate) => Coordinate;
  getLonLatFromSdCoord: (coord: Coordinate) => Coordinate;
  getSdCoordFromLonLat: (coord: Coordinate) => Coordinate;
  getWindowCoordFromLonLat: (coord: Coordinate) => Coordinate;
  getSdCoordinates: (coord: Coordinate) => Coordinate;
  getWindowCoordinatesFromSdCoordinates: (coord: Coordinate) => Coordinate;
};

class SituationDisplayStore extends BaseStore<SituationDisplayState> {
  rect = new Rectangle(0, 0, 0, 0);

  paddedRect = new Rectangle(0, 0, 0, 0);

  projection = geoMercator().scale(14000).translate([0, 0]);

  matrix = Matrix.IDENTITY;

  protected state = this.computeLatestState();

  constructor() {
    super();

    window.addEventListener("resize", () => {
      this.updateRect();
    });
    this.updateRect();
  }

  range = 300;

  lonLatCenter: Coordinate = [0, 0];

  getLatestLonLatCenter() {
    return this.getLonLatFromSdCoordinate([this.rect.width / 2, this.rect.height / 2]);
  }

  updateLonLatCenter() {
    this.lonLatCenter = this.getLatestLonLatCenter();
    store.dispatch(setRangeCenterOverrideToLonLatAction(this.lonLatCenter));
  }

  updateRange() {
    this.range = this.getLatestRange();
    store.dispatch(setRangeAction(this.range));
  }

  rangeCenterUpdate(rangeCenter: RangeCenter) {
    const { innerWidth: width, innerHeight: height } = window;
    this.projection = geoMercator()
      .scale(14000)
      .translate([width / 2, height / 2])
      .center([rangeCenter.centerLongitude, rangeCenter.centerLatitude]);
    this.updateLonLatCenter();
    this.sync();
  }

  getLatestRange() {
    return Math.round(
      turf.distance(
        this.getLonLatFromSdCoordinate([0, this.rect.height / 2]),
        this.getLonLatFromSdCoordinate([this.rect.width, this.rect.height / 2]),
        {
          units: "nauticalmiles",
        },
      ),
    );
  }

  getRangeAtScale(scale: number, rangeCenterOverride: Coordinate) {
    const matrix = new Matrix(scale, 0, 0, scale, rangeCenterOverride[0], rangeCenterOverride[1]);
    return Math.round(
      turf.distance(
        this.getLonLatFromSdCoordinate([0, this.rect.height / 2], matrix),
        this.getLonLatFromSdCoordinate([this.rect.width, this.rect.height / 2], matrix),
        {
          units: "nauticalmiles",
        },
      ),
    );
  }

  private updateRect() {
    const { innerWidth: width, innerHeight: height } = window;
    const newWidth = Math.min(width, 3072);
    this.rect = new Rectangle(Math.floor((width - newWidth) / 2), 0, newWidth, height);
    this.paddedRect = this.rect.clone().pad(sdPadding);
    this.projection = geoMercator()
      .scale(14000)
      .translate([Math.floor(width / 2), Math.floor(height / 2)])
      .center(this.projection.center());
    store?.dispatch(setRangeCenterOverrideToLonLat(this.lonLatCenter, { keepRange: true }));
    this.sync();
  }

  // return lon/lat from window coordinate
  getLonLatFromWindowCoord(coordinate: Coordinate, matrix = this.matrix): Coordinate {
    const point = new Point(coordinate[0], coordinate[1]);
    matrix.applyInverse(point, point);
    return this.projection.invert!([point.x, point.y])!;
  }

  getLonLatFromSdCoordinate(coordinate: Coordinate, matrix = this.matrix) {
    return this.getLonLatFromWindowCoord([this.rect.x + coordinate[0], this.rect.y + coordinate[1]], matrix);
  }

  getWindowCoordFromLonLat(coordinate: Coordinate): Coordinate {
    const projectedPoint = this.projection(coordinate)!;
    const point = new Point(projectedPoint[0], projectedPoint[1]);
    this.matrix.apply(point, point);
    return [point.x, point.y];
  }

  // return sd coordinate from lon/lat
  getSdCoordFromLonLat(coordinate: Coordinate): Coordinate {
    const windowCoord = this.getWindowCoordFromLonLat(coordinate);
    return [windowCoord[0] - this.rect.x, windowCoord[1] - this.rect.y];
  }

  get pixelLen() {
    return turf.distance(this.getLonLatFromSdCoordinate([0, 0]), this.getLonLatFromSdCoordinate([1, 0]), {
      units: "nauticalmiles",
    });
  }

  nmToPx(nm: number) {
    return Math.floor(nm / this.pixelLen);
  }

  getSdCoordinates(coord: Coordinate): Coordinate {
    return [coord[0] - this.rect.x, coord[1] - this.rect.y];
  }

  getWindowCoordinatesFromSdCoordinates(coord: Coordinate): Coordinate {
    return [coord[0] + this.rect.x, coord[1] + this.rect.y];
  }

  protected computeLatestState() {
    const projection = this.projection;
    const matrix = this.matrix;
    const rect = this.rect;
    const range = this.range;
    return {
      rect,
      range,
      matrix,
      projection,
      getLonLatFromWindowCoord: this.getLonLatFromWindowCoord.bind(this),
      getLonLatFromSdCoord: this.getLonLatFromSdCoordinate.bind(this),
      getSdCoordFromLonLat: this.getSdCoordFromLonLat.bind(this),
      getWindowCoordFromLonLat: this.getWindowCoordFromLonLat.bind(this),
      getSdCoordinates: this.getSdCoordinates.bind(this),
      getWindowCoordinatesFromSdCoordinates: this.getWindowCoordinatesFromSdCoordinates.bind(this),
    };
  }
}

export const situationDisplayStore = new SituationDisplayStore();
