import React from "react";
import { useRootSelector } from "~redux/hooks";
import { altimTearoffMapSelector, metarTearoffMapSelector } from "~redux/slices/eramStateSlice";
import { MetarTearoff } from "components/utils/metarTearOff";
import { AltimeterTearoff } from "components/utils/altimTearOff";
import { viewOptionSelector } from "~redux/slices/viewOptionSlice";
import { ViewOptionContextProvider } from "contexts/viewOptionContext";

export const WeatherTearOffItems = () => {
  const altimList = useRootSelector(altimTearoffMapSelector);
  const wxList = useRootSelector(metarTearoffMapSelector);
  const altimViewOptions = useRootSelector((state) => viewOptionSelector(state, "ALTIM_SET"));
  const wxViewOptions = useRootSelector((state) => viewOptionSelector(state, "WX_REPORT"));

  return (
    <container zIndex={1}>
      <ViewOptionContextProvider options={altimViewOptions}>
        {Object.entries(altimList).map(([airport, position]) => (
          <AltimeterTearoff key={airport} airport={airport} pos={position} />
        ))}
      </ViewOptionContextProvider>
      <ViewOptionContextProvider options={wxViewOptions}>
        {Object.entries(wxList).map(([airport, position]) => (
          <MetarTearoff key={airport} airport={airport} pos={position} />
        ))}
      </ViewOptionContextProvider>
    </container>
  );
};
