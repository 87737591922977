import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import { LogicalPosition } from "@tauri-apps/api/window";
import { useStableCallback } from "@poscon/shared-frontend";
import { useApplication } from "@pixi/react";

export const useMoveCursorToElement = () => {
  const app = useApplication();
  return useStableCallback((id: string) => {
    const graphics = app.app.stage.getChildByName(id, true);
    if (window.__TAURI__ && graphics) {
      const pos = graphics.getGlobalPosition();
      const rect = graphics.getLocalBounds();
      const newCursorPos = {
        x: pos.x + rect.width / 2,
        y: pos.y + rect.height / 2,
      };
      void WebviewWindow.getCurrent().setCursorPosition(
        new LogicalPosition(newCursorPos.x - 1, newCursorPos.y - 1),
      );
    }
  });
};
