import { nxAltDisplayItemSelector, nxBrightSelector, nxLevelSelector } from "~redux/slices/eramStateSlice";
import type { Container } from "pixi.js";
import { Color, Filter, Graphics, GlProgram, GpuProgram, defaultFilterVert } from "pixi.js";
import type { ListenerEntry } from "types/listenerEntry";
import { listenerMiddleware } from "~redux/listenerMiddleware";
import { situationDisplayStore } from "~/situationDisplayStore";
import debounce from "lodash.debounce";
import { env } from "~/env";

type NexradData = [number, number, number, number][];

const nexradWorker = new Worker(new URL("./workers/nexrad.ts", import.meta.url), { type: "module" });

const fragment = `
uniform highp vec4 uInputSize;

in vec2 vTextureCoord;

out vec4 finalColor;

uniform sampler2D uTexture;

void main()
{
    float total = floor(vTextureCoord.x * float(uInputSize.x) * 0.5) + floor(vTextureCoord.y * float(uInputSize.y) * 0.5);
    bool isEven = mod(total, 2.0) == 0.0;
    finalColor = (isEven) ? vec4(0.0, 0.0, 0.0, 0.0) : texture2D(uTexture, vTextureCoord);
}
`;

// const gpuProgram = GpuProgram.from({ fragment: { source, entryPoint: "mainFragment" }, vertex: { source: defaultFilterVert, entryPoint: "mainVertex" } });
const glProgram = GlProgram.from({ fragment, vertex: defaultFilterVert, name: "checkerboard-filter" });

const checkerboardFilter = new Filter({ glProgram });
const level2Filters = [checkerboardFilter];

const NEXRAD_COLOR_MAP = {
  1: new Color(0x0030ff),
  2: new Color(0x00ff50),
  3: new Color(0x00ff50),
};

const nxLevelMap = {
  0: [],
  1: [1, 2, 3],
  2: [2, 3],
  3: [3],
} as Record<0 | 1 | 2 | 3, (1 | 2 | 3)[]>;

// const nexradStations = ["KOKX", "KDIX", "KDOX", "KAKQ", "KMHX", "KBOX", "KLTX"];
const nexradStations = ["KOKX", "KDIX", "KAKQ", "KRLX"];

const listeners: ListenerEntry[] = [
  {
    predicate: (action, state, prevState) => nxBrightSelector(state) !== nxBrightSelector(prevState),
    effect: (action, { getState }) => {
      const alpha = nxBrightSelector(getState()) / 100;
      nexradManager.level1Graphics.alpha = alpha;
      nexradManager.level2Graphics.alpha = alpha;
      nexradManager.level3Graphics.alpha = alpha;
    },
  },
  // {
  //   predicate: (action, state, prevState) => nexradStationsSelector(state) !== nexradStationsSelector(prevState),
  //   effect: (action, { getState }) => {
  //     const sites = nexradStationsSelector(getState());
  //   },
  // },
  {
    predicate: (action, state, prevState) => nxLevelSelector(state) !== nxLevelSelector(prevState),
    effect: (action, { getState }) => {
      nexradManager.displayLevels = nxLevelMap[nxLevelSelector(getState())];
      nexradManager.draw();
    },
  },
  {
    predicate: (action, state, prevState) =>
      nxAltDisplayItemSelector(state) !== nxAltDisplayItemSelector(prevState),
    effect: (action, { getState }) => {
      const displayItem = nxAltDisplayItemSelector(getState());
      nexradManager.minAlt = displayItem.nexradLayerFloor;
      nexradManager.maxAlt = displayItem.nexradLayerCeiling;
      nexradManager.filterData();
    },
  },
];

for (const listener of listeners) {
  // @ts-ignore
  listenerMiddleware.startListening(listener);
}

type NexradLevel = 1 | 2 | 3;

type CombinedFetchMessage = {
  type: "fetchCombined";
  data: {
    result: NexradData;
    stationDetails: { site: string; last: string | null }[];
  };
};
type FilterCombinedMessage = {
  type: "filterCombined";
  data: [number, number, NexradLevel][];
};
type NexradWorkerMessage = CombinedFetchMessage | FilterCombinedMessage;

const NEXRAD_SERVER_URL = env.VITE_NEXRAD_SERVER_URL;
const NEXRAD_POLLING_INTERVAL = 5 * 60e3; // 5 minutes

class NexradManager {
  level1Graphics = new Graphics();

  level2Graphics = new Graphics();

  level3Graphics = new Graphics();

  // lon, lat, alt, value
  data: [number, number, number, number][] = [];

  // lon, lat, level
  dataToDraw: [number, number, NexradLevel][] = [];

  sites: string[] = nexradStations;

  stationDetails: { site: string; last: string | null }[] = this.sites.map((site) => ({ site, last: null }));

  updateIntervalId: ReturnType<typeof setInterval> | null = null;

  displayLevels: NexradLevel[] = [1, 2, 3];

  minAlt = 0;

  maxAlt = 600;

  isFetching = false;

  container: Container | null = null;

  constructor() {
    this.level1Graphics.zIndex = 1;
    this.level2Graphics.zIndex = 2;
    this.level3Graphics.zIndex = 3;

    this.level2Graphics.filters = level2Filters;
    this.draw();

    nexradWorker.addEventListener("message", (e: MessageEvent<NexradWorkerMessage>) => {
      if (e.data.type === "fetchCombined") {
        this.handleFetch(e.data);
      }
      if (e.data.type === "filterCombined") {
        this.dataToDraw = e.data.data;
        this.draw();
      }
    });
    this.updateIntervalId = setInterval(() => {
      this.fetchData();
    }, NEXRAD_POLLING_INTERVAL);
    this.fetchData();
    situationDisplayStore.subscribe(() => {
      this.draw();
    });
  }

  handleFetch(msg: CombinedFetchMessage) {
    this.data = msg.data.result;
    this.stationDetails = msg.data.stationDetails;
    this.isFetching = false;
    this.filterData();
  }

  fetchData() {
    if (!this.isFetching) {
      this.isFetching = true;
      nexradWorker.postMessage({
        type: "fetchCombined",
        data: { url: NEXRAD_SERVER_URL, sites: this.stationDetails },
      });
    }
  }

  filterData() {
    nexradWorker.postMessage({
      type: "filterCombined",
      data: { nxData: this.data, min: this.minAlt, max: this.maxAlt },
    });
  }

  draw = debounce(() => {
    const projection = situationDisplayStore.projection;

    this.level1Graphics.clear();
    this.level2Graphics.clear();
    this.level3Graphics.clear();

    for (const [lon, lat, level] of this.dataToDraw) {
      if (this.displayLevels.includes(level)) {
        const topLeft = projection([(lon - 0.5) / 100, (lat + 0.5) / 100]);
        const bottomRight = projection([(lon + 0.5) / 100, (lat - 0.5) / 100]);
        if (topLeft && bottomRight) {
          const x1 = Math.floor(topLeft[0] / 4) * 4;
          const y1 = Math.floor(topLeft[1] / 4) * 4;
          const x2 = Math.floor(bottomRight[0] / 4) * 4;
          const y2 = Math.floor(bottomRight[1] / 4) * 4;
          const graphics =
            this[`level${level}Graphics` as "level1Graphics" | "level2Graphics" | "level3Graphics"];

          for (let x = x1; x < x2; x += 4) {
            for (let y = y1; y < y2; y += 4) {
              graphics.rect(x, y, 4, 4).fill(NEXRAD_COLOR_MAP[level]);
            }
          }
        }
      }
    }

    if (this.container && !this.container.children.includes(this.level1Graphics)) {
      this.container.addChild(this.level1Graphics);
    }
    if (this.container && !this.container.children.includes(this.level2Graphics)) {
      this.container.addChild(this.level2Graphics);
    }
    if (this.container && !this.container.children.includes(this.level3Graphics)) {
      this.container.addChild(this.level3Graphics);
    }
  }, 50);
}

export const nexradManager = new NexradManager();
