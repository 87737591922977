import React from "react";
import { useButtonContext } from "contexts/buttonContext";
import { colorNameMap } from "@poscon/shared-frontend";

export const Cross = ({ width: _width, height: _height }: { width?: number; height?: number }) => {
  const { buttonWidth, buttonHeight } = useButtonContext();
  const width = _width ?? buttonWidth;
  const height = _height ?? buttonHeight;

  return (
    <graphics
      eventMode="none"
      zIndex={10}
      draw={(graphics) => {
        graphics.clear();
        graphics.moveTo(0, 0)
          .lineTo(width, height)
          .moveTo(width, 0)
          .lineTo(0, height)
          .stroke({ width: 1, color: colorNameMap.white });
      }}
    />
  );
};
