import React from "react";
import { View } from "components/utils/View";
import {
  backgroundOption,
  borderOption,
  brightOption,
  fontOption,
  linesOption,
  toggleOption,
  viewOptionSelector,
} from "~redux/slices/viewOptionSlice";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { crrGroupsSelector } from "~redux/slices/eramStateSlice";
import type { Coordinate, CrrGroup, TrackId } from "@poscon/shared-types";
import { stringToParsedTokenArray, stringToTokenArray } from "@poscon/shared-types/eram";
import type { ViewItemOption, EramFontSize } from "@poscon/shared-frontend";
import {
  dispatchInsertCommandEvent,
  getBitmapTextStyles,
  useViewOptionSelected,
  colorNameMap,
  computeColor,
  createItemOption,
  eramFontDimensionMap,
  eramFontNameMap,
  ViewItem,
  closeSpecificViewOption,
} from "@poscon/shared-frontend";
import { ViewItemOptionContainer } from "components/utils/ViewMenu";
import * as turf from "@turf/turf";
import { eramHubConnection } from "~/eramHubConnection";
import { useViewOptions, ViewOptionContextProvider } from "contexts/viewOptionContext";
import { drawColorMap } from "~/utils/drawColorMap";
import { TBE, TBP } from "~/eramConstants";
import { useBrightContext } from "contexts/brightnessContext";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import { flightplanSelector, trackSelector } from "~redux/slices/aircraftSlice";

const view = "CRR";

const optionMap = {
  background: backgroundOption(view),
  border: borderOption(view),
  lines: linesOption(view, 5, 40),
  font: fontOption(view),
  bright: brightOption(view),
  list: toggleOption(view, "list", "LIST", 4),
};

type AircraftRowProps = {
  groupName: string;
  trackId: TrackId;
  groupCoord: Coordinate;
};

const AircraftRow = ({ trackId, groupCoord, groupName }: AircraftRowProps) => {
  const dispatch = useRootDispatch();
  const viewOptions = useViewOptions(view);

  const viewOptionPath = `${view}/aircraft/${trackId}`;
  const { selected, toggleViewOption } = useViewOptionSelected(viewOptionPath);

  const track = useRootSelector((state) => trackSelector(state, trackId));
  const fp = useRootSelector((state) => flightplanSelector(state, track?.fpId ?? null));
  const callsign = fp?.callsign ?? "N/A";
  const itemOptions = [
    createItemOption(`DELETE ${callsign}`, () => {
      dispatch(processEramMessage(stringToParsedTokenArray(`LF ${groupName} ${callsign}`)));
    }),
  ];

  const fontSize = viewOptions.font as EramFontSize;
  const fontFamily = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontFamily];

  const width = 18 * fontDimension.width + 4;
  const height = fontDimension.height + 2;

  const alpha = (viewOptions.bright * 0.8) / 100 + 0.2;

  const tint = selected ? 0 : computeColor(colorNameMap.white, viewOptions.bright / 100);
  const fillColor = computeColor(selected ? colorNameMap.grey : colorNameMap.black, selected ? alpha : 1);
  const distance = track
    ? parseFloat(turf.distance(track.position, groupCoord, { units: "nauticalmiles" }).toFixed(1))
    : null;

  const distanceText = !distance ? "---" : distance >= 1000 ? ">999.9" : distance.toString();

  return (
    <ViewItem
      width={width}
      height={height}
      fill={selected}
      fillColor={fillColor}
      onmousedown={toggleViewOption}
    >
      <bitmapText
        zIndex={3}
        text={callsign}
        eventMode="none"
        style={{ ...getBitmapTextStyles(fontFamily), fill: tint }}
        x={6}
        y={1}
      />
      <bitmapText
        zIndex={3}
        text={distanceText}
        eventMode="none"
        style={{ ...getBitmapTextStyles(fontFamily), fill: tint }}
        anchor={{ x: 1, y: 0 }}
        x={width - 6}
        y={1}
      />
      {selected && <ViewItemOptionContainer xOffset={width} options={itemOptions} />}
    </ViewItem>
  );
};

const GroupContainer = ({ name, coordinate, aircraft, color }: CrrGroup) => {
  const viewOptionPath = `${view}/group/${name}`;
  const { selected, toggleViewOption, closeViewOption } = useViewOptionSelected(viewOptionPath);
  const viewOptions = useViewOptions(view);

  const deleteGroup = () => {
    void eramHubConnection.removeCrrGroup(name);
  };

  const deleteAllAircraft = () => {
    void eramHubConnection.removeAllCrrAircraftInGroup(name);
    closeViewOption();
  };

  const itemOptions: ViewItemOption[] = [
    {
      text: aircraft.length > 0 ? `DELETE ALL ${name}` : `DELETE ${name}`,
      onmousedown: aircraft.length > 0 ? deleteAllAircraft : deleteGroup,
    },
  ];

  const fontSize = viewOptions.font as EramFontSize;
  const fontName = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontName];

  const width = name.length * fontDimension.width + 4;

  const alpha = (viewOptions.bright * 0.8) / 100 + 0.2;

  const _color = drawColorMap[color ?? "grey"];

  const tint = selected ? 0 : _color;
  const fillColor = selected ? _color : computeColor(colorNameMap.black, selected ? alpha : 1);

  return (
    <container sortableChildren>
      <ViewItem
        fill={selected}
        x={Math.floor(fontDimension.width * (10 - name.length / 2) - 2)}
        width={width}
        text={name}
        tint={tint}
        fillColor={fillColor}
        fontSize={fontSize}
        onmousedown={(event) => {
          if (event.button === TBP) {
            dispatchInsertCommandEvent(stringToTokenArray(`LF ${name}`));
          }
          if (event.button === TBE) {
            toggleViewOption();
          }
        }}
      >
        {selected && <ViewItemOptionContainer xOffset={width} options={itemOptions} />}
      </ViewItem>
      <container x={fontDimension.width - 4} zIndex={0}>
        {aircraft.map((id, index) => (
          <container key={id} y={(fontDimension.height + 4) * (index + 1)} sortableChildren>
            <AircraftRow trackId={id} groupCoord={coordinate} groupName={name} />
          </container>
        ))}
      </container>
    </container>
  );
};

type CrrButtonProps = {
  group: CrrGroup;
  width: number;
  x: number;
  y: number;
};
const CrrButton = ({ group, width, x, y }: CrrButtonProps) => {
  const dispatch = useRootDispatch();
  const viewOptions = useViewOptions(view);
  const { borderTint } = useBrightContext();

  const viewOptionPath = `${view}/groupButton/${group.name}`;
  const { selected, toggleViewOption } = useViewOptionSelected(viewOptionPath);

  const fontSize = viewOptions.font as EramFontSize;

  const name = group.name;
  const deleteGroup = () => {
    void eramHubConnection.removeCrrGroup(name);
  };

  const deleteAllAircraft = () => {
    void eramHubConnection.removeAllCrrAircraftInGroup(name);
    dispatch(closeSpecificViewOption(viewOptionPath));
  };

  const itemOptions: ViewItemOption[] = [
    {
      text: group.aircraft.length > 0 ? `DELETE ALL ${group.name}` : `DELETE ${group.name}`,
      onmousedown: group.aircraft.length > 0 ? deleteAllAircraft : deleteGroup,
    },
  ];

  return (
    <container x={x} y={y} zIndex={selected ? 1 : 0}>
      <ViewItem
        key={group.name}
        height={eramFontDimensionMap[eramFontNameMap[fontSize]].height * 2 - 6}
        text={group.name}
        borderColor={borderTint}
        tint={selected ? 0 : drawColorMap[group.color]}
        fillColor={selected ? drawColorMap[group.color] : 0}
        width={width}
        fontSize={fontSize}
        onmousedown={(event) => {
          if (event.button === TBP) {
            dispatchInsertCommandEvent(stringToTokenArray(`LF ${name}`));
          }
          if (event.button === TBE) {
            toggleViewOption();
          }
        }}
      />
      {selected && <ViewItemOptionContainer xOffset={width} options={itemOptions} />}
    </container>
  );
};

type ListViewProps = {
  groups: CrrGroup[];
  width: number;
};
const CrrButtons = ({ groups, width }: ListViewProps) => {
  const viewOptions = useViewOptions(view);
  const colWidth = Math.floor(width / 3) - 5;

  const fontSize = viewOptions.font as EramFontSize;

  return (
    <container x={2} y={2} sortableChildren>
      {groups.map((group, index) => {
        const x = Math.floor((index % 3) * (colWidth + 4));
        const y = Math.floor(index / 3) * (eramFontDimensionMap[eramFontNameMap[fontSize]].height * 2 - 2);
        return <CrrButton key={group.name} group={group} width={colWidth} x={x} y={y} />;
      })}
    </container>
  );
};

export const ContRangeReadout = () => {
  const groups = useRootSelector(crrGroupsSelector);
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));

  const fontDimension = eramFontDimensionMap[eramFontNameMap[viewOptions.font as EramFontSize]];
  const heightList = groups.map((group) => (group.aircraft.length + 1) * (fontDimension.height + 8) + 8);
  const yList = heightList.map((height, index) => heightList.slice(0, index).reduce((acc, h) => acc + h, 0));

  const height = viewOptions.list
    ? heightList.reduce((acc, l) => acc + l, 0)
    : Math.ceil(groups.length / 3) * (fontDimension.height * 2 - 1) + 3;

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View width={20} extraWidthPx={1} height={height} view={view} optionMap={optionMap}>
        <container x={2} y={2} sortableChildren>
          {viewOptions.list ? (
            groups.map((group, index) => {
              const y = yList[index];
              return (
                <container key={group.name} x={0} y={y}>
                  <GroupContainer {...group} />
                </container>
              );
            })
          ) : (
            <CrrButtons groups={groups} width={20 * fontDimension.width} />
          )}
        </container>
      </View>
    </ViewOptionContextProvider>
  );
};
