/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  eramStateSlice,
  removeCrrGroup,
  setAltLimits,
  setCrrGroup,
  setCrrGroups,
  setEramAltimeterList,
  setEramState,
  setFdbLdr,
} from "~redux/slices/eramStateSlice";
import type { RootState, RootStore } from "~redux/store";
import {
  altimeterAirportsSelector,
  metarAirportsSelector,
  baseEramHubConnection,
  deleteQuicklookTracks,
  geomapStore,
  isRestrictedUiAction,
  messagingSlice,
  replayManager,
  setMetarList,
  setQuicklookTracks,
  uiSubscribedSelector,
  viewMenuFieldsSlice,
  viewOptionSliceName,
} from "@poscon/shared-frontend";
import type { EramHubConnection } from "@poscon/shared-frontend";
import { applyPrefsetThunk } from "~redux/thunks/applyPrefsetThunk";
import type { EramPrefset } from "~/EramPrefset";
import { createPrefset } from "~/EramPrefset";
import { env } from "~/env";
import {
  EramPrefset as BEramPrefset,
  InflatedEramClientEventMap,
  ArtccId,
  isCoordinate,
} from "@poscon/shared-types";
import type { DefaultEventsMap } from "@socket.io/component-emitter";
import { startListening } from "~redux/listenerMiddleware";
import { setViewOptionState } from "~redux/slices/viewOptionSlice";
import { setSettingsState, settingsSlice } from "~redux/slices/settingsSlice";
import { situationDisplayStore } from "./situationDisplayStore";
import {
  eramTempStateSlice,
  geomapConfigSelector,
  removeRouteLine,
  setBCG,
  setBeaconCodeList,
  setCfrList,
  setEramConfig,
  setGeomapConfig,
  setKSDConfig,
  setLsConfig,
  setMapFilterMenu,
  setMapScale,
  setPrefsets,
  setRangeCenter,
  setRangeCenterOverride,
  setRouteLine,
  setRouteLines,
  setRPosConfig,
  setStatus,
} from "~redux/slices/eramTempStateSlice";
import {
  resetRangeAction,
  setMapScaleToRange,
  setRangeAction,
  setRangeCenterOverrideToLonLat,
  setRangeCenterOverrideToLonLatAction,
} from "~redux/thunks/mapThunks";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import { isAction } from "@reduxjs/toolkit";
import { Geomap, GeomapSaaFeature } from "@poscon/shared-types/eram";
import { ControllerSectorId, PosconRole } from "@poscon/shared-types/poscon";

const syncedUiSlices = [eramStateSlice.name, viewOptionSliceName, settingsSlice.name] as const;
type SyncedUiSlice = (typeof syncedUiSlices)[number];

let store: RootStore;
export const ERAM_SERVER_URL = env.VITE_ERAM_SERVER_URL;
export const eramHubConnection = baseEramHubConnection as EramHubConnection<
  InflatedEramClientEventMap & DefaultEventsMap
>;
eramHubConnection.url = ERAM_SERVER_URL;

const excludeButtonPathElements: string[] = ["ALT_LIM", "PREFSET"];

export const injectStore = async (s: RootStore) => {
  store = s;
  eramHubConnection.dispatch = store.dispatch;
  eramHubConnection.getState = () => {
    return store.getState();
  };

  eramHubConnection.getPrefsetState = () => {
    const state = store.getState();
    const prefset = Object.fromEntries(
      syncedUiSlices.map((slice) => [slice, state[slice]]),
    ) as unknown as BEramPrefset;
    prefset.eram = {
      ...prefset.eram,
      selectedMenuButtonPaths: prefset.eram.selectedMenuButtonPaths.filter(
        (p) => !excludeButtonPathElements.some((e) => p.includes(e)),
      ),
      crrGroups: prefset.eram.crrGroups.map((g) => ({
        ...g,
        aircraft: [],
      })),
    };
    prefset.center = situationDisplayStore.lonLatCenter;
    prefset.range = situationDisplayStore.range;
    prefset.metarList = metarAirportsSelector(state);
    prefset.altimList = altimeterAirportsSelector(state);
    return prefset;
  };

  eramHubConnection.registerEventHandler("removeQuicklookTracks", (trackIds) => {
    store.dispatch(deleteQuicklookTracks(trackIds));
  });
  eramHubConnection.registerEventHandler("updateQuicklookTracks", (quicklookTracks) => {
    store.dispatch(setQuicklookTracks(quicklookTracks));
  });
  eramHubConnection.registerEventHandler("receiveEramUiState", (_state) => {
    const prefset = _state as BEramPrefset & Pick<RootState, SyncedUiSlice>;
    store.dispatch(setEramState(prefset.eram));
    if (prefset.center) {
      store.dispatch(setRangeCenterOverrideToLonLat(prefset.center));
    }
    if (prefset.range) {
      store.dispatch(setMapScaleToRange(prefset.range));
    }
    store.dispatch(setSettingsState(prefset.settings));
    store.dispatch(setViewOptionState(prefset.viewOptions));
  });
  eramHubConnection.registerEventHandler("receiveAltLimits", (altLimits) => {
    store.dispatch(setAltLimits(altLimits));
  });
  eramHubConnection.registerEventHandler("receiveAltimeterList", (altimeterList) => {
    store.dispatch(setEramAltimeterList(altimeterList));
  });
  eramHubConnection.registerEventHandler("receiveMetarList", (metarList) => {
    store.dispatch(setMetarList(metarList));
  });
  eramHubConnection.registerEventHandler("receiveCrrGroups", (crrGroups) => {
    store.dispatch(setCrrGroups(crrGroups));
  });
  eramHubConnection.registerEventHandler("receiveCrrGroup", (crrGroup) => {
    store.dispatch(setCrrGroup(crrGroup));
  });
  eramHubConnection.registerEventHandler("removeCrrGroup", (crrGroup) => {
    store.dispatch(removeCrrGroup(crrGroup));
  });
  eramHubConnection.registerEventHandler("receiveCfrList", (cfrList) => {
    store.dispatch(setCfrList(cfrList));
  });
  eramHubConnection.registerEventHandler("receiveBeaconCodeList", (beaconCodeList) => {
    store.dispatch(setBeaconCodeList(beaconCodeList));
  });
  eramHubConnection.registerEventHandler("receiveRouteLine", (id, line) => {
    store.dispatch(setRouteLine({ id, line }));
  });
  eramHubConnection.registerEventHandler("receiveRouteLines", (lines) => {
    store.dispatch(setRouteLines(lines));
  });
  eramHubConnection.registerEventHandler("removeRouteLine", (id) => {
    store.dispatch(removeRouteLine(id));
  });
  eramHubConnection.registerEventHandler("receiveFdbLdr", (length) => {
    store.dispatch(setFdbLdr(length));
  });
  eramHubConnection.registerEventHandler("receiveGeomapConfig", (config) => {
    store.dispatch(setGeomapConfig(config));
    geomapStore.activeGeomapConfig = config;
  });
  eramHubConnection.registerEventHandler("setRangeCenter", (rangeCenter) => {
    store.dispatch(setRangeCenter(rangeCenter));
  });
  eramHubConnection.registerEventHandler("receiveLsConfig", (config) => {
    store.dispatch(setLsConfig(config));
  });
  eramHubConnection.registerEventHandler("receivePrefsets", (prefsets) => {
    store.dispatch(setPrefsets(prefsets));
  });
  eramHubConnection.registerEventHandler("receiveEramUiAction", (action) => {
    if (isAction(action)) {
      store.dispatch({ ...action, meta: { forwarded: true } });
    }
  });
  eramHubConnection.registerEventHandler("receiveEramOutage", (status) => {
    store.dispatch(setStatus(status));
  });
  eramHubConnection.registerEventHandler("requestPrefsetState", async (callback) => {
    callback(await createPrefset(store.getState()));
  });
  eramHubConnection.registerEventHandler("applyPrefset", (prefset) => {
    // TODO: validation
    store.dispatch(applyPrefsetThunk(prefset as EramPrefset));
  });
};

// TODO: put into env file
const cdnUrl = "https://cdn.poscon.com";

// TODO: save geomaps on disk
export async function initializeConnection(
  artccId: ArtccId,
  sectorId: ControllerSectorId | null = null,
  role: PosconRole = "Radar",
) {
  await eramHubConnection.initialize(artccId, "ERAM", ERAM_SERVER_URL, sectorId, role);
  try {
    geomapStore.geomapConfigs = await eramHubConnection.getGeomapConfigs(artccId);
    const geomapIds = [...new Set(geomapStore.geomapConfigs.flatMap((c) => c.geomapIds))];
    const saaGeomapIds = [...new Set(geomapStore.geomapConfigs.flatMap((c) => c.saaGeomapIds))];
    const geomaps = (
      await Promise.allSettled(
        geomapIds.map(async (id) => {
          const res = await fetch(`${cdnUrl}/videomaps/eram/${artccId}/${id}.geojson`);
          const v = await res.json();
          return v;
        }),
      )
    )
      .filter((v): v is PromiseFulfilledResult<Geomap> => v.status === "fulfilled")
      .map(({ value }) => value);
    const saaMaps = (
      await Promise.allSettled(
        saaGeomapIds.map(async (id) => {
          const res = await fetch(`${cdnUrl}/videomaps/eram/${artccId}/${id}.geojson`);
          const v = await res.json();
          return v;
        }),
      )
    )
      .filter((v): v is PromiseFulfilledResult<Geomap<GeomapSaaFeature>> => v.status === "fulfilled")
      .map(({ value }) => value);
    geomapStore.saaGeomaps = saaMaps;
    geomapStore.geomaps = geomaps;
    geomapStore.mapFilterMenus = await eramHubConnection.getFilterMenu(artccId);
    geomapStore.bcgs = await eramHubConnection.getBCGs(artccId);
  } catch (e) {
    console.error("failed to load geomaps: ", e);
  }
  eramHubConnection.getKSDConfig().then((ksdConfig) => {
    store.dispatch(setKSDConfig(ksdConfig));
  });
  eramHubConnection.getRPosConfig().then((rPosConfig) => {
    store.dispatch(setRPosConfig(rPosConfig));
  });
  eramHubConnection.getEramConfig().then((eramConfig) => {
    store.dispatch(setEramConfig(eramConfig));
  });
}

export const uiActionReducerNames: string[] = [
  viewOptionSliceName,
  eramStateSlice.name,
  eramTempStateSlice.name,
  messagingSlice.name,
  viewMenuFieldsSlice.name,
];

startListening({
  predicate: (action, state) =>
    uiSubscribedSelector(state as RootState) &&
    isRestrictedUiAction(
      action,
      uiActionReducerNames,
      [setRangeAction.type, resetRangeAction.type, setRangeCenterOverrideToLonLatAction.type],
      [setMapScale.type, setRangeCenterOverride.type],
    ),
  effect: (action) => {
    eramHubConnection.emit("uiAction", action);
  },
});

startListening({
  predicate: (action) => setRangeAction.match(action) && (action as any).meta?.forwarded,
  effect: (action, { dispatch }) => {
    if (typeof action.payload === "number") {
      dispatch(setMapScaleToRange(action.payload));
    }
  },
});

startListening({
  predicate: (action) =>
    setRangeCenterOverrideToLonLatAction.match(action) && (action as any).meta?.forwarded,
  effect: (action, { dispatch }) => {
    if (isCoordinate(action.payload)) {
      dispatch(setRangeCenterOverrideToLonLat(action.payload));
    }
  },
});

startListening({
  predicate: () => replayManager.recording,
  effect: (action) => {
    void replayManager.recordReplayAction("D", action);
  },
});

startListening({
  predicate: (action, state, prevState) =>
    geomapConfigSelector(state as RootState) !== geomapConfigSelector(prevState as RootState),
  effect: (action, { getState, dispatch }) => {
    geomapStore.activeGeomapConfig = geomapConfigSelector(getState());
    dispatch(setMapFilterMenu(geomapStore.activeMapFitlerMenu));
    dispatch(setBCG(geomapStore.activeBCG));
  },
});

if (window.__TAURI__) {
  const webviewWindow = WebviewWindow.getCurrent();
  await webviewWindow.listen("poscon:close", async () => {
    if (eramHubConnection.isActive) {
      try {
        await eramHubConnection.signout();
      } catch (err) {
        console.error(err);
      }
    }
    void webviewWindow.close();
  });
}
