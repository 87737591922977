import type { DrawMode } from "contexts/drawContext";
import { useDrawContext } from "contexts/drawContext";
import type { DrawButtonId } from "types/eramButton";
import React, { useRef, useState } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import {
  addDrawItem,
  drawCircleRadiusSelector,
  drawColorBrightSelector,
  drawColorSelector,
  removeAllDrawItems,
  setDrawColor,
} from "~redux/slices/eramStateSlice";
import type { EramButtonProps } from "components/buttons/EramButton";
import { EramBaseButton } from "components/buttons/EramButton";
import { Cross } from "components/utils/Cross";
import { EramCounterButton } from "components/buttons/EramCounterButton";
import type { FederatedPointerEvent, Container as PixiContainer } from "pixi.js";
import { baseToggleButtonSelectedColor } from "components/buttons/EramToggleButton";
import { useButtonContext } from "contexts/buttonContext";
import {
  colorNameMap,
  computeColor,
  EramInput,
  eramTextDimensionMap,
  MenuElement,
  useHitArea,
  useInputProps,
} from "@poscon/shared-frontend";
import { ColorPalette } from "components/utils/ColorPalette";
import { getDrawColor } from "~/utils/drawColorMap";
import type { ApiFix, ApiFrd, Coordinate } from "@poscon/shared-types";
import { ERAM_SERVER_URL } from "~/eramHubConnection";
import { distance } from "@turf/turf";
import { getFixCoordinates, segmentIsFix } from "@poscon/shared-types";
import { useBrightContext } from "contexts/brightnessContext";
import { Rectangle } from "pixi.js";
import { rangeCenterSelector } from "~redux/slices/eramTempStateSlice";

const { width: fontWidth, height: fontHeight } = eramTextDimensionMap[2];
const circleInputWidth = fontWidth * 12 + 4;
const xX = circleInputWidth - fontHeight;

async function getFixCoord(ident: string, refPoint: Coordinate) {
  const fixes = (await fetch(`${ERAM_SERVER_URL}/navdata/ident/${ident}`).then((res) => res.json())) as (
    | ApiFix
    | ApiFrd
  )[];
  const fix = fixes.sort(
    (a, b) => distance(refPoint, getFixCoordinates(a)) - distance(refPoint, getFixCoordinates(b)),
  )[0];
  return fix ? getFixCoordinates(fix) : null;
}

const DrawCircleInput = ({ x, y }: Pick<PixiContainer, "x" | "y">) => {
  const rangeCenter = useRootSelector(rangeCenterSelector);
  const dispatch = useRootDispatch();
  const circleRadius = useRootSelector(drawCircleRadiusSelector);
  const { borderTint, tint, textBright, fillColor } = useBrightContext();
  const drawColor = useRootSelector(drawColorSelector);
  const drawColorBright = useRootSelector(drawColorBrightSelector);
  const { setDrawMode } = useDrawContext();
  const [showInvalid, setShowInvalid] = useState(false);

  const { value, setInputValue, showInput, setShowInput, ...inputProps } = useInputProps(
    `draw/circle/fix`,
    "",
    async (v) => {
      const id = window.isSecureContext
        ? crypto.randomUUID().replace(/-/g, "")
        : new Date().toJSON().replaceAll(/[-:.]/g, "");
      if (!segmentIsFix(v)) {
        setShowInvalid(true);
        return;
      }
      const pos = await getFixCoord(v, [rangeCenter.centerLongitude, rangeCenter.centerLatitude]);
      if (!pos) {
        setShowInvalid(true);
        return;
      }
      dispatch(
        addDrawItem({
          id,
          anchor: "MAP",
          origin: pos,
          element: {
            itemType: "CIRCLE",
            radius: circleRadius,
            color: drawColor,
            bright: drawColorBright,
          },
        }),
      );
      setDrawMode("OPEN");
    },
    {
      resetOnMouseDown: true,
      maxLength: 11,
      initialShowInput: false,
    },
  );

  const hitAreaRef = useHitArea(0, 0, circleInputWidth, (fontHeight + 1) * (showInvalid ? 3 : 2));

  return showInput ? (
    <container x={x} y={y} zIndex={200} sortableChildren hitArea={hitAreaRef.current}>
      <MenuElement width={xX} text="CIRCLE" tint={tint} fillColor={fillColor} borderColor={borderTint} />
      <MenuElement
        x={xX}
        width={fontHeight}
        text="X"
        onmousedown={() => {
          setInputValue("");
          setShowInput(false);
        }}
        tint={tint}
        fillColor={fillColor}
        borderColor={borderTint}
      />
      <MenuElement y={fontHeight + 1} width={circleInputWidth} fillColor={0}>
        <EramInput value={value} {...inputProps} width={circleInputWidth - 4} x={2} y={1} tint={tint} />
      </MenuElement>
      {showInvalid && (
        <MenuElement
          y={(fontHeight + 1) * 2}
          width={circleInputWidth}
          text="INVALID"
          tint={computeColor(colorNameMap.yellow, textBright)}
          fillColor={0}
        />
      )}
    </container>
  ) : null;
};

export const EramDrawAnchorButton = (props: EramButtonProps<"DRAW_ANCHOR_MAP">) => {
  const { anchor, toggleAnchor } = useDrawContext();
  return (
    <EramBaseButton
      {...props}
      overrideText={anchor}
      onmousedown={toggleAnchor}
      baseBgColor={baseToggleButtonSelectedColor}
    />
  );
};

const drawButtonModeMap: Partial<Record<DrawMode, DrawButtonId>> = {
  CIRCLE: "DRAW_CIRCLE",
  RECT: "DRAW_RECT",
  LINE: "DRAW_LINE",
  TEXT: "DRAW_TEXT",
  DELETE: "DRAW_DEL",
  DELETE_ALL: "DRAW_DEL_ALL",
};

const DrawColorPaletteContent = () => {
  const dispatch = useRootDispatch();
  const drawColor = useRootSelector(drawColorSelector);
  const { buttonWidth, buttonHeight } = useButtonContext();

  const width = Math.floor(buttonWidth / 2) - 4;
  const height = Math.floor(buttonHeight / 2) - 4;

  return (
    <ColorPalette
      width={width}
      height={height}
      spacing={2}
      selectedColor={drawColor}
      setSelectedColor={(color) => dispatch(setDrawColor(color))}
    />
  );
};
export const emptyTextButtons: DrawButtonId[] = [
  "DRAW_RECT",
  "DRAW_CIRCLE",
  "DRAW_LINE",
  "DRAW_COLOR_PALETTE",
];

export const EramDrawButton = (props: EramButtonProps<DrawButtonId>) => {
  const dispatch = useRootDispatch();
  const ref = useRef<PixiContainer>(null);

  const { drawMode, setDrawMode, onItemClick } = useDrawContext();
  const { buttonWidth, buttonHeight } = useButtonContext();

  const selected = drawButtonModeMap[drawMode] === props.buttonId;

  const onmousedown = (event: FederatedPointerEvent) => {
    props.onmousedown?.(event);
    switch (props.buttonId) {
      case "DRAW_CIRCLE":
        onItemClick("CIRCLE");
        break;
      case "DRAW_RECT":
        onItemClick("RECT");
        break;
      case "DRAW_LINE":
        onItemClick("LINE");
        break;
      case "DRAW_TEXT":
        onItemClick("TEXT");
        break;
      case "DRAW_DEL":
        setDrawMode((prev) => (prev === "DELETE" ? "OPEN" : "DELETE") as DrawMode);
        break;
      case "DRAW_DEL_ALL":
        if (drawMode !== "DELETE_ALL") {
          setDrawMode("DELETE_ALL");
        } else {
          dispatch(removeAllDrawItems());
          setDrawMode("OPEN");
        }
        break;
    }
  };

  return (
    <>
      <EramBaseButton
        {...props}
        containerRef={ref}
        overrideText={emptyTextButtons.includes(props.buttonId) ? null : props.overrideText}
        onmousedown={onmousedown}
        baseBgColor={selected ? colorNameMap.commandActiveBrown : colorNameMap.cyan}
      >
        <graphics
          zIndex={2}
          label={`${props.buttonId}_CONTENT`}
          draw={(graphics) => {
            graphics.clear();
            graphics.setStrokeStyle({ width: 1, color: 0xffffff });
            switch (props.buttonId) {
              case "DRAW_CIRCLE":
                graphics.circle(buttonWidth / 2, buttonHeight / 2, buttonHeight / 2 - 8).stroke();
                break;
              case "DRAW_RECT":
                graphics.rect(6, 6, buttonWidth - 12, buttonHeight - 12).stroke();
                break;
              case "DRAW_LINE":
                graphics
                  .moveTo(6, 6)
                  .lineTo(buttonWidth - 6, buttonHeight - 6)
                  .stroke();
                break;
            }
          }}
        />
        {props.buttonId === "DRAW_COLOR_PALETTE" && <DrawColorPaletteContent />}
      </EramBaseButton>
      {props.buttonId === "DRAW_CIRCLE" && selected && (
        <DrawCircleInput x={buttonWidth + 2} y={buttonHeight + 2} />
      )}
    </>
  );
};

export const EramDrawCounterColor = (props: EramButtonProps<"DRAW_COLOR">) => {
  const drawColor = useRootSelector(drawColorSelector);
  const drawColorBright = useRootSelector(drawColorBrightSelector);
  const color = getDrawColor(drawColor, drawColorBright);

  return (
    <EramCounterButton {...props} textColorOverride={props.buttonId === "DRAW_COLOR" ? color : undefined} />
  );
};

export const EramDrawCircleSizeButton = (props: EramButtonProps<"DRAW_CIRCLE_SIZE">) => {
  const { anchor } = useDrawContext();

  return (
    <EramCounterButton {...props} disabled={anchor === "DISP"}>
      {anchor === "DISP" && <Cross />}
    </EramCounterButton>
  );
};
