import React, { useLayoutEffect } from "react";
import { useRootSelector } from "~redux/hooks";
import { LongitudinalScale } from "components/map/LongitudinalScale";
import { EramDrawItems } from "components/utils/EramDrawItem";
import { CrrGroupLabels } from "components/map/crrGroupLabels";
import { useDrawContext } from "contexts/drawContext";
import { useSituationDisplay } from "contexts/sdContext";
import { useGeomapConfig, usePixiMouseEventListener } from "@poscon/shared-frontend";
import { Geomaps } from "components/map/Geomaps";
import { RouteLines } from "components/map/RouteLines";
import type { Container as PixiContainer, Sprite as PixiSprite } from "pixi.js";
import { layerZIndexMap } from "~/layerZIndexMap";
import { dispatchMapClickEvent } from "~/customEvents";
import { trackManager } from "~/trackManager";
import { InteractiveContainer } from "components/utils/InteractiveContainer";
import { nexradManager } from "~/nexradManager";
import { SaaMap } from "components/map/SaaMap";
import {
  delTearOffCommandActiveSelector,
  lsConfigSelector,
  mapScaleSelector,
  rangeCenterOverrideSelector,
} from "~/redux/slices/eramTempStateSlice";

type MapProps = {
  maskRef: React.RefObject<PixiSprite | null>;
};
export const Map = ({ maskRef }: MapProps) => {
  const tracksContainerRef = React.useRef<PixiContainer>(null);
  const nexradContainerRef = React.useRef<PixiContainer>(null);
  const geomapConfig = useGeomapConfig();
  const { sdRef, getLonLatFromSdCoord, getSdCoordinates } = useSituationDisplay();
  const delTearOffCommandActive = useRootSelector(delTearOffCommandActiveSelector);
  const mapScale = useRootSelector(mapScaleSelector);
  const rangeCenterOverride = useRootSelector(rangeCenterOverrideSelector);
  const showCrrFixes = useRootSelector((state) => state.eram.toggleButtonState.CRR_FIX);
  const lsConfig = useRootSelector(lsConfigSelector);
  const { drawMode } = useDrawContext();

  useLayoutEffect(() => {
    if (tracksContainerRef.current) {
      trackManager.tracksContainer = tracksContainerRef.current;
    }
    if (nexradContainerRef.current) {
      nexradManager.container = nexradContainerRef.current;
    }
  }, []);

  usePixiMouseEventListener((event) => {
    if (
      !delTearOffCommandActive &&
      drawMode === "CLOSED" &&
      (event.target as any)?.label === "SITUATION_DISPLAY"
    ) {
      const sdCoordinate = getSdCoordinates([event.pageX, event.pageY]);
      const geoCoordinate = getLonLatFromSdCoord(sdCoordinate);
      dispatchMapClickEvent({
        button: event.button,
        sdCoordinate,
        geoCoordinate,
      });
    }
  }, sdRef);

  return (
    <>
      <container mask={maskRef.current} zIndex={layerZIndexMap.map}>
        <container
          isRenderGroup
          sortableChildren
          scale={mapScale}
          x={rangeCenterOverride[0]}
          y={rangeCenterOverride[1]}
        >
          <container label="NEXRAD" eventMode="none" sortableChildren zIndex={1} ref={nexradContainerRef} />
          {geomapConfig && (
            <>
              <Geomaps
                key={`MAP${geomapConfig.artccId}${geomapConfig.geomapId}`}
                geomapConfig={geomapConfig}
              />
              <SaaMap
                key={`SAA${geomapConfig.artccId}${geomapConfig.geomapId}`}
                geomapId={geomapConfig.geomapId}
              />
            </>
          )}
          {lsConfig && <LongitudinalScale config={lsConfig} />}
        </container>
        {showCrrFixes && <CrrGroupLabels />}
        <RouteLines />
        <EramDrawItems />
      </container>
      <InteractiveContainer
        ref={tracksContainerRef}
        mask={maskRef.current}
        zIndex={layerZIndexMap.tracks}
        label="TRACKS"
        sortableChildren
        isRenderGroup
      />
    </>
  );
};
