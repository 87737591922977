import React from "react";
import { computeColor, GeomapContextProvider, useUiIsLocked } from "@poscon/shared-frontend";
import { useRootSelector } from "~redux/hooks";
import { useSituationDisplay } from "contexts/sdContext";
import type { Graphics, Sprite as PixiSprite } from "pixi.js";
import { Texture } from "pixi.js";
import { Map } from "components/map/Map";
import { ToolbarContainer } from "components/Toolbar";
import { layerZIndexMap } from "~/layerZIndexMap";

const baseBgColor = new Uint8Array([0x00, 0x00, 0xad]);
const baseAbColor = new Uint8Array([0xd0, 0xd0, 0xd0]);

export const SituationDisplay = ({ ref }: { ref: React.RefObject<Graphics | null> }) => {
  const { rect } = useSituationDisplay();
  const maskRef = React.useRef<PixiSprite>(null);
  const _abWidth = useRootSelector((state) => state.eram.abWidth);
  const abBright = useRootSelector((state) => state.eram.brightness.AB_BRDR_BRIGHT);
  const backgroundBrightness = useRootSelector((state) => state.eram.brightness.BCKGRD_BRIGHT);
  const uiIsLocked = useUiIsLocked();

  const abWidth = !uiIsLocked ? _abWidth : Math.max(_abWidth, 2);
  const abTint = computeColor(baseAbColor, abBright / 100);

  return (
    <>
      <container
        label="SITUATION_DISPLAY_CONTAINER"
        zIndex={layerZIndexMap.situationDisplay}
        mask={maskRef.current}
        x={rect.x}
        y={0}
      >
        <sprite
          x={-1}
          label="SD_MASK"
          texture={Texture.WHITE}
          width={rect.width + 2}
          height={rect.height}
          ref={maskRef}
          eventMode="none"
        />
        <graphics
          label="SITUATION_DISPLAY"
          ref={ref}
          eventMode="static"
          draw={(graphics) => {
            graphics.clear();
            const color = computeColor(baseBgColor, backgroundBrightness / 100, 0);
            graphics.rect(0, 0, rect.width, rect.height).fill(color);
          }}
        />
      </container>
      <ToolbarContainer maskRef={maskRef} x={rect.x} />
      <GeomapContextProvider>
        <Map maskRef={maskRef} />
      </GeomapContextProvider>
      <graphics
        x={rect.x}
        label="AB_BORDER"
        eventMode="none"
        draw={(graphics) => {
          graphics.clear();
          graphics.rect(-abWidth, 0, abWidth, rect.height).fill(abTint);
          graphics.rect(rect.width, 0, abWidth, rect.height).fill(abTint);
        }}
      />
    </>
  );
};
