import React from "react";
import type { EramFontSize } from "@poscon/shared-frontend";
import { eramTextDimensionMap } from "@poscon/shared-frontend";
import { useRootSelector } from "~redux/hooks";

export const buttonTextPaddingX = 3;
export const buttonTextPaddingY = 2;

export const ButtonContext = React.createContext<{
  buttonWidth: number;
  buttonHeight: number;
  buttonBright: number;
  fontWidth: number;
  fontHeight: number;
  fontSize: EramFontSize;
} | null>(null);

export const ButtonContextProvider = ({ children }: { children: React.ReactNode }) => {
  const buttonBright = useRootSelector((state) => state.eram.brightness.BUTTON_BRIGHT) / 100;
  const fontSize = useRootSelector((state) => state.eram.font.TOOLBAR_FONT) as EramFontSize;
  const { width: fontWidth, height: fontHeight } = eramTextDimensionMap[fontSize];
  const buttonWidth = Math.ceil(fontWidth * 8 + 4 + 2 * buttonTextPaddingX);
  const buttonHeight = Math.ceil(fontHeight * 2 + 2 * buttonTextPaddingY - 1);
  return (
    <ButtonContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ buttonWidth, buttonHeight, fontWidth, fontHeight, fontSize, buttonBright }}
    >
      {children}
    </ButtonContext.Provider>
  );
};

export const useButtonContext = () => {
  const contextValue = React.useContext(ButtonContext);
  if (contextValue === null) {
    throw new Error("useButtonContext must be used within a ButtonContextProvider");
  }
  return contextValue;
};
