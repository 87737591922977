import { invoke } from "@tauri-apps/api/core";
import { situationDisplayStore } from "~/situationDisplayStore";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";

export async function unclipCursor(): Promise<void> {
  return window.__TAURI__ ? WebviewWindow.getCurrent().setCursorGrab(false) : void 0;
}

export async function clipCursorToSituationDisplay(): Promise<void>;
/**
 * clip the cursor to the situation display
 * @param offsetWidth when tearing a button, clip the cursor so that the dragging outside would not go past the vertical SD border
 * @param offsetHeight when tearing a button, clip the cursor so that the dragging outside would not go past the horizontal SD border
 */
export async function clipCursorToSituationDisplay(offsetWidth: number, offsetHeight: number): Promise<void>;
export async function clipCursorToSituationDisplay(
  offsetWidth?: number,
  offsetHeight?: number,
): Promise<void> {
  if (window.__TAURI__) {
    const windowPosition = await WebviewWindow.getCurrent().innerPosition();
    const scaleFactor = await WebviewWindow.getCurrent().scaleFactor();
    return invoke("clip_cursor", {
      x: Math.round(windowPosition.x + scaleFactor * situationDisplayStore.rect.x),
      y: Math.round(windowPosition.y + scaleFactor * situationDisplayStore.rect.y),
      width: Math.round(scaleFactor * (situationDisplayStore.rect.width - (offsetWidth ?? 0))),
      height: Math.round(scaleFactor * (situationDisplayStore.rect.height - 1 - (offsetHeight ?? 0))),
    });
  }
}
