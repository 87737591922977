import React, { useCallback, useLayoutEffect, useState } from "react";
import { useSituationDisplay } from "contexts/sdContext";
import { colorNameMap, useStableCallback } from "@poscon/shared-frontend";
import { useApplication } from "@pixi/react";
import { draggingOutline } from "~/draggingOutline";

type DragContextValue = {
  anyDragging: boolean;
  setAnyDragging: React.Dispatch<React.SetStateAction<boolean>>;
  draggingHandler: (event: MouseEvent, inSD?: boolean) => void;
  getDraggingOutlinePositionAndDimension: () => {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  setDraggingOutlinePositionAndDimension: (x: number, y: number, width: number, height: number) => void;
  setDraggingOutlineVisible: (visible: boolean) => void;
};
export const DragContext = React.createContext<DragContextValue | null>(null);

type DragContextProviderProps = {
  children: React.ReactNode;
};
export const DragContextProvider = ({ children }: DragContextProviderProps) => {
  const { rect } = useSituationDisplay();
  const [anyDragging, setAnyDragging] = useState(false);

  const app = useApplication();

  useLayoutEffect(() => {
    if (!app.app.stage.children.includes(draggingOutline)) {
      app.app.stage.addChild(draggingOutline);
    }
  }, [app.app.stage]);

  const setDraggingOutlinePositionAndDimension = useCallback(
    (x: number, y: number, width: number, height: number) => {
      draggingOutline.x = x;
      draggingOutline.y = y;
      draggingOutline.clear();
      draggingOutline.rect(0, 0, width, height).stroke({ width: 1, color: colorNameMap.white });
    },
    [],
  );

  const setDraggingOutlineVisible = useCallback((visible: boolean) => {
    draggingOutline.visible = visible;
  }, []);

  const getDraggingOutlinePositionAndDimension = useCallback(() => {
    return {
      x: draggingOutline.x,
      y: draggingOutline.y,
      height: draggingOutline.height,
      width: draggingOutline.width,
    };
  }, []);

  const draggingHandler = useStableCallback((event: MouseEvent, inSD = false) => {
    event.stopPropagation();
    let x = event.pageX;
    let y = event.pageY;
    const { width, height } = getDraggingOutlinePositionAndDimension();
    if (inSD) {
      const { width: outlineWidth, height: outlineHeight } = getDraggingOutlinePositionAndDimension();
      x = Math.min(Math.max(x, rect.x), rect.x + rect.width - outlineWidth + 1);
      y = Math.min(Math.max(y, rect.y), rect.y + rect.height - (outlineHeight + 1));
    } else {
      x = Math.min(x, window.innerWidth - width + 1);
      y = Math.min(y, window.innerHeight - (height + 1));
    }
    draggingOutline.x = Math.max(1, x);
    draggingOutline.y = Math.max(0, y);
  });

  return (
    <DragContext.Provider
      value={{
        anyDragging,
        setAnyDragging,
        setDraggingOutlinePositionAndDimension,
        setDraggingOutlineVisible,
        getDraggingOutlinePositionAndDimension,
        draggingHandler,
      }}
    >
      {children}
    </DragContext.Provider>
  );
};

export const useDragContext = () => {
  const contextValue = React.useContext(DragContext);
  if (contextValue === null) {
    throw new Error("useDragContext must be used within a DragContextProvider");
  }
  return contextValue;
};

export const useAnyDragging = () => {
  const { anyDragging, setAnyDragging } = useDragContext();
  return { anyDragging, setAnyDragging };
};
