import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { useDragging } from "hooks/useDragging";
import { ViewMenu } from "components/utils/ViewMenu";
import { useBoolean, useEventListener, useWindowSize } from "usehooks-ts";
import { LogicalPosition } from "@tauri-apps/api/window";
import {
  deltaVectorLength,
  pushZStack,
  setToggleButtonValue,
  viewPositionSelector,
  zStackSelector,
} from "~redux/slices/eramStateSlice";
import {
  chunkEramMessage,
  getEramMessageElements,
  spaceToken,
  stringToParsedTokenArray,
  stringToTokenArray,
} from "@poscon/shared-types/eram";
import type { Coordinate, EramMessage, EramMessageElement, KsdKey } from "@poscon/shared-types/eram";
import { useZIndex } from "hooks/useZIndex";
import { brightOption, counterOption, fontOption, viewOptionSelector } from "~redux/slices/viewOptionSlice";
import { functionKeyInsertValueMap, TBE, TBP } from "~/eramConstants";
import type { FederatedPointerEvent, Graphics as PixiGraphics } from "pixi.js";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import { useCursorContext } from "contexts/cursorContext";
import type { EramFontSize, InsertCommandEvent, RecallCommandEvent } from "@poscon/shared-frontend";
import {
  activeCategoryMenuSelector,
  connectionSelector,
  EramKeyboardEvent,
  getBitmapTextStyles,
  mcaFeedbackSelector,
  setActiveCategoryMenu,
  syncMcaInputAction,
  setMraMessage,
  replayManager,
  dispatchInsertCommandEvent,
  parseSI,
  parseSO,
  parseZR,
  useCustomEventListener,
  colorNameMap,
  computeColor,
  eramFontDimensionMap,
  eramFontNameMap,
  eramTextDimensionMap,
  isProcessingCommandSelector,
  ScrollBar,
  setMcaFeedback,
  setSelectedViewOption,
  useMousePosition,
  useViewOptionSelected,
} from "@poscon/shared-frontend";
import { useSituationDisplay } from "contexts/sdContext";
import { useBrightContext } from "contexts/brightnessContext";
import { InteractiveContainer } from "components/utils/InteractiveContainer";
import { ViewOptionContextProvider } from "contexts/viewOptionContext";
import { ToolbarCatList } from "components/buttons/EramToolbarCatButton";
import type { InsertPrefsetNameEvent, MapClickEvent } from "~/customEvents";
import { eramButtonActionMap } from "~redux/thunks/eramButtonActionMap";
import { layerZIndexMap } from "~/layerZIndexMap";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import {
  delTearOffCommandActiveSelector,
  mapScaleSelector,
  toggleDelTearOffState,
  toolbarCategoryMenusSelector,
} from "~/redux/slices/eramTempStateSlice";
import { ksdButtonActionThunk } from "~redux/thunks/ksdButtonActionThunk";
import { deltaRange, resetRangeAction, translateRangeCenterOverride } from "~/redux/thunks/mapThunks";
import { chunkRows, assert } from "@poscon/shared-types";
import { EramConnection } from "@poscon/shared-types/poscon";

const view = "MESSAGE_COMPOSE_AREA";

const optionMap = {
  paLines: counterOption(view, "paLines", "PA LINES", 2, 12, 12),
  width: counterOption(view, "width", "WIDTH", 25, 50, 8, "delta", 25),
  font: fontOption(view),
  bright: brightOption(view),
};

export const MessageComposeArea = () => {
  const { setCursorOverride } = useCursorContext();
  const ref = useRef<PixiGraphics>(null);
  const dispatch = useRootDispatch();
  const connection = useRootSelector(connectionSelector) as EramConnection;
  const { rect: sdRect } = useSituationDisplay();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const delTearOffCommandIsActive = useRootSelector(delTearOffCommandActiveSelector);
  const mcaFeedback = useRootSelector(mcaFeedbackSelector);
  const _pos = useRootSelector((state) => viewPositionSelector(state, view));
  const [mcaInput, setMcaInput] = useState<EramMessageElement[]>([]);
  const { value: insertMode, toggle: toggleInsertMode, setValue: setInsertMode } = useBoolean(true);
  const [cursorPosition, setCursorPosition] = useState(0);
  const zStack = useRootSelector(zStackSelector);
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));
  const { selected: showOptions } = useViewOptionSelected(view);
  const zIndex = useZIndex(view, ref);
  const isProcessingCommand = useRootSelector(isProcessingCommandSelector);
  const mousePositionRef = useMousePosition();

  const fontFamily = eramFontNameMap[viewOptions.font as EramFontSize];
  const { width: fontWidth, height: fontHeight } = eramFontDimensionMap[fontFamily];

  const { borderTint } = useBrightContext();
  const mapScale = useRootSelector(mapScaleSelector);
  const categoryMenus = useRootSelector(toolbarCategoryMenusSelector);
  const activeCategoryMenu = useRootSelector(activeCategoryMenuSelector);
  const [paScrollOffset, setPaScrollOffset] = useState(0);
  const refForEventListeners = useRef<{
    recall: EramMessageElement[];
    cursorPosition: number;
    input: EramMessageElement[];
    insertMode: boolean;
  }>({ recall: [], cursorPosition: 0, input: [], insertMode: true });

  refForEventListeners.current.input = mcaInput;
  refForEventListeners.current.cursorPosition = cursorPosition;
  refForEventListeners.current.insertMode = insertMode;

  const showArrows = mcaInput.length > viewOptions.width * viewOptions.paLines;

  useEffect(() => {
    if (paScrollOffset > 0 && !showArrows) {
      setPaScrollOffset(0);
    }
    if ((paScrollOffset + viewOptions.paLines) * viewOptions.width > mcaInput.length) {
      setPaScrollOffset(
        Math.max(0, Math.floor((mcaInput.length + 1) / viewOptions.width) - viewOptions.paLines),
      );
    }
    if (showArrows && cursorPosition > (paScrollOffset + viewOptions.paLines - 1) * viewOptions.width - 1) {
      setPaScrollOffset(Math.floor(cursorPosition / viewOptions.width) - viewOptions.paLines + 1);
    }
    if (showArrows && cursorPosition < paScrollOffset * viewOptions.width) {
      setPaScrollOffset(Math.floor(cursorPosition / viewOptions.width));
    }
  }, [cursorPosition, mcaInput.length, paScrollOffset, showArrows, viewOptions.paLines, viewOptions.width]);

  const paHeight = Math.min(viewOptions.paLines, Math.max(2, Math.ceil(mcaInput.length / viewOptions.width)));

  const showSuccessIndicator = mcaFeedback?.isSuccess !== undefined;
  const feedbackRows = mcaFeedback
    ? [
        `${showSuccessIndicator ? "  " : ""}${mcaFeedback.line1 ?? (mcaFeedback.isSuccess === undefined ? "" : mcaFeedback.isSuccess ? "ACCEPT" : "REJECT")}`,
        ...mcaFeedback.lines,
      ]
        .flatMap((l) => chunkRows(l, viewOptions.width))
        .filter((s) => s.length > 0)
    : [];
  let width = fontWidth * viewOptions.width + 7;
  const height = fontHeight * (paHeight + Math.max(4, feedbackRows.length)) + 3;

  if (showArrows) {
    width += fontWidth + 4;
  }

  const { startDrag } = useDragging(ref, view);
  const { getSdCoordinates } = useSituationDisplay();

  const pos = {
    x: Math.min(_pos.x, windowWidth - width),
    y: Math.min(_pos.y, windowHeight - height - 1),
  };

  const parseEramMessage = useCallback(
    async (msg: EramMessage, insertUplink = false) => {
      refForEventListeners.current.recall = msg;
      const elements = getEramMessageElements(msg);

      const [command] = elements;

      assert(command, "command is empty");

      switch (command.token) {
        case "SI":
          dispatch(parseSI(elements));
          break;
        case "SO":
          dispatch(parseSO(elements));
          break;
        case "ZR":
          dispatch(parseZR(elements));
          break;
        default:
          if (insertUplink) {
            elements.splice(1, 0, { token: "/U" });
          }
          dispatch(processEramMessage(elements, false));
          break;
      }
    },
    [dispatch],
  );

  const inputIsLocked =
    (connection?.hasActiveRadarConnection && !connection.isActive) ||
    (connection?.sectorId && connection?.role === "Observer");

  useEventListener("keydown", (e: KeyboardEvent) => {
    if (e.code === "KeyI" && e.ctrlKey && e.shiftKey) {
      // open devtools
      return;
    }
    e.preventDefault();
    const event = new EramKeyboardEvent(e);
    if (event.ctrl && event.code === "KeyR") {
      window.location.reload();
      return;
    }
    if (inputIsLocked) {
      return;
    }
    const mousePosition = mousePositionRef.current;

    // special keys / key remappings
    switch (event.code) {
      case "Home":
        dispatch(deltaRange(25));
        return;
      case "End":
        dispatch(deltaRange(-25));
        return;
      case "PageUp":
        dispatch(deltaVectorLength(1));
        return;
      // RNG DOWN
      case "PageDown":
        dispatch(deltaVectorLength(-1));
        return;
      // MSG ACK
      case "ControlLeft":
        dispatch(setMraMessage([]));
        return;
      case "KeyS":
        if (event.ctrl && event.alt && !inputIsLocked) {
          dispatch(setToggleButtonValue({ buttonId: "SETTINGS_MENU", newValue: true }));
          return;
        }
        break;
      case "Escape":
        if (!inputIsLocked) {
          dispatch(setActiveCategoryMenu(null));
          if (delTearOffCommandIsActive) {
            dispatch(toggleDelTearOffState());
            return;
          }
        }
        break;
      case "Enter":
        if (event.alt && window.__TAURI__) {
          WebviewWindow.getCurrent()
            .isDecorated()
            .then(async (isDecorated) => {
              await WebviewWindow.getCurrent().setFullscreen(false);
              void WebviewWindow.getCurrent().setDecorations(!isDecorated);
            });
          return;
        }
        if (event.ctrl && window.__TAURI__) {
          WebviewWindow.getCurrent()
            .isFullscreen()
            .then(async (isFullscreen) => {
              if (!isFullscreen) {
                await WebviewWindow.getCurrent().setDecorations(true);
              }
              void WebviewWindow.getCurrent().setFullscreen(!isFullscreen);
            });
          return;
        }
    }
    if (isProcessingCommand) {
      return;
    }
    if (zIndex < zStack.length - 1 || zStack.length === 0) {
      dispatch(pushZStack("MESSAGE_COMPOSE_AREA"));
    }
    const key = event.key;
    // function keys
    if (!replayManager.replayMode && /^F\d+$/.test(key)) {
      if (event.alt) {
        const categoryMenuIndex = categoryMenus?.findIndex(
          (m) => m.catSelectionKey === parseInt(key.slice(1), 10),
        );
        if (categoryMenuIndex !== undefined && categoryMenuIndex !== -1) {
          dispatch(setActiveCategoryMenu(categoryMenuIndex));
        }
        return;
      }
      if (key in functionKeyInsertValueMap) {
        dispatchInsertCommandEvent(stringToTokenArray(functionKeyInsertValueMap[key]!));
      }
    }
    if (!event.useDigitsAsNumpad) {
      switch (event.code) {
        case "Digit2":
          if (event.shift) {
            dispatch(eramButtonActionMap.CURSOR_VOLUME!.action!("CURSOR_VOLUME", 1));
            return;
          }
          break;
        case "Digit3":
          if (event.shift) {
            dispatch(eramButtonActionMap.CURSOR_VOLUME!.action!("CURSOR_VOLUME", -1));
            return;
          }
          break;
        case "Digit4":
          setMcaInput(refForEventListeners.current.recall);
          setCursorPosition(0);
          return;
        // HOME
        case "Digit5":
          if (window.__TAURI__) {
            void WebviewWindow.getCurrent().setCursorPosition(
              new LogicalPosition(window.innerWidth / 2 - 1, window.innerHeight / 2 - 1),
            );
          }
          return;
        case "Digit6":
          if (!replayManager.replayMode && sdRect.contains(mousePosition.x, mousePosition.y) && event.shift) {
            const sdCoordinate = getSdCoordinates([mousePosition.x, mousePosition.y]);
            const diff = [
              (sdCoordinate[0] - sdRect.width / 2) / mapScale,
              (sdCoordinate[1] - sdRect.height / 2) / mapScale,
            ] satisfies Coordinate;
            dispatch(translateRangeCenterOverride(diff));
          }
          return;
        case "Digit7":
          if (!replayManager.replayMode && event.shift) {
            dispatch(resetRangeAction());
          }
          return;
        case "Digit8":
          dispatchInsertCommandEvent(stringToTokenArray("QA"));
          return;
        case "Digit9":
          dispatchInsertCommandEvent(stringToTokenArray(event.shift ? "SO" : "SI"));
          return;
      }
    } else if (event.isNumpadKey && event.isDigit) {
      const num = parseInt(event.key);
      dispatch(ksdButtonActionThunk(num as KsdKey, "keydown"));
      return;
      // TODO: implement SAA KSD functions
    }
    switch (key) {
      case "Pause":
      case "Enter":
        if (mcaInput.length > 0) {
          void parseEramMessage(mcaInput, key === "Pause");
        } else {
          dispatch(setMcaFeedback(null));
        }
        break;
      case "Escape":
        setMcaInput([]);
        setCursorPosition(0);
        dispatch(setMcaFeedback(null));
        break;
      case "ArrowLeft":
        if (cursorPosition % viewOptions.width === 0) {
          setPaScrollOffset((prevOffset) => Math.max(0, prevOffset - 1));
        }
        setCursorPosition((prevPosition) => Math.max(0, prevPosition - 1));
        break;
      case "ArrowUp":
        setCursorPosition((prevPosition) => Math.max(0, prevPosition - viewOptions.width));
        break;
      case "ArrowDown":
        setCursorPosition((prevPosition) => Math.min(mcaInput.length, prevPosition + viewOptions.width));
        break;
      case "ArrowRight":
        if (cursorPosition % viewOptions.width === viewOptions.width - 1) {
          setPaScrollOffset((prevOffset) =>
            Math.min(
              prevOffset + 1,
              Math.max(0, Math.floor((mcaInput.length + 1) / viewOptions.width) - viewOptions.paLines),
            ),
          );
        }
        setCursorPosition((prevPosition) => Math.min(prevPosition + 1, mcaInput.length));
        break;
      case "Backspace":
        if (cursorPosition > 0) {
          setMcaInput((prevValue) =>
            prevValue.slice(0, cursorPosition - 1).concat(prevValue.slice(cursorPosition)),
          );
          setCursorPosition((prevPosition) => Math.max(0, prevPosition - 1));
        }
        break;
      case "Insert":
        toggleInsertMode();
        break;
      case "Delete":
        setMcaInput((prevValue) =>
          prevValue.slice(0, cursorPosition).concat(prevValue.slice(cursorPosition + 1)),
        );
        break;
      default:
        if (event.token) {
          setMcaInput((prevValue) =>
            [...prevValue.slice(0, cursorPosition), { token: event.token! }].concat(
              prevValue.slice(cursorPosition + (insertMode ? 1 : 0)),
            ),
          );
          setCursorPosition((prevPosition) => prevPosition + 1);
        }
    }
  });

  useEventListener("keyup", (event: KeyboardEvent) => {
    if (/^Numpad[1-9]$/.test(event.code)) {
      const num = parseInt(event.code.slice(6), 10);
      dispatch(ksdButtonActionThunk(num as KsdKey, "keyup"));
    }
  });

  useCustomEventListener("mapclick", (event: MapClickEvent) => {
    const {
      input: currentInput,
      cursorPosition: currentCursorPosition,
      insertMode: currentInsertMode,
    } = refForEventListeners.current;
    if (inputIsLocked) {
      return;
    }
    if (event.detail.command) {
      void parseEramMessage(event.detail.command);
    } else if (currentInput.length > 0 || event.detail.targetFpId || event.detail.targetTrackId) {
      let newInput = [...currentInput.slice(0, currentCursorPosition)];
      let cursorPos = currentCursorPosition;
      const prependSpace =
        (!currentInsertMode || currentCursorPosition === currentInput.length) &&
        !(
          currentInput[currentCursorPosition - 1]?.token === " " ||
          !currentInput[currentCursorPosition - 1]?.token
        );
      const appendSpace = currentCursorPosition === currentInput.length;
      if (prependSpace) {
        newInput.push(spaceToken);
        cursorPos += 1;
      }
      newInput.push({ ...event.detail });
      cursorPos += 1;
      if (appendSpace) {
        newInput.push(spaceToken);
        cursorPos += 1;
      }
      newInput = newInput.concat(currentInput.slice(!currentInsertMode ? currentCursorPosition : cursorPos));
      if (event.detail.button === TBP) {
        setMcaInput(newInput);
        setCursorPosition(cursorPos);
      } else if (event.detail.button === TBE) {
        void parseEramMessage(newInput);
      }
    } else {
      setCursorOverride("InvalidSelectionX");
    }
  });

  useCustomEventListener("insertcommand", (event: InsertCommandEvent) => {
    setMcaInput(event.detail.command);
    setCursorPosition(event.detail.command.length);
  });

  useCustomEventListener("recallcommand", (event: RecallCommandEvent) => {
    const recall = event.detail;
    setCursorPosition(recall?.cursorPosition ?? 0);
    setMcaInput(recall?.msg ?? []);
  });

  useCustomEventListener("insertprefsetname", (event: InsertPrefsetNameEvent) => {
    const currentInput = refForEventListeners.current.input;
    if (currentInput.length > 0) {
      const newValue = currentInput.concat(stringToTokenArray(event.detail.prefsetName));
      setMcaInput(newValue);
      setCursorPosition(newValue.length);
    } else if (connection) {
      dispatch(
        processEramMessage(stringToParsedTokenArray(`UI ${connection.userId} ${event.detail.prefsetName}`)),
      );
    }
  });

  useEffect(() => {
    void replayManager.recordReplayEvent("mcaInput", { mcaInput, cursorPosition, insertMode });
    dispatch(
      syncMcaInputAction({
        mcaInput,
        cursorPosition,
        insertMode,
      }),
    );
  }, [cursorPosition, dispatch, insertMode, mcaInput]);

  useCustomEventListener("replay:mcaInput", (event) => {
    const { mcaInput: newInput, cursorPosition: newCursorPosition, insertMode: newInsertMode } = event.detail;
    setMcaInput(newInput);
    setCursorPosition(newCursorPosition);
    setInsertMode(newInsertMode);
  });

  useCustomEventListener("ui:sync:mcaInput", (event) => {
    const { mcaInput: newInput, cursorPosition: newCursorPosition, insertMode: newInsertMode } = event.detail;
    setMcaInput(newInput);
    setCursorPosition(newCursorPosition);
    setInsertMode(newInsertMode);
  });

  const fill = computeColor(colorNameMap.white, viewOptions.bright / 100);

  const cursorX = (cursorPosition % viewOptions.width) * fontWidth + 4;
  const cursorY =
    Math.min(Math.floor(cursorPosition / viewOptions.width), viewOptions.paLines - 1) * fontHeight;

  const categoryMenu = activeCategoryMenu !== null ? categoryMenus?.[activeCategoryMenu] : null;
  const toolbarCatListHeight = categoryMenu
    ? categoryMenu.categoryItems.length * (eramTextDimensionMap[1].height + 3) + 3
    : 0;

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <InteractiveContainer label="MCA" x={pos.x} y={pos.y} zIndex={101 + zIndex}>
        <graphics
          ref={ref}
          eventMode="static"
          draw={(graphics) => {
            graphics.clear();
            graphics.rect(0, 0, width, height).fill(0).stroke({ width: 1, color: borderTint });
            graphics
              .moveTo(0, fontHeight * paHeight + 4)
              .lineTo(width, fontHeight * paHeight + 4)
              .stroke({ width: 1, color: borderTint });
          }}
          onMouseDown={(event: FederatedPointerEvent) => {
            switch (event.button) {
              case TBE:
                event.preventDefault();
                dispatch(setSelectedViewOption(view));
                break;
              case TBP:
                void startDrag(event);
                break;
            }
          }}
        />
        <graphics
          label="MCA_CURSOR"
          x={cursorX}
          y={cursorY}
          eventMode="none"
          draw={(graphics) => {
            graphics.clear();
            if (insertMode) {
              graphics.moveTo(0, fontHeight).lineTo(fontWidth, fontHeight);
            } else {
              graphics.moveTo(0, 2).lineTo(0, fontHeight).lineTo(fontWidth, fontHeight).lineTo(fontWidth, 2);
            }
            graphics.stroke({ width: 1, color: fill });
          }}
        />
        {showArrows && (
          <ScrollBar
            x={width - fontWidth - 3}
            y={2}
            height={paHeight * fontHeight}
            scrollUpDisabled={paScrollOffset === 0}
            scrollDownDisabled={(paScrollOffset + viewOptions.paLines) * viewOptions.width > mcaInput.length}
            scrollUp={() => {
              setPaScrollOffset(Math.max(0, paScrollOffset - 1));
              setCursorPosition(Math.max(0, cursorPosition - viewOptions.width));
            }}
            scrollDown={() => {
              setPaScrollOffset(
                Math.min(
                  paScrollOffset + 1,
                  Math.ceil((mcaInput.length + 1) / viewOptions.width) - (viewOptions.paLines - 1),
                ),
              );
              setCursorPosition(Math.min(mcaInput.length, cursorPosition + viewOptions.width));
            }}
            tint={fill}
            disabledTint={computeColor(colorNameMap.grey, viewOptions.bright / 100)}
            borderTint={borderTint}
            fontSize={viewOptions.font as EramFontSize}
          />
        )}
        <bitmapText
          label="MCA_PREVIEW_AREA"
          text={chunkEramMessage(mcaInput, viewOptions.width)
            .slice(paScrollOffset, paScrollOffset + viewOptions.paLines)
            .join("\n")}
          x={4}
          y={1}
          eventMode="none"
          style={{
            ...getBitmapTextStyles(fontFamily),
            fill,
          }}
        />
        {mcaFeedback && (
          <>
            {mcaFeedback.isSuccess !== undefined && (
              <bitmapText
                label="MCA_FEEDBACK_AREA_INDICATOR"
                text={mcaFeedback.isSuccess ? "\u0083" : "X"}
                x={4}
                y={fontHeight * paHeight + 2}
                eventMode="none"
                style={{
                  ...getBitmapTextStyles(fontFamily),
                  fill: computeColor(
                    mcaFeedback.isSuccess ? colorNameMap.green : colorNameMap.red,
                    viewOptions.bright / 100,
                  ),
                }}
              />
            )}
            <bitmapText
              label="MCA_FEEDBACK_AREA_LINE_1"
              text={feedbackRows.join("\n")}
              x={4}
              y={fontHeight * paHeight + 6}
              eventMode="none"
              style={{
                ...getBitmapTextStyles(fontFamily),
                fill,
              }}
            />
          </>
        )}
      </InteractiveContainer>
      <container x={pos.x} y={pos.y} zIndex={layerZIndexMap.viewMenu}>
        {categoryMenu && (
          <ToolbarCatList
            x={1}
            y={pos.y - toolbarCatListHeight - 1 > 0 ? -toolbarCatListHeight : height + 1}
            categoryItems={categoryMenu.categoryItems}
          />
        )}
        {showOptions && <ViewMenu view={view} parentWidth={width} options={optionMap} />}
      </container>
    </ViewOptionContextProvider>
  );
};
