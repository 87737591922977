import type { RootThunkAction } from "~redux/store";
import { momentaryButtonList } from "types/eramButton";
import type { ToggleButtonId } from "types/eramButton";
import { setToggleButtonValue, toggleButton } from "~redux/slices/eramStateSlice";
import { errorTone } from "@poscon/shared-frontend";
import { ksdKeysSelector, setLine4DisplayOverride } from "~redux/slices/eramTempStateSlice";
import type { KsdKey, SDKeypadKeyFunction } from "@poscon/shared-types/eram";

export const ksdFunctionMap: Record<SDKeypadKeyFunction, ToggleButtonId | null> = {
  AircraftDataDisplayOverride: null,
  AllLDBsDisplayButton: "ALL_LDBS",
  BeaconCodeDisplayOverride: "CODE",
  DestinationDisplayOverride: null,
  FacilitySAAsButton: null,
  GeomapFilterButton1: "MAP_1",
  GeomapFilterButton10: "MAP_10",
  GeomapFilterButton11: "MAP_11",
  GeomapFilterButton12: "MAP_12",
  GeomapFilterButton13: "MAP_13",
  GeomapFilterButton14: "MAP_14",
  GeomapFilterButton15: "MAP_15",
  GeomapFilterButton16: "MAP_16",
  GeomapFilterButton17: "MAP_17",
  GeomapFilterButton18: "MAP_18",
  GeomapFilterButton19: "MAP_19",
  GeomapFilterButton2: "MAP_2",
  GeomapFilterButton20: "MAP_20",
  GeomapFilterButton3: "MAP_3",
  GeomapFilterButton4: "MAP_4",
  GeomapFilterButton5: "MAP_5",
  GeomapFilterButton6: "MAP_6",
  GeomapFilterButton7: "MAP_7",
  GeomapFilterButton8: "MAP_8",
  GeomapFilterButton9: "MAP_9",
  HSFDisplayOverride: null,
  HighIntensityATCWeatherDisplayButton: null,
  LowIntensityATCWeatherDisplayButton: null,
  MediumIntensityWeatherDisplayButton: null,
  NonADSBIndicationOverride: "NON_ADSB",
  NonModeCBeaconTargetDisplayButton: "NON_MODE_C",
  PairedSelectLDBsDisplayButton: "PR_LDB",
  PrimaryTargetDisplayButton: "ALL_PRIM",
  SectorSAAsButton: null,
  SelectBeaconDisplayButton: "SELECT_BEACON",
  SpeedDisplayOverride: "SPEED",
  StrobeDisplayButton: "STROBE_LINES",
  UnpairedSelectLDBsDisplayButton: "UNP_LDB",
  VerticalRateIndicatorOverride: "VRI",
};

export const ksdButtonActionThunk = (key: KsdKey, event: "keydown" | "keyup"): RootThunkAction => {
  return event === "keydown" ? ksdKeyDownActionThunk(key) : ksdKeyUpActionThunk(key);
};

const ksdKeyDownActionThunk = (key: KsdKey): RootThunkAction => {
  return (dispatch, getState) => {
    const ksdKeys = ksdKeysSelector(getState());
    const keyFunc = ksdKeys.find((k) => k.key === key)?.function;
    const keyFuncButtonId = keyFunc ? ksdFunctionMap[keyFunc] : null;
    if (keyFuncButtonId) {
      if (momentaryButtonList.includes(keyFuncButtonId)) {
        dispatch(setToggleButtonValue({ buttonId: keyFuncButtonId, newValue: true }));
      } else {
        dispatch(toggleButton(keyFuncButtonId));
      }
    } else {
      switch (keyFunc) {
        case "HSFDisplayOverride":
          dispatch(setLine4DisplayOverride("HSF"));
          break;
        case "AircraftDataDisplayOverride":
          dispatch(setLine4DisplayOverride("TYPE"));
          break;
        case "DestinationDisplayOverride":
          dispatch(setLine4DisplayOverride("DEST"));
          break;
        default:
          errorTone.tryPlay();
      }
    }
    // TODO: implement SAA KSD functions
  };
};

const ksdKeyUpActionThunk = (key: KsdKey): RootThunkAction => {
  return (dispatch, getState) => {
    const ksdKeys = ksdKeysSelector(getState());
    const keyFunc = ksdKeys.find((k) => k.key === key)?.function;
    const keyFuncButtonId = keyFunc ? ksdFunctionMap[keyFunc] : null;
    if (keyFuncButtonId && momentaryButtonList.includes(keyFuncButtonId)) {
      dispatch(setToggleButtonValue({ buttonId: keyFuncButtonId, newValue: false }));
    } else {
      switch (keyFunc) {
        case "AircraftDataDisplayOverride":
        case "HSFDisplayOverride":
        case "DestinationDisplayOverride":
          dispatch(setLine4DisplayOverride(null));
      }
    }
  };
};
