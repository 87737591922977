import type { Coordinate, FlightplanId, TrackId } from "@poscon/shared-types";
import { EramMessage } from "@poscon/shared-types/eram";
import type { MouseEvent } from "react";

export type MapClickEvent = CustomEvent<{
  targetTrackId?: TrackId;
  targetFpId?: FlightplanId;
  command?: EramMessage;
  sdCoordinate: Coordinate;
  geoCoordinate?: Coordinate;
  button: MouseEvent["button"];
}>;
export type InsertPrefsetNameEvent = CustomEvent<{ prefsetName: string }>;

export function dispatchInsertPrefsetNameEvent(prefsetName: string) {
  document.dispatchEvent(
    new CustomEvent("insertprefsetname", {
      detail: {
        prefsetName,
      },
    }),
  );
}

export function dispatchMapClickEvent(arg: MapClickEvent["detail"]) {
  document.dispatchEvent(
    new CustomEvent("mapclick", {
      detail: arg,
    }),
  );
}
