import * as Sentry from "@sentry/react";
import { env } from "./env";
import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import { PosconStage, ReplayContextProvider } from "@poscon/shared-frontend";
import { store } from "~redux/store";
import { Eram } from "components/Eram";
import { Provider } from "react-redux";
import { Assets } from "pixi.js";
import 'pixi.js/text-bitmap';

Sentry.init({
  dsn: env.VITE_SENTRY_URL_ERAM,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  sampleRate: 1,
  // Tracing
  tracesSampleRate: 0.001,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["hq.poscon.local", "https://services.poscon.com/eram"],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 0.1,
});

await Assets.load([
  new URL("/fonts/eramCpdlc1.xml", import.meta.url).href,
  new URL("/fonts/eramCpdlc2.xml", import.meta.url).href,
  new URL("/fonts/eramCpdlc3.xml", import.meta.url).href,
  new URL("/fonts/eramCpdlc4.xml", import.meta.url).href,
  new URL("/fonts/eramCpdlc5.xml", import.meta.url).href,
  new URL("/fonts/eramText1.xml", import.meta.url).href,
  new URL("/fonts/eramText2.xml", import.meta.url).href,
  new URL("/fonts/eramText3.xml", import.meta.url).href,
  new URL("/fonts/eramText4.xml", import.meta.url).href,
  new URL("/fonts/eramText5.xml", import.meta.url).href,
  new URL("/fonts/eramText6.xml", import.meta.url).href,
  new URL("/fonts/eramText7.xml", import.meta.url).href,
  new URL("/fonts/eramText8.xml", import.meta.url).href,
  new URL("/fonts/eramGeomapSymbols1.xml", import.meta.url).href,
  new URL("/fonts/eramGeomapSymbols2.xml", import.meta.url).href,
  new URL("/fonts/eramGeomapSymbols3.xml", import.meta.url).href,
  new URL("/fonts/eramGeomapSymbols4.xml", import.meta.url).href,
  new URL("/fonts/eramPositionSymbols1.xml", import.meta.url).href,
  new URL("/fonts/eramTargetSymbols1.xml", import.meta.url).href,
]);


const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <div id="eramRoot" className="root" onContextMenu={(e) => e.preventDefault()}>
      <PosconStage>
        <Provider store={store}>
          <ReplayContextProvider>
            <Eram />
          </ReplayContextProvider>
        </Provider>
      </PosconStage>
    </div>
  </React.StrictMode>,
);
