import React, { useCallback, useEffect, useState } from "react";
import type { Nullable } from "@poscon/shared-types";
import type { ToolbarId } from "~redux/slices/eramStateSlice";
import {
  addToolbarButton,
  delTearOffButton,
  removeMacro,
  removeToolbarButton,
  setTearOffButtonPosition,
} from "~redux/slices/eramStateSlice";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import type { EramButtonId, PriorityButtonId } from "types/eramButton";
import { isPriorityButton } from "types/eramButton";
import { LogicalPosition } from "@tauri-apps/api/window";
import { TBE, TBP } from "~/eramConstants";
import type { FederatedPointerEvent, Graphics } from "pixi.js";
import { useButtonContext } from "contexts/buttonContext";
import {
  usePixiMouseEventListener,
  clipCursorToWindow,
  useCustomEventListener,
} from "@poscon/shared-frontend";
import { useAnyDragging, useDragContext } from "contexts/dragContext";
import { clipCursorToSituationDisplay } from "~/utils/cursor";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import { delTearOffCommandActiveSelector, setDelTearOffState } from "~/redux/slices/eramTempStateSlice";

export type SubmitDelTearOffEventArgs = {
  buttonId?: "MACRO_BUTTON" | Exclude<EramButtonId, PriorityButtonId>;
  macroLabel?: string;
  stationCode?: string;
  itemType?: "METAR" | "ALTIM";
};

export type SubmitDelTearOffEvent = CustomEvent<SubmitDelTearOffEventArgs>;

type ActiveTearingToolbar = { toolbarId: ToolbarId; row?: 0 | 1 };
type TearOffContextValue = {
  startTearOff: <T extends EramButtonId | "MACRO_BUTTON">(
    event: FederatedPointerEvent,
    button: T,
    graphics: Graphics,
    macroLabel?: T extends "MACRO_BUTTON" ? string : never,
  ) => void;
  tearingButton: (EramButtonId | "MACRO_BUTTON") | null;
  tearingMacroLabel: string | null;
  setTearingButton: <T extends EramButtonId | "MACRO_BUTTON">(
    button: T | null,
    macroLabel?: T extends "MACRO_BUTTON" ? string : never,
  ) => void;
  pendingDelTearOffButtonList: Exclude<EramButtonId, PriorityButtonId>[];
  pendingDelMacroButtonList: string[];
  toggleDelTearOffButtonPending: <T extends "MACRO_BUTTON" | Exclude<EramButtonId, PriorityButtonId>>(
    buttonId: T,
    macroLabel?: T extends "MACRO_BUTTON" ? string : never,
  ) => void;
  delTearOffCommandActive: boolean;
  onGhostButtonClick: <T extends EramButtonId | "MACRO_BUTTON">(
    toolbarId: ToolbarId,
    buttonId: Exclude<T, PriorityButtonId>,
    row: number,
    col: number,
    macroLabel?: T extends "MACRO_BUTTON" ? string : never,
  ) => void;
  activeTearingTb: { toolbarId: ToolbarId; row?: 0 | 1 } | null;
  setActiveTearingTb: (activeTearingToolbar: Nullable<ActiveTearingToolbar>) => void;
  submitDelTearOffEvent: (detail: SubmitDelTearOffEventArgs) => void;
};

export const TearOffContext = React.createContext<TearOffContextValue | null>(null);

export const TearOffContextProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useRootDispatch();
  const delTearOffCommandActive = useRootSelector(delTearOffCommandActiveSelector);
  const { buttonWidth, buttonHeight } = useButtonContext();
  const { anyDragging, setAnyDragging } = useAnyDragging();
  const [pendingDelTearOffButtonList, setPendingDelTearOffButtonList] = useState<
    Exclude<EramButtonId, PriorityButtonId>[]
  >([]);
  const [pendingDelMacroButtonList, setPendingDelMacroButtonList] = useState<string[]>([]);
  const [tearingButton, _setTearingButton] = useState<Nullable<EramButtonId | "MACRO_BUTTON">>(null);
  const [tearingMacroLabel, setTearingMacroLabel] = useState<Nullable<string>>(null);
  const [activeTearingTb, setActiveTearingTb] = useState<Nullable<ActiveTearingToolbar>>(null);
  const {
    setDraggingOutlineVisible,
    setDraggingOutlinePositionAndDimension,
    getDraggingOutlinePositionAndDimension,
    draggingHandler,
  } = useDragContext();

  const setTearingButton = useCallback(
    <T extends EramButtonId | "MACRO_BUTTON">(
      button: T | null,
      macroLabel?: T extends "MACRO_BUTTON" ? string : never,
    ) => {
      if (button === null) {
        _setTearingButton(null);
        setTearingMacroLabel(null);
      }
      _setTearingButton(button);
      if (button === "MACRO_BUTTON") {
        setTearingMacroLabel(macroLabel ?? null);
      }
    },
    [],
  );

  const abIsOpen = useRootSelector((state) => state.eram.toggleButtonState.AB_OPEN);

  useEffect(() => {
    if (tearingButton && activeTearingTb && !isPriorityButton(tearingButton)) {
      setDraggingOutlineVisible(false);
    }
    if (tearingButton && !activeTearingTb && !isPriorityButton(tearingButton)) {
      setDraggingOutlineVisible(true);
    }
  }, [activeTearingTb, setDraggingOutlineVisible, tearingButton]);

  useEffect(() => {
    if (!delTearOffCommandActive) {
      setPendingDelTearOffButtonList([]);
    }
  }, [delTearOffCommandActive]);

  const submitDelTearOffEvent = (detail: SubmitDelTearOffEventArgs) => {
    const event = new CustomEvent<SubmitDelTearOffEventArgs>("submitDelTearOff", {
      detail,
    });
    dispatch(setDelTearOffState(false));
    document.dispatchEvent(event);
  };

  const startTearOff = useCallback(
    (
      event: FederatedPointerEvent,
      button: EramButtonId | "MACRO_BUTTON",
      graphics: Graphics,
      macroLabel?: string,
    ) => {
      if (!anyDragging && (event.button === TBP || event.button === TBE)) {
        event.stopImmediatePropagation();
        const globalPos = graphics.getGlobalPosition();
        let previewPos = { x: event.pageX, y: event.pageY };
        if (window.__TAURI__) {
          if (abIsOpen) {
            void clipCursorToWindow(buttonWidth - 1, buttonHeight);
          } else {
            void clipCursorToSituationDisplay(buttonWidth, buttonHeight);
          }
          previewPos = { x: globalPos.x, y: globalPos.y };
          void WebviewWindow.getCurrent().setCursorPosition(new LogicalPosition(previewPos.x, previewPos.y));
        }
        setDraggingOutlinePositionAndDimension(previewPos.x - 1, previewPos.y, buttonWidth, buttonHeight);
        setAnyDragging(true);
        setTearingButton(button, macroLabel);
        window.addEventListener("mousemove", draggingHandler, { passive: true, capture: true });
      }
    },
    [
      abIsOpen,
      anyDragging,
      buttonHeight,
      buttonWidth,
      draggingHandler,
      setAnyDragging,
      setDraggingOutlinePositionAndDimension,
      setTearingButton,
    ],
  );

  usePixiMouseEventListener(
    (event) => {
      if (tearingButton) {
        if ((event.target as any)?.label?.includes("GHOST_BUTTON") && !isPriorityButton(tearingButton)) {
          return;
        }
        event.stopImmediatePropagation();
        const { x, y } = getDraggingOutlinePositionAndDimension();
        const newPos = { x, y };
        if (window.__TAURI__ && abIsOpen) {
          void WebviewWindow.getCurrent().setCursorGrab(false);
        }
        dispatch(removeToolbarButton({ buttonId: tearingButton, macroLabel: tearingMacroLabel }));
        dispatch(
          setTearOffButtonPosition({
            button: tearingButton,
            position: newPos,
            macroLabel: tearingMacroLabel,
          }),
        );
        setAnyDragging(false);
        setDraggingOutlineVisible(false);
        setTearingButton(null);
        window.removeEventListener("mousemove", draggingHandler, { capture: true });
      }
    },
    undefined,
    true,
  );

  const toggleDelTearOffButtonPending = <T extends "MACRO_BUTTON" | Exclude<EramButtonId, PriorityButtonId>>(
    buttonId: T,
    macroLabel?: T extends "MACRO_BUTTON" ? string : never,
  ) => {
    if (buttonId === "MACRO_BUTTON") {
      setPendingDelMacroButtonList((prev) =>
        prev.includes(macroLabel!) ? prev.filter((label) => label !== macroLabel) : [...prev, macroLabel!],
      );
    } else if (delTearOffCommandActive) {
      setPendingDelTearOffButtonList((prev) =>
        prev.includes(buttonId) ? prev.filter((id) => id !== buttonId) : [...prev, buttonId],
      );
    }
  };

  const onGhostButtonClick = useCallback(
    <T extends EramButtonId | "MACRO_BUTTON">(
      toolbarId: ToolbarId,
      buttonId: Exclude<T, PriorityButtonId>,
      row: number,
      col: number,
      macroLabel?: T extends "MACRO_BUTTON" ? string : never,
    ) => {
      dispatch(addToolbarButton({ toolbarId, buttonId, row, col, macroLabel }));
      if (window.__TAURI__ && abIsOpen) {
        void WebviewWindow.getCurrent().setCursorGrab(false);
      }
      dispatch(delTearOffButton({ buttonId, macroLabel }));
      setAnyDragging(false);
      setDraggingOutlineVisible(false);
      setTearingButton(null);
      window.removeEventListener("mousemove", draggingHandler, { capture: true });
    },
    [abIsOpen, dispatch, draggingHandler, setAnyDragging, setDraggingOutlineVisible, setTearingButton],
  );

  useCustomEventListener("submitDelTearOff", ({ detail }: SubmitDelTearOffEvent) => {
    if (delTearOffCommandActive) {
      const { buttonId, macroLabel } = detail;
      if (buttonId) {
        if (buttonId === "MACRO_BUTTON") {
          dispatch(removeMacro(macroLabel!));
          setPendingDelMacroButtonList((prev) => prev.filter((label) => label !== macroLabel));
        } else {
          dispatch(removeToolbarButton({ buttonId }));
          dispatch(delTearOffButton({ buttonId }));
        }
      }
      pendingDelMacroButtonList.forEach((label) => {
        dispatch(removeMacro(label));
      });
      pendingDelTearOffButtonList.forEach((id) => {
        dispatch(removeToolbarButton({ buttonId: id }));
        dispatch(delTearOffButton({ buttonId: id }));
      });
    }
    setPendingDelTearOffButtonList([]);
  });

  const contextValue = {
    startTearOff,
    tearingButton,
    tearingMacroLabel,
    setTearingButton,
    pendingDelTearOffButtonList,
    toggleDelTearOffButtonPending,
    pendingDelMacroButtonList,
    delTearOffCommandActive,
    onGhostButtonClick,
    activeTearingTb,
    setActiveTearingTb,
    submitDelTearOffEvent,
  };

  return <TearOffContext.Provider value={contextValue}>{children}</TearOffContext.Provider>;
};

export const useTearOffContext = () => {
  const contextValue = React.useContext(TearOffContext);
  if (contextValue === null) {
    throw new Error("useTearOffContext must be used within TearOffContextProvider");
  }
  return contextValue;
};
