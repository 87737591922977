import React from "react";
import { View } from "components/utils/View";
import {
  backgroundOption,
  borderOption,
  brightOption,
  counterOption,
  fontOption,
  linesOption,
  toggleOption,
  viewOptionSelector,
} from "~redux/slices/viewOptionSlice";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { beaconCodeListSelector } from "~redux/slices/eramTempStateSlice";
import { ViewItemOptionContainer } from "components/utils/ViewMenu";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import { formatBeaconCode } from "@poscon/shared-types";
import type { FederatedEventHandler } from "pixi.js";
import type { EramFontSize, ViewItemOption } from "@poscon/shared-frontend";
import {
  useViewOptionSelected,
  colorNameMap,
  computeColor,
  eramFontDimensionMap,
  eramFontNameMap,
  ViewItem,
} from "@poscon/shared-frontend";
import { useViewOptions, ViewOptionContextProvider } from "contexts/viewOptionContext";
import { stringToParsedTokenArray } from "@poscon/shared-types/eram";

const view = "CODE_VIEW";

const optionMap = {
  background: backgroundOption(view),
  border: borderOption(view),
  lines: linesOption(view, 3, 20),
  columns: counterOption(view, "columns", "COL", 1, 5, 6),
  font: fontOption(view),
  bright: brightOption(view),
  sortManual: toggleOption(view, "sortManual", "SORT MAN", 8),
};

type BeaconCodeRowProps = { code: string; manual: boolean; onDelete: FederatedEventHandler };
const BeaconCodeRow = ({ code, manual, onDelete }: BeaconCodeRowProps) => {
  const { selected, openViewOption } = useViewOptionSelected(`${view}/code`);

  const viewOptions = useViewOptions(view);

  const fontSize = viewOptions.font as EramFontSize;
  const fontName = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontName];
  const width = 5 * fontDimension.width + 4;

  const tint = selected ? 0 : computeColor(colorNameMap.white, viewOptions.bright / 100);

  const alpha = (viewOptions.bright * 0.8) / 100 + 0.2;
  const fillColor = computeColor(selected ? colorNameMap.grey : colorNameMap.black, selected ? alpha : 1);

  const options: ViewItemOption[] = [
    {
      text: `DELETE ${code}`,
      onmousedown: onDelete,
    },
  ];

  return (
    <container>
      <ViewItem
        text={code + (manual ? "." : "")}
        width={width}
        fontSize={fontSize}
        tint={tint}
        fill={selected}
        fillColor={fillColor}
        onmousedown={() => {
          openViewOption();
        }}
      >
        {selected && <ViewItemOptionContainer xOffset={width} options={options} />}
      </ViewItem>
    </container>
  );
};

export const BeaconCodeList = () => {
  const dispatch = useRootDispatch();
  const beaconCodeList = useRootSelector(beaconCodeListSelector);
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));

  const fontSize = viewOptions.font as EramFontSize;
  const fontName = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontName];

  const height = beaconCodeList.length > 0 ? beaconCodeList.length * (fontDimension.height + 4) + 8 : 0;

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View width={9} height={height} view={view} optionMap={optionMap}>
        {beaconCodeList.length > 0 && (
          <container x={4} y={4}>
            {beaconCodeList.map((code) => (
              <BeaconCodeRow
                key={code}
                code={formatBeaconCode(code)}
                manual
                onDelete={() => dispatch(processEramMessage(stringToParsedTokenArray(`QB ${code}`)))}
              />
            ))}
          </container>
        )}
      </View>
    </ViewOptionContextProvider>
  );
};
