import React, { useRef } from "react";
import type { Container as PixiContainer } from "pixi.js";
import { useRootSelector } from "~redux/hooks";
import { routeLineMapSelector } from "~redux/slices/eramTempStateSlice";
import { useGetWindowCoordFromLonLat } from "contexts/sdContext";
import { eramFontNameMap, getBitmapTextStyles } from "@poscon/shared-frontend";

const tint = new Uint8Array([0xad, 0xad, 0x00]);

export const RouteLines = () => {
  const ref = useRef<PixiContainer>(null);
  const getPixelCoordFromLonLat = useGetWindowCoordFromLonLat();
  const routeLineMap = useRootSelector(routeLineMapSelector);
  const fdbFontSize = useRootSelector((state) => state.eram.font.FDB_FONT);

  return (
    <container label="ROUTE_LINES" eventMode="none" ref={ref} zIndex={6}>
      {Object.entries(routeLineMap).map(([id, line]) => {
        const points = line.coordinates;
        if (line.coordinates.length === 0) {
          return null;
        }
        const start = getPixelCoordFromLonLat(points[0]!);
        return (
          <React.Fragment key={id}>
            <graphics
              draw={(graphics) => {
                graphics.clear();
                graphics.setStrokeStyle({ width: 2, color: tint });
                graphics.rect(start[0] - 4, start[1] - 2, 8, 4).stroke();
                graphics.moveTo(start[0], start[1]);
                points.slice(1).forEach((point) => {
                  const p = getPixelCoordFromLonLat(point);
                  graphics.lineTo(p[0], p[1]);
                });
                graphics.stroke();
                const end = points.at(-1)!;
                const p = getPixelCoordFromLonLat(end);
                if (line.fullRoute) {
                  graphics
                    .moveTo(p[0] - 8, p[1] - 10)
                    .lineTo(p[0] + 8, p[1] + 10)
                    .lineTo(p[0] - 8, p[1] + 10)
                    .lineTo(p[0] + 8, p[1] - 10)
                    .stroke();
                } else {
                  graphics
                    .moveTo(p[0] - 4, p[1] - 2)
                    .rect(p[0] - 4, p[1] - 2, 8, 4)
                    .fill(tint).stroke();
                }
              }}
            />
            <bitmapText
              text={id.split("-").at(-1)!}
              x={start[0]}
              y={start[1]}
              style={{ ...getBitmapTextStyles(eramFontNameMap[fdbFontSize]), fill: tint }}
            />
          </React.Fragment>
        );
      })}
    </container>
  );
};
