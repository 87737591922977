import React, { useEffect, useState } from "react";
import { View } from "components/utils/View";
import {
  backgroundOption,
  borderOption,
  brightOption,
  counterOption,
  fontOption,
  toggleOption,
  viewOptionSelector,
} from "~redux/slices/viewOptionSlice";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import {
  createItemOption,
  ViewItemOptionContainer,
  colorNameMap,
  computeColor,
  eramFontDimensionMap,
  eramFontNameMap,
  ViewItem,
  useViewOptionSelected,
  sectorIdSelector,
  processEramMessage,
  useScrollProps,
  ScrollBar,
  getBitmapTextStyles,
} from "@poscon/shared-frontend";
import {
  formatCoordinationTime,
  formatNasAltitude,
  formatSpeed,
  stringToParsedTokenArray,
  type EramFlightplan,
} from "@poscon/shared-types/eram";
import type { EramFontSize } from "@poscon/shared-frontend";
import { eramHubConnection } from "~/eramHubConnection";
import { cfrListSelector } from "~redux/slices/eramTempStateSlice";
import { useViewOptions, ViewOptionContextProvider } from "contexts/viewOptionContext";
import { selectFlightplansById, trackFromFpIdSelector } from "~redux/slices/aircraftSlice";
import { useBrightContext } from "contexts/brightnessContext";
import { formatRoute, chunkString, sumBy } from "@poscon/shared-types";

const view = "CFR";

const cfrFields = [
  {
    name: "FLID",
    width: 17,
    textCreator: (fp: EramFlightplan) => `${fp.cid} ${fp.callsign}`,
  },
  {
    name: "TYP",
    width: 7,
    textCreator: (fp: EramFlightplan) => `${fp.aircraftType}/${fp.equipmentQualifier}`,
  },
  {
    name: "BCN",
    width: 5,
    textCreator: (fp: EramFlightplan) => fp.squawk ?? "",
  },
  {
    name: "SPD",
    width: 5,
    textCreator: (fp: EramFlightplan) => (fp.actualSpeed ? formatSpeed(fp.actualSpeed) : ""),
  },
  {
    name: "FIX",
    width: 11,
    textCreator: (fp: EramFlightplan) => fp.coordinationFix ?? "",
  },
  {
    name: "TIM",
    width: 6,
    textCreator: (fp: EramFlightplan) =>
      fp.coordinationTime ? formatCoordinationTime(fp.coordinationTime) : "",
  },
  {
    name: "ALT",
    width: 4,
    textCreator: (fp: EramFlightplan) => (fp.assignedAltitude ? formatNasAltitude(fp.assignedAltitude) : ""),
  },
  {
    name: "RTE/RMK",
    width: 0,
    textCreator: (fp: EramFlightplan, width: number, showRemarks = false, showFullText = false) => {
      if (showFullText) {
        const text = showRemarks
          ? fp.remarks
          : formatRoute(fp.route, fp.departure, fp.destination, fp.routeIsTruncated);
        return chunkString(text, width).join("\n");
      }
      if (showRemarks) {
        return fp.remarks.slice(0, width);
      }
      let routeToDisplay = formatRoute(fp.route, fp.departure, fp.destination, fp.routeIsTruncated);
      if (routeToDisplay.length > width - 3) {
        routeToDisplay = routeToDisplay.slice(0, width - 4).split(/\.+\w+$/)[0] ?? "";
        routeToDisplay = `${routeToDisplay.replace(/\.+$/, "")}....`;
      }
      return routeToDisplay;
    },
  },
];

const optionMap = {
  background: backgroundOption(view),
  border: borderOption(view),
  entries: counterOption(view, "entries", "ENTRIES", 1, 10, 10),
  font: fontOption(view),
  bright: brightOption(view),
  routeWidth: counterOption(view, "routeWidth", "RTE", 30, 100, 7, "delta"),
  filters: toggleOption(view, "filters", "FILTERS", 7),
};

type FlightplanId = string;
type CfrRowProps = {
  fp: EramFlightplan;
  showFullText: boolean;
  showRemarks: boolean;
  toggleShowFullText: () => void;
  toggleRemarks: () => void;
};

const CfrRow = ({ fp, showFullText, toggleShowFullText, showRemarks, toggleRemarks }: CfrRowProps) => {
  const dispatch = useRootDispatch();
  const sectorId = useRootSelector(sectorIdSelector);
  const viewOptions = useViewOptions(view);
  const { borderTint } = useBrightContext();
  const track = useRootSelector((state) => trackFromFpIdSelector(state, fp.id));
  const { selected, toggleViewOption } = useViewOptionSelected(`${view}/${fp.id}`);
  const options = [
    createItemOption(`DELETE ${fp.callsign}`, () =>
      dispatch(processEramMessage(stringToParsedTokenArray(`QF ${fp.cid ?? fp.callsign}`))),
    ),
  ];

  const fontSize = viewOptions.font as EramFontSize;
  const fontFamily = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontFamily];

  const tint = computeColor(colorNameMap.white, viewOptions.bright / 100);
  const flidTint = selected ? 0 : tint;

  const alpha = (viewOptions.bright * 0.8) / 100 + 0.2;
  const fillColor = computeColor(selected ? colorNameMap.grey : colorNameMap.black, selected ? alpha : 1);
  const style = getBitmapTextStyles(fontFamily);

  const ownerShort = track?.ownerShort ?? null;
  const flidText = fp
    ? `${fp.cid} ${fp.callsign}${ownerShort !== null && track?.owner !== sectorId ? `(${ownerShort})` : ""}`
    : "N/A";
  const flidWidth = flidText.length * fontDimension.width + 4;

  return (
    <container sortableChildren>
      <ViewItem
        zIndex={1}
        text={flidText}
        width={flidWidth}
        height={fontDimension.height + 1}
        fontSize={fontSize}
        tint={flidTint}
        fill={selected}
        fillColor={fillColor}
        onmousedown={() => {
          toggleViewOption();
        }}
      >
        {selected && <ViewItemOptionContainer xOffset={flidWidth} options={options} />}
      </ViewItem>
      {fp && (
        <container zIndex={0} y={1} eventMode="none">
          {cfrFields.slice(1).map(({ name, textCreator }, index) => {
            const x = sumBy(cfrFields.slice(0, index + 1), (f) => f.width) * fontDimension.width;
            return (
              <bitmapText
                key={name}
                text={textCreator(fp, viewOptions.routeWidth, showRemarks, showFullText)}
                x={x + 2}
                style={{ ...style, fill: tint }}
              />
            );
          })}
        </container>
      )}
      <container x={(sumBy(cfrFields, (f) => f.width) + viewOptions.routeWidth) * fontDimension.width + 9}>
        <ViewItem
          width={fontDimension.height * 2 + 2}
          height={fontDimension.height + 1}
          text="*"
          fill
          fillColor={showFullText ? tint : 0}
          borderColor={borderTint}
          tint={showFullText ? 0 : tint}
          onmousedown={toggleShowFullText}
        />
        <ViewItem
          x={fontDimension.height * 2 + 3}
          width={fontDimension.height * 2 + 2}
          height={fontDimension.height + 1}
          text="R"
          fill
          fillColor={showRemarks ? tint : 0}
          borderColor={borderTint}
          tint={showRemarks ? 0 : tint}
          onmousedown={toggleRemarks}
        />
      </container>
    </container>
  );
};

type CfrItemOptions = { id: FlightplanId; showFullText: boolean; showRemarks: boolean };

export const CfrView = () => {
  const cfrList = useRootSelector(cfrListSelector);
  const flightplans = useRootSelector((state) => selectFlightplansById(state, cfrList));
  const [itemOptions, setItemOptions] = useState<CfrItemOptions[]>([]);
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));

  const scrollProps = useScrollProps(view, flightplans.length, viewOptions.entries, {
    scrollDelta: Math.floor(viewOptions.entries / 2),
  });

  const flightplansToShow = flightplans.slice(scrollProps.scroll, scrollProps.scroll + viewOptions.entries);

  const headerButtons = [
    {
      text: "DEL ALL",
      onmousedown: () => eramHubConnection.removeAllCfrAircraft(),
      disabled: cfrList.length === 0,
    },
    { text: "SHOW FIELDS", disabled: true },
  ];

  const tint = computeColor(colorNameMap.white, viewOptions.bright / 100);

  const fontSize = viewOptions.font as EramFontSize;
  const fontDimension = eramFontDimensionMap[eramFontNameMap[fontSize]];
  const width = viewOptions.routeWidth + sumBy(cfrFields, (f) => f.width) + 7;
  const totalWidth = width * fontDimension.width;

  const itemHeightList = flightplansToShow.map((fp) => {
    const opts = itemOptions.find((e) => e.id === fp.id);
    return (
      fontDimension.height *
        (opts?.showFullText
          ? chunkString(
              opts.showRemarks
                ? fp.remarks
                : formatRoute(fp.route, fp.departure, fp.destination, fp.routeIsTruncated),
              viewOptions.routeWidth,
            ).length
          : 1) +
      3
    );
  });

  useEffect(() => {
    eramHubConnection.emit("setCfrIsOpen", true);
    return () => {
      eramHubConnection.emit("setCfrIsOpen", false);
    };
  }, []);

  const height = itemHeightList.reduce((a, b) => a + b, 0) + (fontDimension.height + 3) + 2;
  const showScrollbar = flightplans.length > viewOptions.entries;

  let y = 0;

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View
        width={width}
        extraWidthPx={showScrollbar ? eramFontDimensionMap[eramFontNameMap[2]].width + 4 : 0}
        height={height}
        view={view}
        optionMap={optionMap}
        headerButtons={headerButtons}
      >
        <container x={1} y={1}>
          {cfrFields.map(({ name, width }, index) => {
            const x = sumBy(cfrFields.slice(0, index), (f) => f.width) * fontDimension.width;
            return (
              <ViewItem
                key={name}
                text={name}
                x={x}
                height={fontDimension.height + 1}
                fontSize={fontSize}
                tint={tint}
              />
            );
          })}
        </container>
        <graphics
          y={fontDimension.height + 3}
          draw={(graphics) => {
            graphics.clear();
            graphics
              .moveTo(0, 0)
              .lineTo(width * fontDimension.width - 1, 0)
              .stroke({ width: 1, color: tint });
          }}
        />
        <container y={fontDimension.height + 4}>
          {flightplansToShow.map((fp, index) => {
            const opts = itemOptions.find((e) => e.id === fp.id);
            const _y = y;
            const height = itemHeightList[index] ?? fontDimension.height + 3;
            y += height;
            return (
              <container key={fp.id} y={_y}>
                <graphics
                  y={height - 1}
                  draw={(graphics) => {
                    graphics.clear();
                    graphics.moveTo(0, 0);
                    graphics.lineTo(width * fontDimension.width - 1, 0).stroke({ width: 1, color: tint });
                  }}
                />
                <container x={1}>
                  <CfrRow
                    fp={fp}
                    showFullText={opts?.showFullText ?? false}
                    showRemarks={opts?.showRemarks ?? false}
                    toggleShowFullText={() =>
                      setItemOptions((prev) => {
                        const opts = prev.find((e) => e.id === fp.id);
                        if (opts) {
                          const newOpts = { ...opts };
                          newOpts.showFullText = !newOpts.showFullText;
                          return [...prev.filter((e) => e.id !== fp.id), newOpts];
                        }
                        return [...prev, { id: fp.id, showFullText: true, showRemarks: false }];
                      })
                    }
                    toggleRemarks={() => {
                      setItemOptions((prev) => {
                        const opts = prev.find((e) => e.id === fp.id);
                        if (opts) {
                          const newOpts = { ...opts };
                          newOpts.showRemarks = !newOpts.showRemarks;
                          return [...prev.filter((e) => e.id !== fp.id), newOpts];
                        }
                        return [...prev, { id: fp.id, showFullText: false, showRemarks: true }];
                      });
                    }}
                  />
                </container>
              </container>
            );
          })}
        </container>
        {showScrollbar && <ScrollBar x={totalWidth + 1} y={2} height={height - 4} {...scrollProps} />}
      </View>
    </ViewOptionContextProvider>
  );
};
