import { setSettingsState } from "~redux/slices/settingsSlice";
import { setViewOptionState } from "~redux/slices/viewOptionSlice";
import {
  closeSpecificViewOption,
  setIsProcessingCommand,
  setMcaFeedback,
  setSelectedViewOption,
  toggleSelectedViewOption,
} from "@poscon/shared-frontend";
import {
  setBCG,
  setEramConfig,
  setGeomapConfig,
  setKSDConfig,
  setMapFilterMenu,
  setMapScale,
  setRangeCenterOverride,
  setRPosConfig,
} from "~redux/slices/eramTempStateSlice";
import { setEramState } from "./eramStateSlice";

export const whitelistedActions: string[] = [
  setSettingsState.type,
  setViewOptionState.type,
  setSelectedViewOption.type,
  closeSpecificViewOption.type,
  toggleSelectedViewOption.type,
  setMcaFeedback.type,
  setMapScale.type,
  setRangeCenterOverride.type,
  setIsProcessingCommand.type,
  setEramState.type,
  setRPosConfig.type,
  setGeomapConfig.type,
  setEramConfig.type,
  setKSDConfig.type,
  setBCG.type,
  setMapFilterMenu.type,
];
