import React, { useState } from "react";
import type { CommandCategoryButtonId } from "types/eramButton";
import { useRootSelector } from "~redux/hooks";
import { cmdMenuItemSelector } from "~redux/slices/eramTempStateSlice";
import type { EramButtonProps } from "components/buttons/EramButton";
import { EramBaseButton } from "components/buttons/EramButton";
import { baseToggleButtonSelectedColor } from "components/buttons/EramToggleButton";
import {
  colorNameMap,
  computeColor,
  dispatchInsertCommandEvent,
  eramFontNameMap,
  eramTextDimensionMap,
  getBitmapTextStyles,
} from "@poscon/shared-frontend";
import { ButtonMenuContainer } from "components/buttons/ButtonMenuContainer";
import { useEventListener } from "usehooks-ts";
import { useBrightContext } from "contexts/brightnessContext";
import { Nullable } from "@poscon/shared-types";
import { RPosConfig, stringToTokenArray } from "@poscon/shared-types/eram";

const fontFamily = eramFontNameMap[1];
const fontDimension = eramTextDimensionMap[1];

type CategoryItem = RPosConfig["toolbarCategoryMenus"][number]["categoryItems"][number];

type ToolbarCatRowProps = {
  y?: number;
  item: RPosConfig["toolbarCategoryMenus"][number]["categoryItems"][number];
  select: () => void;
  selected: boolean;
};
const ToolbarCatRow = ({ item, y = 0, select, selected }: ToolbarCatRowProps) => {
  const { textBright } = useBrightContext();

  useEventListener(
    "keydown",
    (event) => {
      event.preventDefault();
      if (event.code === `F${item.functionKey.toString()}`) {
        event.stopImmediatePropagation();
        dispatchInsertCommandEvent(stringToTokenArray(item.verb));
        select();
      }
    },
    undefined,
    true,
  );

  const tint = computeColor(colorNameMap.white, textBright);
  const bgColor = colorNameMap.darkGrey;

  return (
    <container x={2} y={y}>
      <graphics
        eventMode="static"
        onMouseDown={() => {
          dispatchInsertCommandEvent(stringToTokenArray(item.verb));
          select();
        }}
        draw={(graphics) => {
          graphics.clear();
          graphics.rect(0, 0, 28 * fontDimension.width, fontDimension.height).fill(selected ? tint : bgColor);
        }}
      />
      <bitmapText
        text={item.item}
        style={{ ...getBitmapTextStyles(fontFamily), fill: selected ? bgColor : tint }}
        eventMode="none"
      />
    </container>
  );
};

type ToolbarCatListProps = {
  categoryItems: CategoryItem[];
  x?: number;
  y?: number;
};
export const ToolbarCatList = ({ categoryItems, x, y }: ToolbarCatListProps) => {
  const [selectedCategory, setSelectedCategory] = useState<Nullable<number>>(null);

  const width = 28 * fontDimension.width + 7;
  const height = categoryItems.length * (fontDimension.height + 3) + 4;

  return (
    <ButtonMenuContainer
      buttonId="COMMAND_MENUS"
      width={width}
      height={height}
      fillColor={0x303030}
      x={x}
      y={y}
    >
      {categoryItems.map((item, index) => (
        <ToolbarCatRow
          key={item.item}
          y={3 + index * (fontDimension.height + 3)}
          item={item}
          select={() => setSelectedCategory(index)}
          selected={selectedCategory === index}
        />
      ))}
    </ButtonMenuContainer>
  );
};

export const EramToolbarCatButton = (props: EramButtonProps<CommandCategoryButtonId>) => {
  const [selected, setSelected] = useState(false);
  const commandMenu = useRootSelector((state) =>
    cmdMenuItemSelector(state, parseInt(props.buttonId.split("_").at(-1)!, 10)),
  );
  const categoryItems = commandMenu?.categoryItems ?? null;

  return (
    <EramBaseButton
      {...props}
      hidden={categoryItems === null}
      onmousedown={() => setSelected((prev) => !prev)}
      baseBgColor={selected ? baseToggleButtonSelectedColor : colorNameMap.black}
      zIndex={selected ? 20 : 0}
    >
      {selected && categoryItems && <ToolbarCatList categoryItems={categoryItems} />}
    </EramBaseButton>
  );
};
