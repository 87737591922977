import React, { useState } from "react";
import type { EramButtonProps } from "components/buttons/EramButton";
import { EramBaseButton } from "components/buttons/EramButton";
import { baseToggleButtonSelectedColor } from "components/buttons/EramToggleButton";
import { colorNameMap, eramFontDimensionMap, eramFontNameMap } from "@poscon/shared-frontend";
import { useButtonContext } from "contexts/buttonContext";
import { useRootSelector } from "~redux/hooks";

const OutageView = () => {
  const fontSize = useRootSelector((state) => state.eram.font.OUTAGE_FONT);
  const { buttonWidth, buttonHeight, buttonBright } = useButtonContext();

  const fontName = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontName];

  const width = fontDimension.width * 20;

  return (
    <container>
    </container>
  );
};

export const EramOutageButton = (props: EramButtonProps) => {
  const [selected, setSelected] = useState(false);

  return (
    <EramBaseButton
      {...props}
      onmousedown={() => setSelected((prev) => !prev)}
      baseBgColor={selected ? baseToggleButtonSelectedColor : colorNameMap.black}
    >
      {selected && <OutageView />}
    </EramBaseButton>
  );
};
